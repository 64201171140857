import CryptoES from "crypto-es";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const KEY = process.env.REACT_APP_ENCRYPTION_KEY;

export const encrypt = (text: string) => {
  const encrypted = CryptoES.AES.encrypt(text, KEY).toString();

  return encrypted;
};

export const decrypt = (text: string) => {
  const Decrypted = CryptoES.AES.decrypt(text, KEY);
  const result = Decrypted.toString(CryptoES.enc.Utf8);
  return result;
};

export const siginInWithToken = async (token: string) => {
  await signInWithCustomToken(getAuth(), token);
};

export const logout = async () => {
  await getAuth().signOut();
};

export const getAuthHeaders = async () => {
  const token = await getAuth().currentUser?.getIdToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return config;
};

export const shuffle = (array: any[]) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

import React, { useEffect } from 'react';
import { useTypedSelector } from '../../../../../hooks';
import { useParams } from 'react-router-dom';
import SubmitModal from './components/SubmitAnswerModal';

const WrittenExam = () => {
	const { key } = useParams();

	const [answer, setAnswer] = React.useState<string>('');
	const [totalWords, setTotalWords] = React.useState<number>(0);
	const [showUpdateModel, setShowUpdateModel] = React.useState<boolean>(false);
	// count the number of words in the answer
	useEffect(() => {
		if (answer === '') return;
		const words = answer.split(' ').length;
		setTotalWords(words);
	}, [answer]);
	// eslint-disable-line react-hooks/exhaustive-deps

	const { studentExams } = useTypedSelector(state => state.ExamReducer);

	const currentExam = studentExams?.find(exam => exam?.key === key);

	return (
		<section className="my-4 bg-[#FAFAFA]">
			{showUpdateModel && (
				<SubmitModal
					setShowUpdateModel={setShowUpdateModel}
					currentExam={currentExam}
					answer={answer}
				/>
			)}
			<div className="shadow-lg  h-fit mx-[10rem]  px-4 py-2">
				<div className="flex flex-col text-left items-center">
					<h4 className="mt-2">{currentExam.topic.title}</h4>
					<h5 className="text-[#18608C] mt-2">{currentExam.topic.topic}</h5>
					<textarea
						name=""
						id=""
						cols={130}
						rows={20}
						className="mt-2 border p-2"
						onChange={e => setAnswer(e.target.value)}
					></textarea>
				</div>
				<div className="">{totalWords}</div>
			</div>
			<div className="flex justify-center mt-2 ">
				<button
					className="bg-[#9BDE7E] text-white px-6 w-[10rem] py-2 font-semibold rounded "
					onClick={() => setShowUpdateModel(true)}
				>
					Send Answers
				</button>
			</div>
		</section>
	);
};

export default WrittenExam;

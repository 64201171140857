import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../../../utils/Constans';
import { useActions } from '../../../../../hooks';
import { getAuthHeaders } from '../../../../../utils/Generic';

const DELETE_GROUP_URL = `${BASE_URL}/DeleteGroup`;

interface DeleteProps {
	setShowModel: (newType: boolean) => void;
	groupKey: string | undefined;
}

const DeleteGroup = ({ setShowModel, groupKey }: DeleteProps) => {
	const { MsgActions } = useActions();

	const handleOnDeletePress = async () => {
		setShowModel(false);
		const headers = await getAuthHeaders();
		await axios
			.delete(
				`${DELETE_GROUP_URL}/${groupKey}`,

				{
					headers: headers.headers,
				}
			)
			.then(res => {
				if (res.status === 200) {
					window.location.reload();
				}
			})
			.catch(err => {
				MsgActions.showMsg(err.response.data.msg, 'danger');
			});
	};
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header closeButton onClick={() => setShowModel(false)}>
					<Modal.Title>Remove group</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to remove the group?</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={() => setShowModel(false)}>
						No
					</Button>
					<Button variant="danger" onClick={handleOnDeletePress}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DeleteGroup;

import React from 'react';
import './Groups.css';
import { Group } from '../../../../../types';
import EmptyGroup from './components/EmptyGroup/EmptyGroup';
import ShowGroup from './components/ShowGroups/ShowGroup';
import { BASE_URL } from '../../../../../utils/Constans';
import { useFetch, useTypedSelector } from '../../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { getAuthHeaders } from '../../../../../utils/Generic';

const FetchGroup_URL = `${BASE_URL}/FetchGroups`;

function Groups() {
	const navigate = useNavigate();
	const [groups, setGroups] = React.useState<Group[]>([]);
	const { user } = useTypedSelector(state => state.AuthReducer);

	const [data, loading, error] = useFetch(
		FetchGroup_URL,
		{ teacherId: user?.uid },
		getAuthHeaders,
		'GET'
	);

	React.useEffect(() => {
		if (!loading && data && data.groups) {
			setGroups(data.groups);
		}
	}, [data, loading, error]);
	const handleOnCreatePress = () => {
		navigate('/groups/createGroup');
	};
	return (
		<div className="h-full">
			{!loading && groups.length == 0 && (
				<EmptyGroup onPress={handleOnCreatePress} />
			)}
			{groups.length > 0 && (
				<ShowGroup groups={groups} onPress={handleOnCreatePress} />
			)}
		</div>
	);
}

export default Groups;

import React from "react";
import "./welcomeScreen.css";
import { Header } from "../../../components";
import LanguageWrapper from "../components/LanguageWrapper/LanguageWrapper";
import LoginWrapper from "../components/LoginWrapper/LoginWrapper";
import { Container } from "react-bootstrap";
import AppDescription from "../components/AppDescription/AppDescription";
import Features from "../components/Features/Features";
import Footer from "../components/Footer/Footer";
function WelcomeScreen() {
  return (
    <div className="welcome">
      <Header />
      <Container>
        <LoginWrapper />
        <LanguageWrapper />
        <AppDescription />
        <Features />
      </Container>
      <Footer />
    </div>
  );
}

export default WelcomeScreen;

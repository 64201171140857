import React from 'react';
import { useTypedSelector } from '../../../../../../../../hooks';
import { getAuthHeaders } from '../../../../../../../../utils/Generic';
import { BASE_URL } from '../../../../../../../../utils/Constans';
import axios from 'axios';

interface CobeAnswerCardProps {
	answer: any;
	index: number;
	currentIndex: number;
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
	lengthFormatted: number;
	exam: any;
}

const CobeQuestionCard = ({
	answer,
	index,
	currentIndex,
	setCurrentIndex,
	lengthFormatted,
	exam,
}: CobeAnswerCardProps) => {
	return (
		<div className="w-full" style={{ minHeight: 'calc(90vh + 100px)' }}>
			<div className="md:flex  md:justify-center md:items-center my-2">
				<div className="flex flex-col shadow-md md:px-20  py-2">
					<div>
						{exam.imageUrl && (
							<div>
								<img
									src={exam.imageUrl}
									alt="exam"
									className="md:w-[20rem] md:mx-[10rem] w-[10rem] my-2"
								/>
							</div>
						)}

						{exam.videoUrl && (
							<div className="aspect-w-16 aspect-h-9 md:mx-[10rem]">
								<iframe
									// width="md:600"
									// height="md:280"
									src={exam.videoUrl}
									className="w-full h-full"
									// allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									title="Embedded youtube"
								/>
							</div>
						)}
					</div>
					<h4 className="md:text-center my-4">Question {index + 1}</h4>
					<div className="border-b-2 w-full"></div>
					<p className="mt-1 text-[1.3rem] my-4">
						{exam.questions[index]?.question}
					</p>
					<div className="border-b-2 w-full"></div>

					{exam.questions[index]?.answerType === 'Record' &&
						answer[0]?.answer && (
							<div className="flex justify-center items-center">
								<audio
									src={answer[0]?.answer}
									controls
									className="w-[20rem] mx-[10rem] my-2"
								/>
							</div>
						)}
					{exam.questions[index]?.answerType === 'Written' && (
						<p className="my-4">
							Student Answer:{'    '}
							<span className="bg-[#67C06B] inline py-1 px-2  rounded">
								{answer[0]?.answer}
							</span>
						</p>
					)}
					{!answer[0]?.answer && (
						<p className="my-2 ">
							Student Answer:{'    '}
							<span className="bg-[#FF6868] inline py-1 px-2 rounded">
								{'-'}
							</span>
						</p>
					)}
				</div>
			</div>

			<div className="md:flex md:justify-center md:mx-[12rem] md:gap-[10rem] space-x-4 my-4">
				<button
					className={`bg-[#3188C9] text-white px-4 py-2 w-[8rem] font-semibold rounded ${
						currentIndex === 0 && 'bg-[#D3D3D3]'
					}`}
					onClick={() => setCurrentIndex(currentIndex - 1)}
					disabled={currentIndex === 0}
				>
					Previous
				</button>
				{/* {currentIndex < lengthFormatted - 1 && ( */}
				<button
					className={`bg-[#3188C9] text-white px-6 w-[8rem] py-2 font-semibold rounded ${
						currentIndex === lengthFormatted - 1 && 'bg-[#D3D3D3]'
					}`}
					onClick={() => {
						setCurrentIndex(currentIndex + 1);
						// handleNext();
					}}
					disabled={currentIndex === lengthFormatted - 1}
				>
					Next
				</button>
			</div>
		</div>
	);
};

export default CobeQuestionCard;

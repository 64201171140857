import React, { useEffect } from "react";
import "./SampleExam.css";
import { Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useActions, useTypedSelector } from "../../../../../hooks";
import { StepCard } from "../../../../../components";
import { STEP1, STEP2, STEP3 } from "../../../../../assets/images";
import StepOne from "./components/StepOne/StepOne";
import StepTwo from "./components/StepTwo/StepTwo";
import StepThree from "./components/StepThree/StepThree";
import Sidebar from "../CustomExam/components/SampleSidebar/Sidebar";

const STEPS = [
  { image: STEP1, title: "The First Step", subTitle: "FirstStepEx" },
  { image: STEP2, title: "The Second Step", subTitle: "SecondStepEx" },
  { image: STEP3, title: "The Third Step", subTitle: "ThirdStepEx" },
];

const SampleExam = () => {
  const { t } = useTranslation();
  const { ExamActions } = useActions();
  const [step, setStep] = React.useState(0);
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { singleExam } = useTypedSelector((state) => state.ExamReducer);

  const { key } = useParams();

  // if key in params then fetch exam data
  useEffect(() => {
    if (key && user) {
      ExamActions.fetchSingleExams(user.key, key);
    }
  }, [key]);

  React.useEffect(() => {
    return () => {
      ExamActions.cleanExam();
    };
  }, []);

  const handleNextPress = () => {
    if (step < STEPS.length - 1) {
      setStep(step + 1);
    }
  };

  const handlePrevPress = () => {
    if (step - 1 >= 0) {
      setStep(step - 1);
    }
  };

  return (
    <div
      className="SampleExam md:flex md:basis-3/5 md:w-full"
      style={{ minHeight: "calc(100vh + 100px)" }}
    >
      <Stack direction="vertical" gap={2}>
        <StepCard
          image={STEPS[step].image}
          title={t(STEPS[step].title)}
          subTtile={t(STEPS[step].subTitle)}
        />

        {step == 0 && (
          <StepOne
            onPress={handleNextPress}
            singleExam={key !== undefined ? singleExam : undefined}
          />
        )}

        {step == 1 && (
          <StepTwo
            onNextPress={handleNextPress}
            onPrevPress={handlePrevPress}
            singleExam={key !== undefined ? singleExam : undefined}
          />
        )}

        {step == 2 && <StepThree singleExam={key ? singleExam : undefined} />}
      </Stack>

      <div className="md:basis-1/5 hidden">
        <Sidebar />
      </div>
    </div>
  );
};

export default SampleExam;

import React, { useEffect, useRef, useState } from 'react';
import { PlayIcon } from '../../../../../../assets/icons';
import { PauseIcon } from '../../../../../../assets/icons';

const AudioPlayer: React.FC<{ audioUrl: string }> = ({ audioUrl }) => {
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [playing, setPlaying] = useState(false);

	const handlePlay = () => {
		if (audioRef.current) {
			audioRef.current.play();
			setPlaying(true);
		}
	};

	const handlePause = () => {
		if (audioRef.current) {
			audioRef.current.pause();
			setPlaying(false);
		}
	};

	return (
		<div>
			<audio ref={audioRef} src={audioUrl} />

			{playing ? (
				<button
					onClick={handlePause}
					className="bg-[#3A8688] p-2"
					disabled={audioUrl === ''}
				>
					<PauseIcon color="white" />
				</button>
			) : (
				<button
					onClick={handlePlay}
					className="bg-[#3A8688] p-2"
					disabled={audioUrl === ''}
				>
					<PlayIcon color="white" />
				</button>
			)}
		</div>
	);
};

export default AudioPlayer;

import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";

interface ChartDataProps {
  examData?: any;
}

const ChartData = ({ examData }: ChartDataProps) => {
  const { t } = useTranslation();
  let data: any[] = [
    ["Grades", "Grades per student"],
    [`0%   0-54`, 0],
    [`0%   55-89`, 0],
    [`0%   90-100`, 0],
  ];
  let options: any = {};

  // check how many students got 0-54, 55-89, 90-100
  let range1Count = 0; // 0-54 marks
  let range2Count = 0; // 55-89 marks
  let range3Count = 0; // 90-100 marks

  let range1Percentage = 0;
  let range2Percentage = 0;
  let range3Percentage = 0;

  if (examData && examData.length > 0) {
    examData.forEach((exam: any) => {
      const marks = exam.marks;

      if (marks >= 0 && marks <= 54) {
        range1Count++;
      } else if (marks >= 55 && marks <= 89) {
        range2Count++;
      } else if (marks >= 90 && marks <= 100) {
        range3Count++;
      }
    });

    range1Percentage = Math.round((range1Count / examData.length) * 100);
    range2Percentage = Math.round((range2Count / examData.length) * 100);
    range3Percentage = Math.round((range3Count / examData.length) * 100);

    data = [
      ["Grades", "Grades per student"],
      [`${range1Percentage}%   0-54`, range1Count],
      [`${range2Percentage}%   55-89`, range2Count],
      [`${range3Percentage}%   90-100`, range3Count],
    ];

    options = {
      title: `${t("Statistics")}`,
      titleTextStyle: {
        fontSize: 20,
        bold: true,
      },
      dataTextStyle: {
        fontSize: 14,
        bold: false,
      },

      legend: {
        textStyle: {
          fontSize: 16,
        },
      },

      colors: ["#ff6868", "#3188c9", "#7bc98d"],
    };
  }

  return (
    <div className="shadow py-[1rem] md:px-[5rem] flex justify-between ">
      {examData && (
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          width={"100%"}
          height={"300px"}
        />
      )}
    </div>
  );
};

export default ChartData;

import React from "react";
import "./StudentsTable.css";
import { Form, Table } from "react-bootstrap";
import { isRTL } from "../../../../../../locales";
import { useTranslation } from "react-i18next";
import { StudentTable } from "../../../../../../types";

interface StudentsTableProps {
  students: StudentTable[];
  setCheckedStudents: (newType: StudentTable[]) => void;
  checkedStudents: StudentTable[];
}

function StudentsTable({
  students,
  setCheckedStudents,
  checkedStudents,
}: StudentsTableProps) {
  const { t } = useTranslation();
  const [checkedAll, setCheckedAll] = React.useState(false);

  const handleCheckAllChecked = () => {
    setCheckedAll(!checkedAll);

    if (!checkedAll) {
      setCheckedStudents([...students]);
      return;
    }

    if (checkedAll) {
      setCheckedStudents([]);
      return;
    }
  };
  // * re rendring to show added students
  React.useEffect(() => {}, [checkedStudents]);

  // * handling add student
  const handleAddStudent = (student: StudentTable) => {
    student.checked = !student.checked;
    const _checkedStudents = checkedStudents.filter(
      (s) => s.uid !== student.uid
    );
    if (student.checked === true) {
      setCheckedStudents([..._checkedStudents, student]);
    } else {
      setCheckedStudents(_checkedStudents);
    }
  };

  return (
    <div className="StudentsTable border max-h-[70vh] overflow-y-scroll">
      <Table striped hover bordered dir={isRTL() ? "rtl" : "ltr"} className="">
        <thead>
          <tr>
            <th>
              <div>#</div>
            </th>
            <th>
              <div>{t("Name")}</div>
            </th>
            <th>
              <div>{t("Class")}</div>
            </th>
            <th>
              <div>
                <Form.Check
                  type="switch"
                  checked={checkedAll}
                  onChange={handleCheckAllChecked}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={index.toString()}>
              <td>{index + 1}</td> <td>{student.name}</td>{" "}
              <td>{student.class}</td>
              <td>
                {
                  <Form.Check
                    className="cursor-pointer"
                    type="switch"
                    checked={checkedAll ? true : student.checked}
                    onChange={() => handleAddStudent(student)}
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default StudentsTable;

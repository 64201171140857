import React from "react";
import { initializeApp } from "firebase/app";

import "./App.css";
import MainNavigation from "./navigations/MainNavigation";
import useTypedSelector from "./hooks/useTypedSelector";
import { MsgModal } from "./components";
import useActions from "./hooks/useActions";
import { useTranslation } from "react-i18next";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
try {
  initializeApp(firebaseConfig);
} catch (err) {
  console.log(err);
}

function App() {
  const { t } = useTranslation();
  const { msg, msgType, showMsg } = useTypedSelector(
    (state) => state.MsgReducer
  );
  const { MsgActions } = useActions();
  const onDismiss = () => {
    MsgActions.cleanMsg();
  };

  return (
    <div className="App">
      <MsgModal
        visible={showMsg && msg !== undefined}
        msg={t(msg)}
        msgType={msgType}
        onDismiss={onDismiss}
      />
      <MainNavigation />
    </div>
  );
}

export default App;

import { Navigate, Route, Routes } from "react-router-dom";
import { WelcomeScreen } from "../screens/Auth";

const AuthNavigation = () => {
  return (
    <Routes>
      <Route index path="/" element={<WelcomeScreen />} />
      <Route path="about" element={<h1>about page</h1>} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default AuthNavigation;

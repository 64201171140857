import React from "react";
import "./appDescription.css";
import { Container } from "react-bootstrap";
import { PHONE, AppleStore, GoogleStore } from "../../../../assets/images";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../locales";
import { appleStore, androidStore } from "../../../../utils/Constans";
function AppDescription() {
  const { t } = useTranslation();

  return (
    <div className="AppDescription">
      <Container className="phoneWrapper">
        <img src={PHONE} className="phoneImg" alt="phone" />
        <div className="infoWrapper">
          <h1 className="title">MY LEXIS PLUS</h1>

          <section style={{ textAlign: isRTL() ? "right" : "left" }}>
            {t("t9")}
          </section>

          <div className="downloadNowWrapper">
            <h1>{t("t10")}</h1>
          </div>

          <div className="storesWrapper">
            <a href={androidStore} target="_blank" rel="noreferrer">
              <img src={GoogleStore} alt="android" />
            </a>
            <a href={appleStore} target="_blank" rel="noreferrer">
              <img src={AppleStore} alt="apple" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AppDescription;

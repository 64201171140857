import React from 'react';
import { Modal } from 'react-bootstrap';
import { CHANGE_PASSWORD } from '../../../../../assets/images';
import { BASE_URL, KEY } from '../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../utils/Generic';
import { useActions } from '../../../../../hooks';
import axios from 'axios';
import CryptoES from 'crypto-es';

const RESET_PASSWORD = `${BASE_URL}/ResetStudentPassword`;

interface ResetPasswordProps {
	setViewShowModel: (newType: boolean) => void;
}

const ResetPassword = ({ setViewShowModel }: ResetPasswordProps) => {
	const [username, setUserName] = React.useState('');

	const encrypt = (text: string) => {
		const encrypted = CryptoES.AES.encrypt(text, KEY).toString();

		return encrypted;
	};
	const { MsgActions } = useActions();

	const handleSave = async () => {
		const headers = await getAuthHeaders();

		const encryptedPassword = encrypt('123123123');

		axios
			.post(
				RESET_PASSWORD,
				{ username: username, password: encryptedPassword },
				{
					headers: headers.headers,
				}
			)
			.then(res => {
				MsgActions.showMsg('Password reset successfully', 'success');

				setViewShowModel(false);
			})
			.catch(err => {
				MsgActions.showMsg('Error resetting password', 'danger');
			});
	};

	return (
		<div>
			<Modal show={true}>
				<Modal.Header
					closeButton
					onClick={() => setViewShowModel(false)}
				></Modal.Header>
				<Modal.Title className="mx-5 mt-2">
					<h3 className="text-[#18608C]">Reset student Password</h3>
				</Modal.Title>
				<Modal.Body className=" h-[30rem] overflow-y-scroll">
					<div className="flex flex-wrap justify-center items-center ">
						<img src={CHANGE_PASSWORD} alt="" />
					</div>
					<div className="mt-4 flex flex-col gap-2">
						<div className="flex justify-around items-center">
							<label htmlFor="">Student Username</label>
							<input
								type="text"
								className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12 pr-3 w-[12rem]"
								value={username}
								onChange={e => setUserName(e.target.value)}
							/>
						</div>

						<div className="flex justify-center mt-2">
							<button
								className="bg-[#3188C9] px-1 py-2 text-white w-[10rem] rounded"
								onClick={handleSave}
							>
								Save
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ResetPassword;

import React from 'react';

interface SampleAnswerCardProps {
	answer: any;
	index: number;
	currentIndex: number;
	setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
	lengthFormatted: number;
}

const SampleAnswerCard = ({
	answer,
	index,
	currentIndex,
	setCurrentIndex,
	lengthFormatted,
}: SampleAnswerCardProps) => {
	// console.log(answer);
	return (
		<div
			className="shadow py-[1rem] px-[2rem] bg-[#FFFFFF] my-4 w-[20rem] md:w-full"
			style={{ minHeight: 'calc(70vh + 100px)' }}
		>
			{answer[0]?.answerType === 'MCQ' && answer[0]?.originalAnswer && (
				<div>
					<h4 className="md:text-center">Question {index + 1}</h4>
					<div className="border-b-2 w-full"></div>
					<h5 className="text-[#18608C] mt-2">{answer[0]?.questionTitle}</h5>
					<p className="mt-4 font-semibold">{answer[0]?.question}</p>
					<p className="bg-[#67C06B] inline py-1 px-2 rounded">
						{answer[0]?.originalAnswer}
					</p>
					<div className="border-b-2 w-full my-4 "></div>
					<p>
						Student Answer:{'    '}
						<span
							className={
								answer[0]?.isCorrect
									? 'bg-[#67C06B] inline py-1 px-2 rounded'
									: 'bg-[#FF6868] inline py-1 px-2 rounded'
							}
						>
							{answer[0]?.answer}
						</span>
					</p>
				</div>
			)}
			{answer[0]?.answerType === 'MCQ' && !answer[0]?.originalAnswer && (
				<div>
					<h4 className="text-center">Question {index + 1}</h4>
					<div className="border-b-2 w-full"></div>
					<h5 className="text-[#18608C] mt-2">{answer[0]?.questionTitle}</h5>
					<p className="mt-4 font-semibold">{answer[0]?.question}</p>
					<p className="bg-[#67C06B] inline py-1 px-2 rounded">
						{answer[0]?.answer}
					</p>
					<div className="border-b-2 w-full my-4 "></div>
					<p>
						Student Answer:{'    '}
						<span className={'bg-[#FF6868] inline py-1 px-2 rounded'}>
							{'-'}
						</span>
					</p>
				</div>
			)}
			{answer[0]?.answerType === 'Matching' && (
				<div className="w-[20rem] md:w-full">
					<h4 className="md:text-center">Question {index + 1}</h4>
					<div className="border-b-2 w-full"></div>
					<h5 className="text-[#18608C] mt-2 hidden md:block">
						{answer[0]?.questionTitle}
					</h5>
					<p className="text-[#18608C] mt-2 block md:hidden">
						{answer[0]?.questionTitle}
					</p>
					<div className="flex items-center">
						<div>
							{answer[0]?.completeQuestion
								? answer[0]?.completeQuestion?.options.map
								: answer[0].options.map((option: string, index: number) => (
										<div key={index.toString()} className="font-semibold mt-4">
											<ol>
												<li className="-my-3">
													{index + 1}
													{')   '}
													{option}
												</li>
											</ol>
										</div>
								  ))}
						</div>
						<div>
							{answer[0]?.completeQuestion
								? answer[0]?.completeQuestion.question.map
								: answer[0]?.question.map((option: string, index: number) => (
										<div key={index.toString()} className="font-semibold">
											<ol>
												<li>
													{'_'}
													{option}
												</li>
											</ol>
										</div>
								  ))}
						</div>
					</div>
					<div className="border-b-2 w-full my-4 "></div>
					<h5 className="font-semibold">Student Answer: </h5>
					{answer[0]?.completeQuestion &&
						answer?.map((item: any, index: number) => (
							<div key={index.toString()} className="my-2">
								<h6 className="font-normal text-[1.2rem]">{item.answer}</h6>
								<h6
									className={
										item.isCorrect
											? 'bg-[#67C06B] inline py-1 px-2 rounded'
											: 'bg-[#FF6868] inline py-1 px-2 rounded'
									}
								>
									{item.option}
								</h6>
							</div>
						))}

					{!answer[0]?.completeQuestion &&
						answer[0]?.question.map((item: any, index: number) => (
							<div key={index.toString()} className="my-2">
								<h6 className="font-normal text-[1.2rem]">{item}</h6>
								<h6 className={'bg-[#FF6868] inline py-1 px-2 rounded'}>
									{'-'}
								</h6>
							</div>
						))}
				</div>
			)}
			<div className="md:flex md:justify-center md:mx-[12rem] md:gap-[10rem] flex flex-row space-x-4 my-4">
				<button
					className={`bg-[#3188C9] text-white px-4 py-2 w-[8rem] font-semibold rounded ${
						currentIndex === 0 && 'bg-[#D3D3D3]'
					}`}
					onClick={() => setCurrentIndex(currentIndex - 1)}
					disabled={currentIndex === 0}
				>
					Previous
				</button>
				{/* {currentIndex < lengthFormatted - 1 && ( */}
				<button
					className={`bg-[#3188C9] text-white px-6 w-[8rem] py-2 font-semibold rounded ${
						currentIndex === lengthFormatted - 1 && 'bg-[#D3D3D3]'
					}`}
					onClick={() => {
						setCurrentIndex(currentIndex + 1);
						// handleNext();
					}}
					disabled={currentIndex === lengthFormatted - 1}
				>
					Next
				</button>
				{/* )} */}
				{/* {currentIndex === lengthFormatted - 1 && (
					<button
						className="bg-[#9BDE7E] text-white px-6 w-[10rem] py-2 font-semibold rounded "
						onClick={handleSendAnswers}
					>
						Send Answers
					</button>
				)} */}
			</div>
		</div>
	);
};

export default SampleAnswerCard;

import React from "react";
import axios, { CancelTokenSource } from "axios";
import { ApiRequest, Method } from "../utils/ApiRequests";

type ReturnProps = [
  any,
  boolean,
  string,
  React.Dispatch<React.SetStateAction<{}>>
];

const defaultGetConfig = async () => {
  return {};
};

const useFetch = (
  url: string,
  options = {},
  getConfig = defaultGetConfig,
  method: Method = "POST"
): ReturnProps => {
  const [params, setParams] = React.useState(options);
  const [data, setData] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource>();

  const fetchData = async () => {
    try {
      setLoading(true);
      cancelRequest();
      const source = axios.CancelToken.source();
      setCancelToken(source);
      const config = await getConfig();
      const headerConfig = { ...config, cancelToken: source.token };
      let response = await ApiRequest(url, params, method, headerConfig);
      setData(response.data);
    } catch (error: any) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.warn(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);
        setError("Failed to fetch data!");
      } else if (error.request) {
        // The request was made but no response was received
        console.warn(error.request);
        setError("Failed to fetch data!");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.warn("Error", error.message);
        setError("Failed to fetch data!");
      }
    } finally {
      setLoading(false);
      setCancelToken(undefined);
    }
  };

  const cancelRequest = () => {
    if (cancelToken) {
      cancelToken.cancel(
        "Request cancelled due to component unmount or URL or params change."
      );
    }
  };

  React.useEffect(() => {
    setLoading(false);
    setError("");
    setData(null);
    fetchData();
    return () => {
      cancelRequest();
    };
  }, [url, params]);

  return [data, loading, error, setParams];
};

export default useFetch;

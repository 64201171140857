import React from 'react';
import SampleResults from './SampleResults';

const Results = () => {
	return (
		<div>
			<SampleResults />
		</div>
	);
};

export default Results;

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Group } from '../../../../../types';
import userImg from '../../../../../assets/images/user.png';

interface ViewGroupProps {
	setViewShowModel: (newType: boolean) => void;
	groups: Group;
}

const ViewGroup = ({ setViewShowModel, groups }: ViewGroupProps) => {
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setViewShowModel(false)}
				></Modal.Header>
				<Modal.Body className="max-w-[32rem] h-[30rem] overflow-y-scroll">
					<div className="flex gap-10 mx-4 max-w-[32rem]  flex-wrap">
						{groups.students.map(student => (
							<div className="flex flex-col">
								<img src={userImg} alt="user" className="h-12 w-12" />

								{student.name}
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ViewGroup;

import React from 'react';
import { Group } from '../../../../../../../types';
import '../EmptyGroup/EmptyGroup.css';
import { Card, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icons } from '../../../../../../../assets/styles';
import ShowGroupCard from './ShowGroupCard';

interface ShowGroupsProps {
	groups: Group[];
	onPress: VoidFunction;
}

const ShowGroup = ({ groups, onPress }: ShowGroupsProps) => {
	const { t } = useTranslation();
	return (
		<section className="container flex bg-[#FAFAFA] my-10 gap-4">
			<div className=" bg-white shadow-sm rounded-sm h-[7rem] w-[8rem] flex justify-center items-center">
				<div className="flex flex-col justify-center items-center pt-4">
					<div
						className="h-8 w-8 bg-[#3188C9] flex justify-center items-center rounded-[20px] cursor-pointer"
						onClick={() => onPress()}
					>
						<Icons.PlusIcon />
					</div>
					<p>{t('Create Group')}</p>
				</div>
			</div>
			<div className="flex gap-2 flex-wrap">
				{groups.map(group => (
					<ShowGroupCard key={group.key} group={group} />
				))}
			</div>
		</section>
	);
};

export default ShowGroup;

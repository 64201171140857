import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { isRTL } from "../../../../../locales";
import { useTranslation } from "react-i18next";
import { Languages, SCHOOL_LEVELS } from "../../../../../utils/Constans";
import { Switch } from "../../../../../components";
import { LanguageType, LevelType, Word } from "../../../../../types";
import { HOMEWORK_TYPES } from "../../../../../utils/Constans";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../utils/Generic";
import { useActions } from "../../../../../hooks";

import WordsTable from "./components/WordsTable";
import axios from "axios";

const FETCH_WORDS_URL = `${BASE_URL}/FetchWordsLanguageLevelCore`;
const FETCH_LANGUAGE_WORDS = `${BASE_URL}/FetchWordsLanguageLevel`;

const CreateHomeWork = () => {
  const { t } = useTranslation();
  const { MsgActions } = useActions();
  const [homeworkLanguage, setHomeworkLanguage] =
    useState<LanguageType>("English");
  const [loading, setLoading] = useState(false);
  const [homeworkTitle, setHomeworkTitle] = useState("");
  const [homeworkLevel, setHomeworkLevel] = useState("Level1");
  const [homeworkType, setHomeworkType] = useState("");
  const [words, setWords] = useState<Word[]>([]);

  const handleHomeworkLanguageChange = (value: string) => {
    const language = value as LanguageType;
    setHomeworkLanguage(language);
  };

  const handleHomeWorkLevelChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const level = e.target.value as LevelType;
    setHomeworkLevel(level);
  };

  const handleHomeWorkTypeChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const type = e.target.value;
    setHomeworkType(type);
    const headers = await getAuthHeaders();
    const [name, core] = type.split("-");
    let level = name[0].toUpperCase();
    level += name.slice(1);

    setLoading(true);

    const params = {
      language: homeworkLanguage,
      level: level === "Definitions" ? "Definitions" : homeworkLevel,
      core,
    };

    const languageParams = {
      language: homeworkLanguage,
      level: homeworkLevel,
    };

    if (homeworkLanguage && core === "") {
      axios
        .get(FETCH_LANGUAGE_WORDS, {
          params: { params: JSON.stringify(languageParams) },
          headers: headers.headers,
        })
        .then((res) => {
          setWords(res.data.words);
          setLoading(false);
        })
        .catch((err) => {
          MsgActions.showMsg(err.response.data.msg, "danger");
          setWords([]);
          setLoading(false);
        });
    }

    if (homeworkLanguage && core !== "") {
      axios
        .get(FETCH_WORDS_URL, {
          params: { params: JSON.stringify(params) },
          headers: headers.headers,
        })
        .then((res) => {
          setWords(res.data.words);
          setLoading(false);
        })
        .catch((err) => {
          MsgActions.showMsg(err.response.data.msg, "danger");
          setWords([]);
          setLoading(false);
        });
    }
  };

  return (
    <div className="bg-[#FAFAFA] " style={{ minHeight: "calc(100vh + 100px)" }}>
      <Form className="mx-3 md:max-w-[70%] ">
        <div
          dir={isRTL() ? "rtl" : "ltr"}
          className="shadow-sm px-[1rem] pb-12 relative"
        >
          <div className="md:flex md:flex-row md:gap-6 flex flex-col">
            <div className="flex flex-col mt-6 gap-2 md:w-[30rem] w-[15rem]">
              <label>{t("Homework Title")}</label>
              <input
                value={homeworkTitle}
                required
                type="text"
                placeholder={`${t("Choose title for homework")}`}
                className="border border-gray-300 p-2 rounded-md "
                onChange={(e) => setHomeworkTitle(e.target.value)}
              />
            </div>
            <div className="flex flex-col mt-6 gap-2 md:w-[30rem] w-[15rem]">
              <label>{t("Homework Level")}</label>
              <select
                required
                className="border border-gray-300 p-2 rounded-md "
                value={homeworkLevel}
                onChange={handleHomeWorkLevelChange}
              >
                <option selected value={""}>
                  {t("Select Level")}
                </option>
                {SCHOOL_LEVELS.map((level) => (
                  <option key={level.value} value={level.value}>
                    {t(level.label)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="md:flex md:flex-row md:gap-[11.5rem] md:mb-3 md:mt-3 flex flex-col">
            <Form.Group className="mb-3 md:w-[20rem] w-[10rem] mt-3">
              <Form.Label>{t("Homework Language")}</Form.Label>
              <div className="shadow-md">
                <Switch
                  initValue={"English"}
                  values={Languages}
                  onSelect={handleHomeworkLanguageChange}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 md:w-[30rem] w-[15rem] mt-3">
              <Form.Label>{t("Homework Type")}</Form.Label>
              <Form.Select
                required
                value={homeworkType}
                onChange={handleHomeWorkTypeChange}
              >
                <option value={""}>{t("Select Homework Type")}</option>
                {homeworkLanguage === "English" && homeworkLevel === "Level1"
                  ? HOMEWORK_TYPES.English.level1.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : homeworkLanguage === "English" && homeworkLevel === "Level2"
                  ? HOMEWORK_TYPES.English.level2.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : homeworkLanguage === "English" && homeworkLevel === "Level3"
                  ? HOMEWORK_TYPES.English.level3.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : homeworkLanguage === "Hebrew" && homeworkLevel === "Level1"
                  ? HOMEWORK_TYPES.Hebrew.level1.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : homeworkLanguage === "Hebrew" && homeworkLevel === "Level2"
                  ? HOMEWORK_TYPES.Hebrew.level2.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : homeworkLanguage === "Hebrew" && homeworkLevel === "Level3"
                  ? HOMEWORK_TYPES.Hebrew.level3.map((type) => (
                      <option
                        key={type.name}
                        value={type.name + "-" + type.core}
                      >
                        {type.title}
                      </option>
                    ))
                  : ""}
              </Form.Select>
            </Form.Group>
            <div className="bg-[#CFF0C0] w-[3rem] h-[5rem] border rounded-tl-[50px] absolute bottom-0 right-0"></div>
          </div>
        </div>
        {loading ? (
          <div className="flex  justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="my-4">
            <div
              dir={isRTL() ? "rtl" : "ltr"}
              className="shadow-sm px-[1rem] py-2"
            >
              <p>
                {t("Choose Homework Words")}{" "}
                <span className="text-[#F28705]">
                  {" "}
                  ({t("at least 2 words")})
                </span>
              </p>

              <WordsTable
                words={words}
                homeworkTitle={homeworkTitle}
                homeworkLanguage={homeworkLanguage}
                isDefinition={homeworkType.startsWith("definitions")}
              />
            </div>
          </div>
        )}
      </Form>
      {/* // * Choose Exam */}
    </div>
  );
};

export default CreateHomeWork;

import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Exam, TeacherUserInfo } from "../../../../../../types";
import { BASE_URL } from "../../../../../../utils/Constans";
import {
  useFetch,
  useActions,
  useTypedSelector,
} from "../../../../../../hooks";
import { getAuthHeaders } from "../../../../../../utils/Generic";
import { ApiRequest } from "../../../../../../utils/ApiRequests";

const FetchTeachers_URL = `${BASE_URL}/FetchAllTeachers`;
const SHARE_EXAM_WITH_TEACHERS_UTL = `${BASE_URL}/ShareExamsWithTeachers`;

interface ShareExamProps {
  setShowShareModel: (newType: boolean) => void;
  newExam: Exam;
}

const ShareExam = ({ setShowShareModel, newExam }: ShareExamProps) => {
  const { MsgActions } = useActions();

  const [teachers, setTeachers] = useState<TeacherUserInfo[]>([]);
  const [newTitle, setNewTitle] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState<TeacherUserInfo[]>(
    []
  );

  useEffect(() => {
    setNewTitle(newExam.title);
  }, [newExam]);

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const [data, loading, error] = useFetch(
    FetchTeachers_URL,
    { schoolCode: user?.schoolCode },
    getAuthHeaders,
    "GET"
  );

  useEffect(() => {
    if (!loading && data && data.teachers) {
      const _teachers = (data.teachers as TeacherUserInfo[]).filter(
        (teacher) => teacher.uid !== user?.uid
      );
      setTeachers(_teachers);
    }
  }, [data, loading, error]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(event.target.value);
  };

  const handleShareExam = async () => {
    try {
      if (selectedTeachers == null || selectedTeachers.length == 0) {
        MsgActions.showMsg("Please select at least 1 teacher", "danger");
        return;
      }
      const _exam: Exam = {
        ...newExam,
        title: newTitle,
        createTime: new Date().getTime(),
      };
      const teachers_uid = selectedTeachers.map((teacher) => teacher.uid);
      const headers = await getAuthHeaders();
      await ApiRequest(
        SHARE_EXAM_WITH_TEACHERS_UTL,
        { teachers: teachers_uid, exam: _exam },
        "POST",
        headers
      );
      MsgActions.showMsg("ShareExamSucceeded", "success");
    } catch (err) {
      console.error((err as Error).message);
      MsgActions.showMsg("FailedToShareExam", "danger");
    }
  };

  const checkedValuesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // add checked group to selected groups
    if (checked) {
      const checkedGroups = teachers.map((data) =>
        data.key == name ? { ...data, checked: checked } : data
      );
      const filteredGroup = checkedGroups.filter((teacher) => teacher.checked);
      setSelectedTeachers([...selectedTeachers, ...filteredGroup]);
    } else {
      // remove unchecked group from selected groups
      const filteredTeacher = selectedTeachers.filter(
        (teacher) => teacher.key != name
      );
      setSelectedTeachers(filteredTeacher);
    }
  };

  return (
    <div>
      <Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
        <Modal.Header
          closeButton
          onClick={() => setShowShareModel(false)}
        ></Modal.Header>
        <Modal.Body>
          <div className="md:flex md:flex-row flex flex-col gap-4">
            <div>
              <label htmlFor="">Exam title</label>
              <input
                type="text"
                className="border p-2"
                value={newTitle}
                onChange={handleTitleChange}
              />
            </div>
            <button
              className="bg-[#3188C9] text-white md:px-4 md:py-2 mx-20 py-1 rounded font-bold"
              onClick={() => {
                handleShareExam();
                setShowShareModel(false);
              }}
            >
              Share Exam
            </button>
          </div>

          <h4 className="mt-4">Select teachers</h4>

          <ol className="ml-0">
            {teachers.map((teacher) => (
              <li key={teacher.name}>
                <div className="flex mt-2">
                  <input
                    type="checkbox"
                    aria-label={teacher.name}
                    onChange={checkedValuesHandler}
                    name={teacher.key}
                    checked={teacher.checked}
                  />
                  <span className="ml-5">{teacher.name}</span>
                </div>
              </li>
            ))}
          </ol>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareExam;

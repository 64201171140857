import React, { useState } from 'react';
import { Group, StudentTable } from '../../../../../../../types';
import { Icons } from '../../../../../../../assets/styles';

import { useNavigate } from 'react-router-dom';
import DeleteGroup from '../../../DeleteGroup/DeleteGroup';
import ViewGroup from '../../../ViewGroup/ViewGroup';

interface ShowGroupsProps {
	group: Group;
}

const ShowGroupCard = ({ group }: ShowGroupsProps) => {
	const [showModel, setShowModel] = useState(false);
	const [showViewModel, setViewShowModel] = useState(false);

	const navigate = useNavigate();
	const date = new Date(group.createdAt);
	const time = date.toLocaleTimeString();
	const formattedHour = time.split(':')[0];
	const formattedMinute = time.split(':')[1];
	const formattedTime = `${formattedHour}:${formattedMinute}`;

	const stringDate = date.toLocaleDateString();

	const handleOnEditPress = () => {
		navigate(`/groups/editGroup/${group.key}`);
	};

	const handleOnDeletePress = () => {
		setShowModel(!showModel);
	};

	const handleOnShowPress = () => {
		setViewShowModel(!showViewModel);
	};

	const key = group.key;

	return (
		<div className=" bg-white shadow-sm rounded-sm w-[13rem] flex flex-col justify-center relative">
			{showModel && <DeleteGroup setShowModel={setShowModel} groupKey={key} />}
			{showViewModel && (
				<ViewGroup setViewShowModel={setViewShowModel} groups={group} />
			)}
			<div className="flex flex-col justify-center items-center pt-4">
				<div className="h-[4rem] w-[4rem] bg-[#EFE2BC] text-[#BDA152] flex justify-center items-center rounded-[40px] cursor-pointer">
					<Icons.GroupIcon color="#BCA050" size={30} />
				</div>
				<div className="absolute top-0 left-0 bg-[#DBEDFA] py-1 px-2">
					{group.students.length}
				</div>
				<p className="text-lg font-bold pt-2">{group.title}</p>
				<div className=" border-t text-[#E0E0E0] pt-2 w-[11rem] ml-2"></div>
			</div>

			{/* // * Buttons */}
			<div className="flex justify-center gap-3 pt-2">
				<div
					className="flex flex-col justify-center 
			items-center"
				>
					<div
						className="bg-[#DBEDFA] p-1 rounded cursor-pointer"
						onClick={handleOnEditPress}
					>
						<Icons.EditIcon color="#0069A8" size={22} />
					</div>
					<p className="text-[#0069A8]">Edit</p>
				</div>
				<div
					className="flex flex-col justify-center 
items-center "
				>
					<div
						className="bg-[#F9F0D3] p-1 rounded cursor-pointer"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
						onClick={handleOnDeletePress}
					>
						<Icons.DeleteIcon color="#F59605" size={22} />
					</div>
					<p className="text-[#F59605]">Delete</p>
				</div>
				<div
					className="flex flex-col justify-center 
			items-center"
				>
					<div
						className="bg-[#DBEDFA] p-1 rounded cursor-pointer"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal"
						onClick={handleOnShowPress}
					>
						<Icons.ViewIcon color="#0069A8" size={22} />
					</div>
					<p className="text-[#0069A8]">View</p>
				</div>
			</div>

			{/* // * Timestamps */}
			<div className="flex justify-between mx-2 pt-8">
				<div className="text-[#ADADAD]">{stringDate}</div>
				<div className="text-[#ADADAD]">{formattedTime}</div>
			</div>
		</div>
	);
};

export default ShowGroupCard;

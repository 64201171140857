import React from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Group } from "../../../../../../types";
import GradeTable from "./GradeTable";
import { Icons } from "../../../../../../assets/styles";
import { generateExcelFile } from "../../../../../../utils/Reports";
interface SidebarProps {
  groups: Group[];
  setStudent: any;
  setExamData?: any;
}

const Sidebar = ({ groups, setStudent, setExamData }: SidebarProps) => {
  const { t } = useTranslation();

  const [studentExams, setStudentExams] = React.useState<any[]>([]);
  const [group, setGroup] = React.useState<Group>(groups[0]);
  //   console.log(group);
  const handleGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const group = groups.find((g) => g.key === e.target.value);
    setGroup(group!);
  };

  const handleDownloadExamResults = () => {
    const groupName = group.title;
    const headers = ["Username", "Name", "Grade"];
    const rows = [];
    for (let student of studentExams) {
      let grade = "-";
      if (student.marks !== undefined) {
        grade = student.marks;
      }

      rows.push([student.uid, student.name, grade.toString()]);
    }
    const date = new Date().toLocaleString();
    const filename = `MYLEXISPLUS_EXAM_REPORT_${date}`;

    generateExcelFile({ rows, columns: headers }, filename, groupName);
  };

  return (
    <div className="flex gap-3 md:h-full h-fit">
      <div className="border-l-[10px] border-[#B6DAEB] rounded-l-lg" />
      <div>
        <Stack direction="horizontal" gap={5}>
          <div className="w-100">
            <Form.Label>{t("Select Group")}</Form.Label>
            <Form.Select
              required
              value={group.key} // Set the value to the key of the current group
              onChange={(e) => handleGroup(e)}
            >
              {groups.map((group) => (
                <option key={group.key} value={group.key}>
                  {group.title}
                </option>
              ))}
            </Form.Select>
          </div>
          <Button variant="success" onClick={handleDownloadExamResults}>
            <Icons.DownloadIcon color="var(--white)" />
          </Button>
        </Stack>

        {group && (
          <GradeTable
            studentExams={studentExams}
            setStudentExams={setStudentExams}
            group={group}
            setStudent={setStudent}
            setExamData={setExamData}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;

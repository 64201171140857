import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../../hooks';
import { useFetch, useActions } from '../../../../hooks';
import { BASE_URL } from '../../../../utils/Constans';
import { getAuthHeaders } from '../../../../utils/Generic';
import { Exam } from '../../../../types';
import { Spinner } from 'react-bootstrap';
import ExamList from './components/ExamList';

const FetchExam_URL = `${BASE_URL}/FetchAllExamsByStudentId`;

const EnglishExam = () => {
	const { ExamActions } = useActions();
	const { user } = useTypedSelector(state => state.AuthReducer);

	const [studentExams, setStudentExams] = useState<Exam[]>([]);
	const [data, loading, error] = useFetch(
		FetchExam_URL,
		{ studentId: user?.key, language: 'English' },
		getAuthHeaders,
		'GET'
	);

	useEffect(() => {
		if (!loading && data !== null && data?.exams?.length > 0) {
			// sort exams by date
			data.exams.sort((a: Exam, b: Exam) => {
				return (
					new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
				);
			});
			setStudentExams(data.exams);
			ExamActions.setStudentExams(data.exams);
		}
	}, [data, loading, error]);

	return (
		<section>
			{loading && (
				<div className="flex justify-center items-center">
					<Spinner animation="border" />
				</div>
			)}

			{!loading && (!data || data?.exams?.length === 0) && (
				<div className="flex justify-center items-center">
					<h1>No exams</h1>
				</div>
			)}

			{!loading && data && data.exams && data.exams.length > 0 && (
				<div className="w-[20rem] md:w-full mx-2">
					<ExamList exams={studentExams} />
				</div>
			)}
		</section>
	);
};

export default EnglishExam;

import React from "react";
import "./YesNoModal.css";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
interface YesNoModalProps {
  YesPress: VoidFunction;
  NoPress: VoidFunction;
  msg: string;
  title: string;
  visible: boolean;
  onDissmis: VoidFunction;
}
function YesNoModal({
  title,
  msg,
  visible,
  YesPress,
  NoPress,
  onDissmis,
}: YesNoModalProps) {
  const { t } = useTranslation();
  return (
    <div className="YesNoModal">
      <Modal show={visible} onHide={onDissmis}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{msg}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={NoPress}>
            {t("No")}
          </Button>
          <Button variant="primary" onClick={YesPress}>
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default YesNoModal;

import React from "react";
import "./features.css";
import {
  LOGIN_IMAGE2,
  LoginService1,
  LoginService2,
  LoginService3,
} from "../../../../assets/images";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../locales";

function Features() {
  const { t } = useTranslation();

  return (
    <div className="Features">
      <div>
        <img src={LOGIN_IMAGE2} alt="LOGIN_IMAGE2" className="featureImage" />
      </div>

      <div>
        <h1 style={{ textAlign: isRTL() ? "right" : "left" }}>
          {t("t11")}
          <span> {t("t12")} </span>
          {t("t13")}
          <span> {t("t14")} </span>
        </h1>
        <Stack
          direction="horizontal"
          gap={2}
          style={{
            marginTop: 10,
            flexDirection: isRTL() ? "row-reverse" : "row",
          }}
        >
          <img src={LoginService1} alt="LoginService1" />
          <p style={{ textAlign: isRTL() ? "right" : "left" }}>{t("t15")}</p>
        </Stack>
        <Stack
          direction="horizontal"
          gap={2}
          style={{
            marginTop: 10,
            flexDirection: isRTL() ? "row-reverse" : "row",
          }}
        >
          <img src={LoginService2} alt="LoginService2" />
          <p style={{ textAlign: isRTL() ? "right" : "left" }}>{t("t16")}</p>
        </Stack>
        <Stack
          direction="horizontal"
          gap={2}
          style={{
            marginTop: 10,
            flexDirection: isRTL() ? "row-reverse" : "row",
          }}
        >
          <img src={LoginService3} alt="LoginService3" />
          <p style={{ textAlign: isRTL() ? "right" : "left" }}>{t("t17")}</p>
        </Stack>
      </div>
    </div>
  );
}

export default Features;

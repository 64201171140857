import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { isRTL } from "../../../../../locales";
import {
  Exam,
  StudentTable,
  StudentsExamsDashboard,
} from "../../../../../types";
import { useTranslation } from "react-i18next";
import { Icons } from "../../../../../assets/styles";
import { generateExcelFile } from "../../../../../utils/Reports";

type StudentsAverageTable = {
  data: StudentsExamsDashboard[];
  studentsGroup: StudentTable[];
};

type Row = {
  exams: (Exam | undefined)[];
  student: StudentTable;
};

const StudentsAverageTable = ({
  data,
  studentsGroup,
}: StudentsAverageTable) => {
  const { t } = useTranslation();

  const [headers, setHeaders] = React.useState<Exam[]>([]);
  const [rows, setRows] = React.useState<Row[]>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [selectedRow, setSelectedRow] = React.useState<Row | undefined>(
    undefined
  );

  React.useEffect(() => {
    const _rows = [];
    setHeaders(data.map((item) => item.teacherExam));
    for (let student of studentsGroup) {
      const studentExams = [];
      for (let item of data) {
        const found = item.studentsExamDetails.find(
          (e) => e.student.uid === student.uid
        );
        studentExams.push(found?.exam);
      }
      _rows.push({ exams: studentExams, student: student });
    }

    setRows(_rows);
  }, [data]);

  const getMark = (exam: Exam | undefined, default_mark = "❌") => {
    if (!exam) return default_mark;
    if (exam.marks === undefined) return default_mark;
    return Math.round(exam.marks);
  };

  const handleViewBtnPress = (row: Row) => {
    setSelectedRow(row);
    setVisible(true);
  };

  const handleViewExam = (exam: Exam, uid: string) => {
    if (exam?.examType === "WrittenProduction") {
      window.open(`/exams/TopicExam/${exam.key}/${uid}`, "_blank");
    } else if (exam?.examType === "CobeExam") {
      window.open(`/exams/CobeExam/${exam.key}/${uid}`, "_blank");
    } else {
      window.open(`/exams/SampleExamResult/${exam.key}/${uid}`, "_blank");
    }
  };

  const handelDownloadExamsRestuls = () => {
    const _coulumns = [
      "Username",
      "Student Name",
      ...headers.map((h) => h.title),
    ];
    const _rows = [];
    for (let row of rows) {
      const _row = [row.student.uid, row.student.name];
      const _grades = row.exams.map((ex) => getMark(ex, "-").toString());
      _rows.push([..._row, ..._grades]);
    }
    const date = new Date().toLocaleString();
    const filename = `MYLEXISPLUS_EXAMS_REPORT_${date}`;
    generateExcelFile({ columns: _coulumns, rows: _rows }, filename);
  };

  return (
    <div className="mt-3">
      <Button
        variant="info"
        className="downloadExamsResults"
        onClick={handelDownloadExamsRestuls}
      >
        <Icons.DownloadIcon color="var(--text)" />
      </Button>

      <Modal
        size="lg"
        show={visible}
        onHide={() => {
          setVisible(false);
          setSelectedRow(undefined);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {selectedRow?.student.uid} - {selectedRow?.student.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover bordered dir={isRTL() ? "rtl" : "ltr"}>
            <thead>
              <tr>
                <th>{t("ExamTitle")}</th>
                <th>{t("Grade")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedRow?.exams.map((exam, index) => (
                <tr key={index.toString()}>
                  <td>{headers[index].title}</td>
                  <td>{getMark(exam)}</td>
                  <td>
                    <Button
                      className="btn-success"
                      onClick={() =>
                        handleViewExam(headers[index], selectedRow?.student.uid)
                      }
                    >
                      {t("view")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Table striped hover bordered dir={isRTL() ? "rtl" : "ltr"}>
        <thead>
          <tr>
            <th>
              <div>{t("username")}</div>
            </th>
            <th>
              <div>{t("Name")}</div>
            </th>
            {headers.map((item, index) => (
              <th key={index.toString()}>
                <div>{item.title}</div>
              </th>
            ))}

            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index.toString()}>
              <td>{row.student.uid}</td>
              <td>{row.student.name}</td>
              {row.exams.map((exam, j) => (
                <td key={j.toString()}>{getMark(exam)}</td>
              ))}
              <td>
                <Button
                  className="btn-success"
                  onClick={() => handleViewBtnPress(row)}
                >
                  {t("view")}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default StudentsAverageTable;

import { useTranslation } from "react-i18next";
import "./DashboardScreen.css";
import React from "react";
import { Alert, Form, Spinner } from "react-bootstrap";

import { isRTL } from "../../../../locales";
import { BASE_URL } from "../../../../utils/Constans";
import { useActions, useFetch, useTypedSelector } from "../../../../hooks";
import { getAuthHeaders } from "../../../../utils/Generic";
import { Group, StudentsExamsDashboard } from "../../../../types";
import StudentsAverageTable from "./components/StudentsAverageTable";
import { ApiRequest } from "../../../../utils/ApiRequests";

const FetchTeacherGroupsURL = `${BASE_URL}/FetchGroups`;
const FetchExamsOfGroupURL = `${BASE_URL}/FetchExamsOfGroup`;

const DashboardScreen = () => {
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { MsgActions } = useActions();
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [groupExamsData, setGroupExamsData] = React.useState<
    StudentsExamsDashboard[]
  >([]);
  const [errorMsg, setErrorMsg] = React.useState<string>("");
  const [selectedGroup, setSelectedGroup] = React.useState<Group | undefined>(
    undefined
  );
  const [data, loading, error, _] = useFetch(
    FetchTeacherGroupsURL,
    { teacherId: user?.uid },
    getAuthHeaders,
    "GET"
  );

  React.useEffect(() => {
    if (!loading && data?.groups) {
      setGroups(data.groups);
      if (data.groups.lenght > 0) {
        setSelectedGroup(data.groups[0]);
      }
      return;
    }

    if (error) {
      MsgActions.showMsg("FetchFailed", "danger");
    }
  }, [data, loading, error]);

  const handleSelectGroup = (groupKey: string) => {
    setGroupExamsData([]);

    if (groupKey === "") {
      setSelectedGroup(undefined);
      return;
    }
    const _group = groups.find((g) => g.key === groupKey);
    setSelectedGroup(_group);
  };

  const handleFetchGroupExamsData = async (group: Group) => {
    try {
      setFetching(true);
      setErrorMsg("");
      const headers = await getAuthHeaders();
      const response = await ApiRequest(
        FetchExamsOfGroupURL,
        { group },
        "GET",
        headers
      );
      setGroupExamsData(response.data.exams);
    } catch (err) {
      console.log((err as Error).message);
      setErrorMsg((err as Error).message);
    } finally {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    if (selectedGroup) {
      handleFetchGroupExamsData(selectedGroup);
    }
  }, [selectedGroup]);

  return (
    <div className="DashboardScreen" dir={isRTL() ? "rtl" : "ltr"}>
      <h1>{t("Statistics")}</h1>
      <div className="w-50">
        {/* select group */}
        <Form.Label>{t("Select students group")}</Form.Label>
        <Form.Select onChange={(e) => handleSelectGroup(e.target.value)}>
          <option value={""}>{t("Select students group")}</option>
          {groups.map((group) => (
            <option key={group.key} value={group.key}>
              {group.title}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="mt-2">
        {fetching && (
          <Spinner className="mt-3" animation="border" variant="primary" />
        )}
        {/* studnets avg chart */}
        {selectedGroup && !fetching && (
          <StudentsAverageTable
            data={groupExamsData}
            studentsGroup={selectedGroup.students}
          />
        )}
        {errorMsg && (
          <Alert className="w-50" variant={"danger"}>
            {t("NoDataFound")}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default DashboardScreen;

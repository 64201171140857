import React from 'react';
import './StepThree.css';
import { Button, Card, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
	SortableItemProps,
	SortableList,
} from '@thaddeusjiang/react-sortable-list';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useActions, useTypedSelector } from '../../../../../../../hooks';
import {
	MCQuestion,
	MatchingQuestion,
	Question,
	SampleExam,
	Exam,
} from '../../../../../../../types';
import { Icons } from '../../../../../../../assets/styles';
import { MIN_EXAM_QUESTIONS_NUM } from '../../../../../../../utils/Constans';
import { generateSampleExamWordDocument } from '../../../../../../../utils/Reports';
import MatchingQuestionSortable from './components/MatchingSortable/MatchingQuestionSortable';
import MCQuestionSortable from './components/MCQSortable/MCQuestionSortable';
import { isRTL } from '../../../../../../../locales';

interface SortableQuestion extends SortableItemProps {
	question: Question;
}

interface StepThreeProps {
	singleExam?: Exam;
}

function StepThree({ singleExam }: StepThreeProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { key } = useParams();

	const { exam, loading } = useTypedSelector(state => state.ExamReducer);
	const { user } = useTypedSelector(state => state.AuthReducer);
	const { msgType } = useTypedSelector(state => state.MsgReducer);
	const [questions, setQuestions] = React.useState<SortableItemProps[]>([]);
	const [drag, setDrag] = React.useState(false);

	const { ExamActions, MsgActions } = useActions();

	React.useEffect(() => {
		const _questions = (exam as SampleExam).questions?.map(question => {
			return { id: question.key || '', question };
		});

		if (_questions) setQuestions(_questions);
	}, []);

	React.useEffect(() => {
		if (msgType == 'success') {
			navigate('/exams/list');
		}
	}, [msgType]);

	const handleCreateSampleExam = () => {
		if (loading) return;
		const _exam = { ...exam } as SampleExam;
		_exam.questions = questions.map(question => {
			return { ...(question as SortableQuestion).question };
		});
		_exam.questionsRef = _exam.questions.map(
			question => question.questionRef || ''
		);
		delete _exam.questions;

		_exam.createTime = new Date().getTime();

		if (_exam && user) {
			ExamActions.saveExam(_exam, user.uid);
		} else {
			MsgActions.showMsg('FailedToCreateExam', 'danger');
		}
	};
	const handleUpdateSampleExam = () => {
		if (loading) return;
		const _exam = { ...exam } as SampleExam;
		_exam.questions = questions.map(question => {
			return { ...(question as SortableQuestion).question };
		});
		_exam.questionsRef = _exam.questions.map(
			question => question.questionRef || ''
		);
		delete _exam.questions;

		// _exam.createTime = new Date().getTime();

		if (_exam && user) {
			ExamActions.editExam(user?.key, singleExam?.key, _exam);
		} else {
			MsgActions.showMsg('FailedToCreateExam', 'danger');
		}
	};

	const handleRemoveQuestion = (questionId: string) => {
		const _questions = questions.filter(question => question.id != questionId);

		if (_questions.length < MIN_EXAM_QUESTIONS_NUM) {
			return;
		}

		setQuestions(_questions);
	};

	const handleDownloadExam = () => {
		if (!exam) return;
		const questions: Question[] = (exam as SampleExam).questions || [];
		generateSampleExamWordDocument(
			exam.title,
			questions,
			exam.language == 'Hebrew'
		);
	};

	return (
		<div className="StepThree">
			<Card>
				<Card.Header dir={isRTL() ? 'rtl' : 'ltr'}>
					<h3>{t('questionsNumber')}</h3>
					<div className="text-secondary">
						{t('Hold press to drag and sort the exam questions')}
					</div>
					<div>
						{t('Total questions')}:{' '}
						<span>{(exam as SampleExam).questions?.length || 0}</span>
					</div>
					<Stack
						direction="horizontal"
						gap={2}
						dir="rtl"
						style={{ marginTop: 20 }}
					>
						{!key && (
							<Button
								className="createSampleExamBtn"
								onClick={handleCreateSampleExam}
							>
								{t('t7')}
							</Button>
						)}
						{key && (
							<Button
								className="createSampleExamBtn"
								onClick={handleUpdateSampleExam}
							>
								{t('EditExam')}
							</Button>
						)}
						<Button
							variant="info"
							className="downloadSampleExam"
							onClick={handleDownloadExam}
						>
							<Icons.DownloadIcon color="var(--text)" />
						</Button>
					</Stack>
				</Card.Header>
				<Card.Body>
					<div
						className={`dargWrapper ${drag ? 'dargAble' : 'dragDisable'} `}
						onClick={() => setDrag(!drag)}
					>
						<Icons.SortIcon color={drag ? 'white' : 'black'} />
					</div>
					<SortableList
						disabled={!drag}
						items={questions}
						setItems={setQuestions}
						itemRender={({ item }) => {
							if ((item as SortableQuestion).question.answerType == 'MCQ') {
								return (
									<MCQuestionSortable
										key={item.id}
										question={(item as SortableQuestion).question as MCQuestion}
										onRemovePress={() => handleRemoveQuestion(item.id)}
										drag={drag}
									/>
								);
							} else if (
								(item as SortableQuestion).question.answerType == 'Matching'
							) {
								return (
									<MatchingQuestionSortable
										key={item.id}
										question={
											(item as SortableQuestion).question as MatchingQuestion
										}
										onRemovePress={() => handleRemoveQuestion(item.id)}
										drag={drag}
									/>
								);
							}
							return <div key={item.id} />;
						}}
					/>
				</Card.Body>
			</Card>
		</div>
	);
}

export default StepThree;

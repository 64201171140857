import {
  Table,
  Document,
  Packer,
  Paragraph,
  TableCell,
  TableRow,
  TextRun,
  BorderStyle,
} from "docx";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { MCQuestion, MatchingQuestion, Question } from "../types";
import { shuffle } from "./Generic";

interface ExcelData {
  columns: string[];
  rows: any[];
}

const saveDocumentToFile = (doc: Document, fileName: string) => {
  const mimeType =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    saveAs(docblob, fileName);
  });
};

export const generateSampleExamWordDocument = (
  examName: string,
  questions: Question[],
  isRtl: boolean
) => {
  let paragraphs: (Paragraph | Table)[] = [];
  let i = 0;
  for (let question of questions) {
    if (question.answerType == "MCQ") {
      paragraphs = [
        ...paragraphs,
        ...generateMCQparagraph(question as MCQuestion, isRtl, i),
      ];
    } else if (question.answerType == "Matching") {
      paragraphs = [
        ...paragraphs,
        ...generateMatchingParagraph(question as MatchingQuestion, isRtl, i),
      ];
    }

    i += 1;
  }

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: paragraphs,
      },
    ],
  });

  saveDocumentToFile(doc, `${examName}.docx`);
};

const generateMatchingParagraph = (
  question: MatchingQuestion,
  isRtl: boolean,
  index: number
) => {
  const paragraphs = [];
  let title: Paragraph;

  title = new Paragraph({
    children: [
      new TextRun({
        text: `${index + 1}) ` + question.title,
        rightToLeft: isRtl,
        size: 24,
      }),
    ],
  });

  const rows = [];
  question.options = shuffle(question.options);
  question.question = shuffle(question.question);

  for (let i = 0; i < question.options.length; i++) {
    const row = new TableRow({
      children: [
        new TableCell({
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `${i + 1}) ${question.options[i]}`,
                  rightToLeft: isRtl,
                }),
              ],
            }),
          ],
        }),
        new TableCell({
          borders: {
            top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
            right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
          },

          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text:
                    i < question.question.length
                      ? `___ ${question.question[i]}`
                      : "",
                  rightToLeft: isRtl,
                }),
              ],
            }),
          ],
        }),
      ],
    });

    rows.push(row);
  }

  const table = new Table({ rows: rows });

  paragraphs.push(title);
  paragraphs.push(new Paragraph({ children: [] })); // new empty line
  paragraphs.push(table);
  paragraphs.push(new Paragraph({ children: [] })); // new empty line

  return paragraphs;
};

const generateMCQparagraph = (
  question: MCQuestion,
  isRtl: boolean,
  i: number
) => {
  const paragraphs = [];

  let pQuestion: Paragraph;
  let options: Paragraph[];
  let title: Paragraph;

  title = new Paragraph({
    children: [
      new TextRun({
        text: `${i + 1}) ` + question.title,
        rightToLeft: isRtl,
        size: 24,
      }),
    ],
  });

  pQuestion = new Paragraph({
    children: question.question.split("@").map(
      (value, index) =>
        new TextRun({
          text: value,
          bold: index % 2 != 0,
          rightToLeft: isRtl,
        })
    ),
  });

  options = shuffle(question.options).map(
    (value, index) =>
      new Paragraph({
        children: [
          new TextRun({
            text: `${index + 1}) ${value}`,
            rightToLeft: isRtl,
          }),
        ],
      })
  );

  paragraphs.push(title);
  paragraphs.push(new Paragraph({ children: [] })); // new empty line
  paragraphs.push(pQuestion);
  for (let option of options) {
    paragraphs.push(option);
  }
  paragraphs.push(new Paragraph({ children: [] })); // new empty line

  return paragraphs;
};

export const generateExcelFile = (
  data: ExcelData,
  fileName: string,
  sheetName = "Sheet1"
) => {
  const { columns, rows } = data;

  const worksheet = XLSX.utils.aoa_to_sheet([columns, ...rows]);

  // Set column widths to 200 (200 pixels)
  const colWidths = columns.map(() => ({ wpx: 200 }));
  worksheet["!cols"] = colWidths;

  // Create a style for centering the text
  const centerStyle = { alignment: { horizontal: "center" } };

  // Apply the centering style to all cells in the worksheet
  for (let R = 0; R < rows.length + 1; R++) {
    for (let C = 0; C < columns.length; C++) {
      const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
      if (!worksheet[cellRef]) worksheet[cellRef] = {};
      worksheet[cellRef].s = centerStyle;
    }
  }

  // Create a new workbook and save the file
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks";
import { useFetch } from "../../../../hooks";
import { BASE_URL } from "../../../../utils/Constans";
import { getAuthHeaders } from "../../../../utils/Generic";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../locales";
import axios from "axios";
import ListExamTime from "../../TeacherScreens/ExamScreens/ListExam/Components/ListExamTime";
import SampleResultCard from "./SampleResultCard";
import { Table, Pagination } from "react-bootstrap";

const ViewSampleResults = () => {
  const { key, uid } = useParams();
  const { t } = useTranslation();
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);

  const currentExam = studentExams?.find((exam) => exam?.key === key) as any;

  const [studentExam, setStudentExam] = useState<any>();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);

  const [student, setStudentData] = useState<any>();

  const rowsPerPage = 4;

  useEffect(() => {
    setPages(Math.ceil(studentExam?.length / rowsPerPage));
    if (studentExam?.length > 0) {
      setCurrentPage(0);
    }
  }, [studentExam]);

  const onPageChanged = (page: number) => {
    setStudentExam(
      studentExam.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    );
  };

  const handlePrevPage = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      onPageChanged(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < pages) {
      setCurrentPage(currentPage + 1);
      onPageChanged(currentPage + 1);
    }
  };

  const [studentData, studentLoading, studentError] = useFetch(
    `${BASE_URL}/GetUserInfo`,
    { username: uid, accountType: "Student" },
    getAuthHeaders,
    "POST"
  );

  useEffect(() => {
    if (!studentLoading && studentData && studentData.userInfo) {
      setStudentData(studentData.userInfo);
    }
  }, [studentData, studentLoading, studentError]);
  useEffect(() => {
    if (student) {
      const getStudentData = async () => {
        const headers = await getAuthHeaders();
        const params = {
          studentId: student.key,
          examId: key,
        };

        axios
          .get(`${BASE_URL}/GetStudentAnswer`, {
            params: { params: JSON.stringify(params) },
            headers: headers.headers,
          })
          .then((res) => {
            const answersByQuestion = res.data.answer?.answers.reduce(
              (acc: any, answer: any) => {
                const existingAnswer = acc.find(
                  (item: any) => item.questionId === answer.questionId
                );

                if (existingAnswer) {
                  existingAnswer.answers.push(answer);
                } else {
                  acc.push({
                    questionId: answer.questionId,
                    answers: [answer],
                    marks: res.data.answer.marks,
                  });
                }

                return acc;
              },
              []
            );

            setStudentExam(answersByQuestion);
          })
          .catch((err) => console.log(err));
      };

      getStudentData();
    }
  }, [studentData]);

  return (
    <div
      className="bg-[#ffffff] md:w-full h-full w-[20rem] mx-2 md-mx-0"
      style={{ minHeight: "calc(100vh + 100px)" }}
    >
      {studentExam && (
        <div className="bg-[#ffffff]">
          <p className="bg-[#f3f3f3] p-2 w-fit mx-4 my-4 text-[1.3rem]">
            {currentExam?.title}
          </p>

          <div className="md:flex md:flex-row md:justify-between md:mt-10 mx-10 shadow md:px-10 py-2 flex flex-col">
            <div className="flex gap-2 mx-10 md:mx-0">
              <p className="inline">Number of questions: </p>
              <h5> {currentExam?.questions?.length}</h5>
            </div>
            <div className=" bg-[#c9e4ff] px-10 mx-10 md:pt-4 md:-mt-16 rounded md:flex md:flex-col  flex justify-center md:items-center gap-4 md:gap-0 pt-2 ">
              <p className="inline"> Degree </p>
              <h5>{studentExam[0]?.marks}</h5>
            </div>
            <div>
              <ListExamTime examDate={studentExam?.submissionTime} />{" "}
            </div>
          </div>
          <div className="md:mx-10">
            <Table
              dir={isRTL() ? "rtl" : "ltr"}
              className="overflow-hidden py-2 md:w-full"
            >
              <thead>
                <tr>
                  <th>
                    <div className="md:flex md:justify-center  md:text-[1.3rem]  ">
                      {t("Question")}
                    </div>
                  </th>

                  <th>
                    <div className="md:flex md:justify-center  md:text-[1.3rem]">
                      {t("Your Answer")}
                    </div>
                  </th>
                  <th>
                    <div className="md:flex md:justify-center  md:text-[1.3rem]">
                      {t("Correct Answer")}
                    </div>
                  </th>
                  <th>
                    <div className="md:flex md:justify-center  md:text-[1.3rem]">
                      {t("Comment")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentExam?.map((answer: any, index: number) => (
                  <tr key={index.toString()}>
                    <td>
                      <div className="md:flex md:items-center text-[0.8rem] ">
                        {answer.answers[0].answerType === "Matching" && (
                          <SampleResultCard answer={answer.answers} />
                        )}
                        {answer.answers[0].answerType === "MCQ" && (
                          <div className="md:pl-8">
                            {answer.answers[0].question}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col gap-10 md:gap-0 items-center text-[0.8rem] ">
                        {answer.answers[0].answerType === "Matching" &&
                          answer.answers.map((item: any, index: number) => (
                            <div
                              key={index.toString()}
                              className="font-semibold"
                            >
                              <ol>
                                <li
                                  className={
                                    item.isCorrect
                                      ? "bg-[#67C06B] inline py-1 px-2 rounded"
                                      : "bg-[#FF6868] inline py-1 px-2 rounded"
                                  }
                                >
                                  {item.option}
                                </li>
                              </ol>
                            </div>
                          ))}

                        {answer.answers[0].answerType === "MCQ" && (
                          <div
                            className={
                              answer.answers[0].isCorrect
                                ? "bg-[#67C06B] inline py-1 md:px-2 rounded"
                                : "bg-[#FF6868] inline py-w md:px-2 rounded"
                            }
                          >
                            {answer.answers[0].answer}
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex flex-col gap-10 md:gap-0 items-center text-[0.8rem] h-[8vh]">
                        {answer.answers[0].answerType === "Matching" &&
                          answer.answers.map((item: any, index: number) => (
                            <div
                              key={index.toString()}
                              className="font-semibold"
                            >
                              <ol>
                                <li className=" bg-[#bcf5e5] inline py-1 px-2 rounded">
                                  {item.originalAnswer}
                                </li>
                              </ol>
                            </div>
                          ))}

                        {answer.answers[0].answerType === "MCQ" && (
                          <div className="bg-[#bcf5e5] inline py-1 px-2 rounded">
                            {answer.answers[0].originalAnswer}
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div className="md:flex md:justify-center md:items-center md:text-[0.8rem] h-[8vh]">
                        {answer.answers[0].comment || ""}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="flex justify-end">
              <Pagination.Prev onClick={handlePrevPage} />

              <Pagination.Item>
                <span>
                  {currentPage + 1} / {pages}
                </span>
              </Pagination.Item>

              <Pagination.Next onClick={handleNextPage} />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSampleResults;

import React from 'react';
import { StepIcon } from '../../../../../../../assets/icons';

const Sidebar = () => {
	return (
		<div className="flex gap-2 mt-2 shadow-sm relative h-full">
			<div className="border-l-[10px] border-[#B6DAEB] h-[33rem]  rounded-l-lg "></div>
			<div>
				<div className="flex mt-4 gap-2 items-center">
					<div className="bg-[#DEF1FA] rounded-2xl h-6 w-6 flex justify-center items-center">
						<StepIcon color="#9DC9DE" />
					</div>
					<div className="mt-3">
						<p className="text-[#18608C] text-[12px] font-bold ">
							Steps to create an exam
						</p>
					</div>
				</div>

				<div className="mt-4 mx-3">
					<h6 className="text-[14px] font-bold">The First Step</h6>
					<p className="font-normal text-[12px] text-[#ADADAD]">
						The first step is to fill in the basic information
					</p>
				</div>

				<div className="mt-6 mx-3">
					<h6 className="text-[14px] font-bold">The Second Step</h6>
					<p className="font-normal text-[12px] text-[#ADADAD]">
						Selecting questions from the database
					</p>
				</div>
				<div className="mt-6 mx-3">
					<h6 className="text-[14px] font-bold">The Third Step</h6>
					<p className="font-normal text-[12px] text-[#ADADAD]">
						Review the questions that have been added
					</p>
				</div>

				<div className="bg-[#CFF0C0] w-16 h-20 border rounded-tl-[50px] absolute bottom-0 right-0"></div>
			</div>
		</div>
	);
};

export default Sidebar;

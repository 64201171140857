import React from "react";
import { MatchingQuestion } from "../../../../../types";
import { Stack } from "react-bootstrap";

interface QuestionCardProps {
  question: MatchingQuestion;
  updateAnswers: (answers: string[]) => void;
  initValue: string[];
}

const MatchingQuestionCard = ({
  question,
  updateAnswers,
  initValue = [],
}: QuestionCardProps) => {
  const [answers, setAnswers] = React.useState<string[]>([]);
  const [selectedAnswer, setSelectedAnswer] = React.useState("");

  React.useEffect(() => {
    setAnswers(initValue);
    setSelectedAnswer("");
  }, [question, initValue]);

  const handleSelectAnswer = (option: string) => {
    var ans = [...answers];
    if (ans.includes(option)) {
      const index = ans.indexOf(option);
      ans[index] = "";
      setAnswers(ans);
    } else {
      setSelectedAnswer(option);
    }
  };

  const handleQuestionPress = (quest: string, index: number) => {
    var _answers = [...answers];
    _answers[index] = selectedAnswer;
    setSelectedAnswer("");
    setAnswers(_answers);
    let isAllAnswered = true;
    for (let answer of _answers) {
      if (answer === "" || answer === undefined) {
        isAllAnswered = false;
        break;
      }
    }

    if (isAllAnswered) {
      // call update question answer
      updateAnswers(_answers);
    }
  };

  const getAnswerIndex = (option: string) => {
    return question.options.indexOf(option);
  };

  return (
    <div className=" w-[20rem] md:w-full mx-4 md:mx-0">
      <div
        className="flex flex-col justify-center items-center md:h-[50vh] "
        // style={{ minHeight: 'calc(100vh + 400px)' }}
      >
        <div className="flex flex-col shadow-md px-10 py-2 bg-[#FAFAFA] my-[4rem] md:my-0">
          <h5 className="font-bold mt-2">{question.title}</h5>
          {/* <p className="mt-1 text-[1.3rem]">{question.question}</p> */}
          <Stack
            gap={2}
            direction="horizontal"
            className="flex flex-col md:flex-row md:flex md:justify-evenly "
          >
            {question.options.map((option: string, index) => (
              <div
                key={index.toString()}
                className={`shadow-sm md:my-4 my-2  flex gap-2 md:text-[1.3rem] h-12 py-1 md:justify-center md:items-center cursor-pointer md:px-2 rounded ${
                  selectedAnswer == option ? "bg-[#3A8688]" : "bg-[#FFFFFF]"
                }
									${answers.includes(option) ? "line-through bg-white" : ""}
									`}
                onClick={() => handleSelectAnswer(option)}
              >
                <h6 className="text-center bg-[#9BDE7E] rounded-full h-8 w-8 flex justify-center items-center">
                  {index + 1}
                </h6>
                <p className="flex flex-col justify-end mb-2">{option}</p>
              </div>
            ))}
          </Stack>

          {question.question.map((question: string, index) => (
            <div
              key={index.toString()}
              className={`shadow-md my-2  flex gap-2 text-[1.3rem]  px-2 ${
                selectedAnswer == ""
                  ? "bg-[#EEEEEE] cursor-pointer"
                  : "bg-[#EEEEEE]"
              }`}
              onClick={() => handleQuestionPress(question, index)}
            >
              <div className="border-b-2 border-[#263238] w-6 flex flex-col justify-end mb-2">
                {answers[index] === undefined || answers[index] === ""
                  ? ""
                  : getAnswerIndex(answers[index]) + 1}
              </div>
              <div className="flex flex-col justify-end mb-2">{question}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MatchingQuestionCard;

import { Modal, Button } from 'react-bootstrap';
import { Exam } from '../../../../../../types';
import { useNavigate } from 'react-router-dom';
import { getAuthHeaders } from '../../../../../../utils/Generic';
import { useActions, useTypedSelector } from '../../../../../../hooks';
import { BASE_URL } from '../../../../../../utils/Constans';
import axios from 'axios';

const SendAnswers_URL = `${BASE_URL}/UpdateStudentExamAnswers`;

interface SubmitModalProps {
	setShowUpdateModel: (newType: boolean) => void;
	currentExam: any;
	answer: string;
}

const SubmitModal = ({
	setShowUpdateModel,
	currentExam,
	answer,
}: SubmitModalProps) => {
	const { MsgActions } = useActions();
	const navigate = useNavigate();
	const { user } = useTypedSelector(state => state.AuthReducer);

	const handleSendAnswers = async () => {
		if (answer === '') {
			MsgActions.showMsg('You must answer all the questions !', 'danger');
			return;
		}
		const completeAnswer = [
			{
				questionId: currentExam.topic.key,
				answer: answer,
			},
		];
		const headers = await getAuthHeaders();
		axios
			.put(
				SendAnswers_URL,
				{
					userId: user?.key,
					examId: currentExam?.key,
					answers: completeAnswer,
				},
				{ headers: headers.headers }
			)
			.then(res => {
				// console.log(res.data);
				MsgActions.showMsg('Answers submitted successfully', 'success');
				navigate('/student/exams');
			})
			.catch(err => {
				console.log(err);
				MsgActions.showMsg('Failed to submit exam answers', 'danger');
			});
	};

	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowUpdateModel(false)}
				></Modal.Header>
				<Modal.Body>
					<h4>Send answers</h4>
					Are you sure you want to send answers ?
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setShowUpdateModel(false);
						}}
					>
						No
					</Button>
					<Button
						variant="success"
						onClick={() => {
							setShowUpdateModel(false);
							handleSendAnswers();
						}}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default SubmitModal;

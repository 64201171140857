import React from "react";
import "./Switch.css";
import { LabelsType } from "../../types";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "react-bootstrap";

interface SwitchProps {
  values: LabelsType[];
  initValue?: string;
  onSelect: (value: string) => void;
}

function Switch({ values, initValue, onSelect }: SwitchProps) {
  const [selectedValue, setSelectedValue] = React.useState(initValue);
  const { t } = useTranslation();

  const handleSelectItem = (itemValue: string) => {
    setSelectedValue(itemValue);
    onSelect(itemValue);
  };

  return (
    <Stack direction="horizontal" gap={2} className="SwitchWrapper">
      {values.map(({ value, label }) => (
        <Button
          onClick={() => handleSelectItem(value)}
          className="itemBtn"
          key={value}
          style={selectedValue == value ? styles.selectStyle : {}}
        >
          {t(label)}
        </Button>
      ))}
    </Stack>
  );
}

const styles = {
  selectStyle: {
    fontWeight: "bold",
    color: "white",
    backgroundColor: "rgb(134, 196, 148)",
  },
};

export default Switch;

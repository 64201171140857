import React from "react";
import "./CheckedTable.css";
import { Form, Pagination, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../locales";

interface CheckedTableProps {
  totalData: number;
  headers: string[];
  children: React.ReactNode;
  onPageChanged: (page: number) => void;
  onCheckedAll: (checked: boolean) => void;
  rowsPerPage: number;
  isAllChecked?: boolean;
  onSearch: (txt: string) => void;
}

function CheckedTable({
  totalData,
  onPageChanged,
  onCheckedAll,
  children,
  headers,
  rowsPerPage,
  isAllChecked,
  onSearch,
}: CheckedTableProps) {
  const { t } = useTranslation();
  const [pages, setPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(-1);
  const [search, setSearch] = React.useState("");
  const [startIndex, setStartIndex] = React.useState(0);

  React.useEffect(() => {
    setPages(Math.ceil(totalData / rowsPerPage));
    if (totalData > 0) {
      setCurrentPage(0);
    }
  }, [totalData]);

  const handleNextPage = () => {
    if (currentPage + 1 < pages) {
      setCurrentPage(currentPage + 1);
      onPageChanged(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      onPageChanged(currentPage - 1);
    }
  };

  const handleCheckAllChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onCheckedAll(isChecked);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value.replace(/[\u0591-\u05C7]/g, "").toLowerCase();
    setSearch(txt);
    onSearch(txt);
  };

  return (
    <div className="CheckedTable">
      {/* search bar */}
      <Form.Control
        className="search"
        style={{ maxWidth: 300 }}
        value={search}
        required
        type="text"
        placeholder={`${t("Search")}...`}
        onChange={handleSearch}
      />
      {/* questions table */}
      <Table striped hover bordered dir={isRTL() ? "rtl" : "ltr"}>
        <thead>
          <tr>
            <th>
              <div>
                <Form.Check
                  checked={isAllChecked}
                  onChange={handleCheckAllChecked}
                />
              </div>
            </th>
            {headers.map((header, index) => (
              <th key={index.toString()}>
                <div>{header}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>

      {/* pages */}
      <Pagination>
        <Pagination.Prev onClick={handlePrevPage} />

        <Pagination.Item>
          <span>
            {currentPage + 1} / {pages}
          </span>
        </Pagination.Item>

        <Pagination.Next onClick={handleNextPage} />
      </Pagination>
    </div>
  );
}

export default CheckedTable;

import React from "react";
import "./InputTag.css";
import { Badge, CloseButton, Stack } from "react-bootstrap";

interface TagProps {
  tagText: string;
  onPress: VoidFunction;
}

const TagElement = ({ tagText, onPress }: TagProps) => {
  return (
    <Badge bg="success" className="Tag">
      <Stack direction="horizontal" gap={2}>
        {tagText}
        <CloseButton
          onClick={onPress}
          variant="white"
          style={{ height: 5, width: 5 }}
        />
      </Stack>
    </Badge>
  );
};

export type Tag = { label: string; value: string };

interface InputTags {
  tags: Tag[];
  onTagPress: (tag: Tag) => void;
}

function InputTag({ tags, onTagPress }: InputTags) {
  return (
    <div className="InputTag">
      {tags.map((tag) => (
        <TagElement
          key={tag.value}
          tagText={tag.label}
          onPress={() => onTagPress(tag)}
        />
      ))}
    </div>
  );
}

export default InputTag;

import { ActionExamType } from "../actions/types";
import { Exam, CobeExam, WrittenProductionExam } from "../types";

export interface ExamState {
  studentExams?: any[];
  exam?: Exam;
  exams?: Exam[];
  singleExam?: Exam;
  writtenExam?: WrittenProductionExam;
  loading?: boolean;
}

const defaultProps: ExamState = {
  studentExams: [],
  exam: undefined,
  exams: [],
  singleExam: undefined,
  writtenExam: undefined,
  loading: false,
};

interface ActionStudentExams {
  type: ActionExamType.STUDENT_EXAMS;
  payload: any[];
}

interface ActionUpdateExam {
  type: ActionExamType.UPDATE_EXAM;
  payload: Exam;
}

interface ActionUpdateCobeExam {
  type: ActionExamType.UPDATE_COBE_EXAM;
  payload: CobeExam;
}

interface ActionsCleanExam {
  type: ActionExamType.CLEAN_EXAM;
}
interface ActionsFetchExam {
  type: ActionExamType.FETCH_EXAM_LIST;
  payload: Exam[];
}
interface ActionsFetchSingleExam {
  type: ActionExamType.FETCH_SINGLE_EXAM;
  payload: Exam;
}
interface ActionsFetchWrittenExam {
  type: ActionExamType.FETCH_WRITTEN_EXAM;
  payload: WrittenProductionExam;
}

interface ActionSaveExam {
  type: ActionExamType.SAVEING_EXAM | ActionExamType.SAVE_EXAM_COMPLETE;
}

export type ActionExam =
  | ActionSaveExam
  | ActionUpdateExam
  | ActionUpdateCobeExam
  | ActionsCleanExam
  | ActionsFetchExam
  | ActionsFetchSingleExam
  | ActionsFetchWrittenExam
  | ActionStudentExams;

const ExamReducer = (state: ExamState = defaultProps, action: ActionExam) => {
  switch (action.type) {
    case ActionExamType.STUDENT_EXAMS:
      return { ...state, studentExams: [...action.payload] };
    case ActionExamType.FETCH_EXAM_LIST:
      return { ...state, exams: [...action.payload] };
    case ActionExamType.FETCH_SINGLE_EXAM:
      return { ...state, singleExam: { ...action.payload } };
    case ActionExamType.FETCH_WRITTEN_EXAM:
      return { ...state, writtenExam: { ...action.payload } };
    case ActionExamType.UPDATE_EXAM:
      return { ...state, exam: { ...action.payload } };
    case ActionExamType.SAVEING_EXAM:
      return { ...state, loading: true };
    case ActionExamType.SAVE_EXAM_COMPLETE:
      return { ...state, loading: false };
    case ActionExamType.CLEAN_EXAM:
      return { ...state, exam: undefined };
    case ActionExamType.UPDATE_COBE_EXAM:
      return { ...state, exam: { ...action.payload } };
    default:
      return state;
  }
};

export default ExamReducer;

import React, { useEffect } from 'react';
import { FaRegComment } from 'react-icons/fa';
import { EMPTY_TOPIC } from '../../../../../../../assets/images';
import { AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';
import { BASE_URL } from '../../../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../../../utils/Generic';
import { useActions } from '../../../../../../../hooks';
import { useTranslation } from 'react-i18next';

const DeleteComment_URL = `${BASE_URL}/DeleteStudentExamComment`;

interface CommentSidebarProps {
	examId: string | undefined;
	studentId: string;
	questionId: string;
	teacherId: string | undefined;
	comments: Array<{ comment: string; index: number }>; // Define comments as an array of objects with a 'comment' property
	setComments: (newComment: Array<{ comment: string; index: number }>) => void;
	index: number;
	deleteComment: (index: number) => void;
}

const CommentSidebar = ({
	examId,
	studentId,
	questionId,
	teacherId,
	comments,
	setComments,
	index,
	deleteComment,
}: CommentSidebarProps) => {
	const answerComment = comments.find(comment => comment.index === index);

	// console.log(answerComment);

	const { t } = useTranslation();
	const { MsgActions } = useActions();

	const handleDelete = async () => {
		const headers = await getAuthHeaders();
		axios
			.put(
				DeleteComment_URL,
				{
					examId,
					studentId,
					questionId,
					teacherId,
				},
				{ headers: headers.headers }
			)
			.then(res => {
				MsgActions.showMsg('Comment deleted successfully', 'success');
				deleteComment(index);
			})
			.catch(err => {
				console.log(err);
				MsgActions.showMsg('Error deleting comment', 'danger');
			});
	};
	return (
		<div className="flex gap-4 h-full">
			<div className="border-l-[10px] border-[#B6DAEB]   rounded-l-lg"></div>
			<div className="flex flex-col gap-4">
				<div className="flex gap-2">
					<div className=" bg-[#DEF1FA] h-fit p-2 rounded-full">
						<FaRegComment color="#00699B" />
					</div>
					<h5 className="text-[#18608C]">{t('Comments')} </h5>
				</div>
				<div>
					{answerComment?.comment !== '' &&
					answerComment?.comment !== undefined &&
					answerComment?.index !== -1 &&
					answerComment?.index !== undefined ? (
						<div>
							<p>Comment 1</p>
							<div className="border-b-2 w-full"></div>
							<p className="my-2">{answerComment?.comment}</p>
							<div className="cursor-pointer" onClick={handleDelete}>
								<AiOutlineDelete color="#F28705" />
							</div>
						</div>
					) : (
						<div className="flex flex-col justify-center mx-10">
							<img
								src={EMPTY_TOPIC}
								alt=""
								className="w-[10rem] h-[10rem] mx-4"
							/>
							<p className="text-center">{t('no comments found')} </p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CommentSidebar;

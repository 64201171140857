import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { AuthActions, MsgActions, ExamActions } from "../actions";

const useActions = () => {
  const dispatch = useDispatch();
  return {
    AuthActions: bindActionCreators(AuthActions, dispatch),
    MsgActions: bindActionCreators(MsgActions, dispatch),
    ExamActions: bindActionCreators(ExamActions, dispatch),
  };
};

export default useActions;

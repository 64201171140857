import React from "react";
import "./EmptyGroup.css";
import { EMPTY_CLASSES } from "../../../../../../../assets/images";
import { Card, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icons } from "../../../../../../../assets/styles";

interface EmptyGroupProps {
  onPress: VoidFunction;
}

function EmptyGroup({ onPress }: EmptyGroupProps) {
  const { t } = useTranslation();
  return (
    <div className="emptyGroup">
      <div className="imageWrapper">
        <Card className="createWrapper">
          <div className="addBtnWrapper" onClick={() => onPress()}>
            <Icons.PlusIcon />
          </div>
          <p>{t("Create Group")}</p>
        </Card>
        <img src={EMPTY_CLASSES} alt="empty groups" />
        <p>{t("You have not created any group")}</p>
      </div>
    </div>
  );
}

export default EmptyGroup;

import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Stack,
  Card,
  Modal,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";

import "./loginWrapper.css";
import {
  LOGIN_IMAGE1,
  LOGIN_VECTOR2,
  LOGIN_VECTOR3,
} from "../../../../assets/images";
import HelpVideo from "../../../../components/HelpVideo/HelpVideo";
import { isRTL } from "../../../../locales";
import useTypedSelector from "../../../../hooks/useTypedSelector";
import useActions from "../../../../hooks/useActions";

function LoginWrapper() {
  const { t } = useTranslation();

  const rtlBox = isRTL() ? "rtlBox" : "";
  const [loginModalVisbile, setLoginModalVisible] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { error, loading } = useTypedSelector((state) => state.AuthReducer);
  const { AuthActions } = useActions();
  const handleLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (loading) return;
    e.preventDefault();
    AuthActions.loginUser(username, password);
  };

  const onDissmis = () => {
    setLoginModalVisible(false);
    setUsername("");
    setPassword("");
    AuthActions.setErrorMsg("");
  };

  return (
    <div className={`loginWrapper ${rtlBox}`}>
      {/* login modal */}
      <Modal size="lg" centered show={loginModalVisbile} onHide={onDissmis}>
        <Modal.Header closeButton>
          <h2 className="title">{t("t18")}</h2>
        </Modal.Header>
        <Modal.Body className="loingModelBody">
          <Form dir={isRTL() ? "rtl" : "ltr"}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{t("username")}</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{t("password")}</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Stack gap={2} direction="horizontal">
              <Button
                variant="primary"
                type="submit"
                onClick={handleLogin}
                disabled={loading}
              >
                {t("t0")}
              </Button>

              {loading && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="mt-2"
                />
              )}
            </Stack>

            {error && (
              <Alert className="mt-2" variant={"danger"}>
                {t(error)}
              </Alert>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      {/* land page main title */}
      <div style={{ textAlign: isRTL() ? "right" : "left" }}>
        <h1 className="title">
          {t("t1")}
          <span> My Lexis Plus </span>
        </h1>

        {/* siginin,video buttons wrapper */}
        <Stack
          direction="horizontal"
          gap={3}
          className="mt-5"
          style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
        >
          <Button onClick={() => setLoginModalVisible(true)}>{t("t0")}</Button>
          <HelpVideo />
        </Stack>
      </div>

      <div className="loginImageWrapper mb-5">
        <img src={LOGIN_IMAGE1} className="loginImage1" alt="loginImage1" />
        <Card className="homeworkCard">
          <Card.Body>
            <Stack
              direction="horizontal"
              gap={2}
              style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
            >
              <img src={LOGIN_VECTOR2} alt="LOGIN_VECTOR2" />
              <Stack
                style={{ alignItems: isRTL() ? "flex-end" : "flex-start" }}
              >
                <h3>{t("t5")}</h3>
                <p>{t("t6")}</p>
              </Stack>
            </Stack>
          </Card.Body>
        </Card>

        <Card className="examCard mt-2">
          <Card.Body>
            <Stack
              direction="horizontal"
              gap={2}
              style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
            >
              <img src={LOGIN_VECTOR3} alt="LOGIN_VECTOR3" />
              <Stack
                style={{ alignItems: isRTL() ? "flex-end" : "flex-start" }}
              >
                <h3>{t("t7")}</h3>
                <p>{t("t8")}</p>
              </Stack>
            </Stack>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default LoginWrapper;

import React, { useEffect } from "react";
import { MCQuestion, QuestionResult } from "../../../../../types";

interface MCQuestionViewProps {
  question: MCQuestion;
  setQuestionResult: (result: QuestionResult) => void;
  isRTL?: boolean;
  initValue?: string;
}

const QuestionCard = ({
  question,
  isRTL,
  setQuestionResult,
  initValue = "",
}: MCQuestionViewProps) => {
  const [selectedValue, setSelectedValue] = React.useState(initValue);

  useEffect(() => {
    const idx = question.options.indexOf(initValue);
    if (idx != -1) setSelectedValue(question.options[idx]);
    else setSelectedValue("");
  }, [question]);

  return (
    <div
      dir={isRTL ? "rtl" : "ltr"}
      className=" w-[20rem] md:w-full mx-4 md:mx-0"
    >
      <div>
        <div className="flex  justify-center items-center h-[50vh] my-[8rem] md:my-0">
          <div className="flex flex-col shadow-md px-10 py-2">
            <h5 className="font-bold">{question.title}</h5>
            <p className="mt-1 text-[1.3rem]">{question.question}</p>
            {question.options.map((option, index) => (
              <div
                key={index.toString()}
                className={`shadow-md my-2 py-2 flex gap-3 text-[1.3rem] cursor-pointer ${
                  selectedValue === option ? "bg-[#3A8688]" : "bg-[#F3F3F3]"
                }`}
                onClick={() => {
                  setSelectedValue(option);
                  const status = option == question.answer;
                  const playerAnswer = option;
                  setQuestionResult({ playerAnswer, isCorrect: status });
                }}
              >
                <input
                  type="radio"
                  name={question.question}
                  value={option}
                  checked={selectedValue === option}
                  className="w-5 ml-2"
                />
                <label>{option}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;

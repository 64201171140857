import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../../utils/Generic';
import { useFetch } from '../../../../../../hooks';

const DeleteAnswer_URL = `${BASE_URL}/DeleteStudentExamAnswer`;

interface ViewGroupProps {
	setShowModal: (newType: boolean) => void;
	examId: string | undefined;
	studentId: string;
	teacherId: string | undefined;
}

const ResetExamModal = ({
	setShowModal,
	examId,
	studentId,
	teacherId,
}: ViewGroupProps) => {
	const [studentData, setStudentData] = useState<any>();

	const [data, loading, error] = useFetch(
		`${BASE_URL}/GetUserInfo`,
		{ username: studentId, accountType: 'Student' },
		getAuthHeaders,
		'POST'
	);

	useEffect(() => {
		if (!loading && data && data.userInfo) {
			setStudentData(data.userInfo);
		}
	}, [data, loading, error]);
	const handleDeleteAnswer = async () => {
		if (studentData) {
			const headers = await getAuthHeaders();
			axios
				.put(
					DeleteAnswer_URL,
					{
						examId,
						studentId: studentData?.key,
						teacherId,
					},
					{ headers: headers.headers }
				)
				.then(res => {
					setShowModal(false);

					// reload window
					window.location.reload();
				})
				.catch(err => {
					console.log(err);
				});
		}
	};
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowModal(false)}
				></Modal.Header>
				<Modal.Body className="max-w-[32rem]  overflow-y-scroll">
					<div className="flex gap-2">
						<h6 className="font-normal">
							Are You Sure you want to reset student answer
						</h6>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={() => setShowModal(false)}>
						No
					</Button>
					<Button
						variant="danger"
						onClick={() => {
							handleDeleteAnswer();
							setShowModal(false);
						}}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ResetExamModal;

import React, { useEffect } from "react";
import "./CustomExam.css";
import StepOne from "./components/StepOne/StepOne";
import StepTwo from "./components/StepTwo/StepTwo";
import StepThree from "./components/StepThree/StepThree";
import { useActions, useTypedSelector } from "../../../../../hooks";
import { CobeExam, Question } from "../../../../../types";
import { useNavigate, useParams } from "react-router-dom";
import { MIN_EXAM_QUESTIONS_NUM } from "../../../../../utils/Constans";

// cobe exam
function CustomExam() {
  const { ExamActions, MsgActions } = useActions();
  const navigate = useNavigate();
  const [stepOneValidation, setStepOneValidation] = React.useState(false);
  const [examQuestions, setExamQuestions] = React.useState<Question[]>([]);
  const { exam } = useTypedSelector((state) => state.ExamReducer);
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { msgType } = useTypedSelector((state) => state.MsgReducer);

  const { singleExam } = useTypedSelector((state) => state.ExamReducer);

  const { key } = useParams();
  // if key in params then fetch exam data
  useEffect(() => {
    if (key && user) {
      ExamActions.fetchSingleExams(user.key, key);
    }
  }, [key]);

  React.useEffect(() => {
    if (msgType == "success") {
      ExamActions.cleanExam();
      navigate("/exams/list");
    }
  }, [msgType]);

  const handleSetSelectedQuestions = (questions: Question[]) => {
    const _exam: CobeExam = { ...exam } as CobeExam;
    _exam.questions = [...questions];
    ExamActions.updateExam(_exam);
    setExamQuestions(questions);
  };

  React.useEffect(() => {
    return () => {
      ExamActions.cleanExam();
    };
  }, []);

  const createExam = () => {
    let examQuestionsNumber = 0;
    if (exam !== undefined) {
      examQuestionsNumber = (exam as CobeExam).questions?.length || 0;
    }

    if (
      !stepOneValidation ||
      !exam ||
      examQuestionsNumber < MIN_EXAM_QUESTIONS_NUM
    ) {
      MsgActions.showMsg("FailedToCreateExam", "danger");
      return;
    }

    ExamActions.saveExam(exam, user?.uid!);
  };

  const updateExam = () => {
    if (!stepOneValidation || !exam) {
      MsgActions.showMsg("FailedToCreateExam", "danger");
      return;
    }

    ExamActions.editExam(user?.key, singleExam?.key, exam);
  };

  return (
    <div className="CustomExam">
      <StepOne
        isValid={setStepOneValidation}
        singleExam={key !== undefined ? singleExam : undefined}
        handleSelectExamReadyToUseQuestions={handleSetSelectedQuestions}
      />

      <div className="StepTwoThreeWrapper">
        <StepTwo
          singleExam={key !== undefined ? singleExam : undefined}
          examQuestions={examQuestions}
          onQuestionsChanged={handleSetSelectedQuestions}
        />
        <StepThree
          examQuestions={examQuestions}
          onCreatePress={createExam}
          onUpdatePress={updateExam}
          onQuestionsChanged={handleSetSelectedQuestions}
          singleExam={key !== undefined ? singleExam : undefined}
        />
      </div>
    </div>
  );
}

export default CustomExam;

import React from "react";
import "./helpVideo.css";
import { Button, Modal, Stack } from "react-bootstrap";
import { VIDEOS } from "../../utils/Constans";
import { LOGIN_PLAY_VIDEO } from "../../assets/images";
import { Icons } from "../../assets/styles";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../locales";

interface HelpVideoProps {
  titleVisible?: boolean;
}

function HelpVideo({ titleVisible = true }: HelpVideoProps) {
  const { t } = useTranslation();
  const [videoModalVisible, setVideoModalVisible] = React.useState(false);
  const [videoIndex, setVideoIndex] = React.useState(0);
  const handleShowVideosModal = () => {
    setVideoModalVisible(true);
  };
  const handleNextClick = () => {
    setVideoIndex(videoIndex + 1);
  };

  const handlePrevClick = () => {
    setVideoIndex(videoIndex - 1);
  };

  React.useEffect(() => {
    setVideoIndex(0);
  }, [videoModalVisible]);

  return (
    <div className="HelpVideo">
      <Modal
        size="lg"
        centered
        show={videoModalVisible}
        onHide={() => setVideoModalVisible(false)}
      >
        <Modal.Header closeButton>
          <h2>{VIDEOS[videoIndex].title}</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="videosBodyWrapper">
            <div className="videoWrapper">
              <iframe
                width={"100%"}
                height={"300px"}
                src={VIDEOS[videoIndex].url}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={VIDEOS[videoIndex].title}
              />
            </div>
            <div className="buttonsWrapper">
              <Button
                className="nxtBtn"
                disabled={videoIndex === VIDEOS.length - 1}
                style={{
                  backgroundColor:
                    videoIndex === VIDEOS.length - 1
                      ? "var(--lightText)"
                      : "blueviolet",
                }}
                onClick={handleNextClick}
              >
                <Icons.RightArrow color="white" />
              </Button>

              <div className="videoDotsWrapper">
                {VIDEOS.map((_, index) => (
                  <div
                    key={index.toString()}
                    className="videosDots"
                    style={{
                      backgroundColor:
                        videoIndex === index
                          ? "blueviolet"
                          : "var(--lightText)",
                    }}
                  />
                ))}
              </div>

              <Button
                className="prevBtn"
                disabled={videoIndex === 0}
                style={{
                  backgroundColor:
                    videoIndex === 0 ? "var(--lightText)" : "blueviolet",
                }}
                onClick={handlePrevClick}
              >
                <Icons.LeftArrow color="white" />
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Stack
        direction="horizontal"
        onClick={handleShowVideosModal}
        className="videoPlayerBtnWrapper"
        gap={2}
        style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
      >
        <img src={LOGIN_PLAY_VIDEO} alt="LOGIN_PLAY_VIDEO" />
        {titleVisible && <h4>{t("t2")}</h4>}
      </Stack>
    </div>
  );
}

export default HelpVideo;

import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { HomeWork, CheckedGroups } from '../../../../../../types';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../../../../../utils/Constans';
import {
	useFetch,
	useActions,
	useTypedSelector,
} from '../../../../../../hooks';
import { Spinner } from 'react-bootstrap';
import { getAuthHeaders } from '../../../../../../utils/Generic';

const FetchGroup_URL = `${BASE_URL}/FetchGroups`;
const SEND_HOMEWORK_URL = `${BASE_URL}/SendHomework`;

interface SendHomeWorkProps {
	setShowSendModel: (newType: boolean) => void;
	homework: HomeWork;
}

const SendHomeWork = ({ setShowSendModel, homework }: SendHomeWorkProps) => {
	const navigate = useNavigate();
	const { MsgActions } = useActions();
	const [groups, setGroups] = useState<CheckedGroups[]>([]);
	const [selectedGroups, setSelectedGroups] = useState<CheckedGroups[]>([]);

	const { user } = useTypedSelector(state => state.AuthReducer);

	const [data, loading, error] = useFetch(
		FetchGroup_URL,
		{ teacherId: user?.uid },
		getAuthHeaders,
		'GET'
	);

	useEffect(() => {
		if (!loading && data && data.groups) {
			setGroups(data.groups);
		}
	}, [data, loading, error]);

	const handleSendHomeWork = async () => {
		if (selectedGroups == null || selectedGroups.length == 0) {
			MsgActions.showMsg('Please select at least 1 group', 'danger');
			return;
		}
		const headers = await getAuthHeaders();
		axios.post(
			SEND_HOMEWORK_URL,
			{ homeWork: homework, groups: selectedGroups, teacherId: user?.uid },
			{
				headers: headers.headers,
			}
		);

		// const params = {
		// 	studentId: '05FdqeXL4WYCJhdWkaZb',
		// 	language: 'English',
		// };
		// const data = await axios.get(
		// 	'http://127.0.0.1:5001/mylexisplus-2f66b/us-central1/app/api/FetchAllHomeWorkByStudentId',
		// 	{ params: { params: JSON.stringify(params) }, headers: headers.headers }
		// );

		// console.log(data);
	};

	// Handle navigate to create group
	const handleCreateGroup = () => {
		navigate('/groups/createGroup');
	};
	const checkedValuesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;

		// add checked group to selected groups
		if (checked) {
			const checkedGroups = groups.map(data =>
				data.key == name ? { ...data, checked: checked } : data
			);
			const filteredGroup = checkedGroups.filter(group => group.checked);
			setSelectedGroups([...selectedGroups, ...filteredGroup]);
		} else {
			// remove unchecked group from selected groups
			const filteredGroup = selectedGroups.filter(group => group.key != name);
			setSelectedGroups(filteredGroup);
		}
	};

	return (
		<div>
			<Modal show={true}>
				<Modal.Header
					closeButton
					onClick={() => setShowSendModel(false)}
				></Modal.Header>
				<Modal.Body>
					<div className="flex gap-4">
						<button
							className="bg-[#9BDE7E] text-white px-4 py-2 rounded font-bold"
							onClick={() => {
								handleSendHomeWork();
								setShowSendModel(false);
							}}
						>
							Send Homework
						</button>
						<button
							className="bg-[#3188C9] text-white px-4 py-2 rounded font-bold"
							onClick={handleCreateGroup}
						>
							Create Group
						</button>
					</div>

					<h4 className="mt-4">Select students group</h4>
					{loading && (
						<div className="flex justify-center items-center ">
							<Spinner animation="border" variant="primary" className="mt-2 " />
						</div>
					)}
					{!loading && groups && (
						<ol className="ml-0">
							{groups.map(group => (
								<li key={group.title}>
									<div className="flex mt-2">
										<input
											type="checkbox"
											aria-label={group.title}
											onChange={checkedValuesHandler}
											name={group.key}
											checked={group.checked}
										/>
										<span className="ml-5">{group.title}</span>
									</div>
								</li>
							))}
						</ol>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default SendHomeWork;

import React from 'react';
import './header.css';
import { LOGO } from '../../assets/images';
import { Icons } from '../../assets/styles';
import useTypedSelector from '../../hooks/useTypedSelector';

interface HeaderProps {
	children?: React.ReactNode;
	onPress?: VoidFunction;
}
function Header({ children, onPress }: HeaderProps) {
	const { user } = useTypedSelector(state => state.AuthReducer);
	return (
		<header className="Header">
			<nav>
				<div className="leftSideWrapper">
					{user && (
						<div className="menuWrapper" onClick={onPress}>
							<Icons.MenuIcon />
						</div>
					)}
					<img src={LOGO} alt="logo" />
				</div>
				{user && <div className="rightSideWrapper">{children}</div>}
			</nav>
		</header>
	);
}

export default Header;

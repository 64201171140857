import React from "react";
import "./MCQuestionSortable.css";
import { useTranslation } from "react-i18next";
import { Card, Stack, Button } from "react-bootstrap";
import { MCQuestion } from "../../../../../../../../../types";
import { Icons } from "../../../../../../../../../assets/styles";
import { isRTL } from "../../../../../../../../../locales";

interface MCQuestionSortableProps {
  onRemovePress: VoidFunction;
  drag: boolean;
  question: MCQuestion;
}

const MCQuestionSortable = ({
  question,
  onRemovePress,
  drag,
}: MCQuestionSortableProps) => {
  const { t } = useTranslation();
  return (
    <Card className="MCQuestionSortable">
      <Card.Body>
        <Stack
          dir={isRTL() ? "rtl" : "ltr"}
          direction="horizontal"
          gap={2}
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <div className="text-secondary">{question.title}</div>

            <div>
              {question.question.split("@").map((word, i) => (
                <span
                  key={i.toString()}
                  style={{
                    color: i % 2 == 0 ? "var(--text)" : "var(--orange)",
                    fontWeight: i % 2 == 0 ? "normal" : "bold",
                  }}
                >
                  {word}
                </span>
              ))}

              <div className="mcqAnswer">
                <span>{question.answer}</span>
              </div>
            </div>
          </div>

          <div>
            {drag ? (
              <Icons.DargIcon />
            ) : (
              <Button variant="danger" onClick={onRemovePress}>
                {t("Delete")}
              </Button>
            )}
          </div>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default MCQuestionSortable;

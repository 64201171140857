import { Modal, Button } from 'react-bootstrap';
import { Exam } from '../../../../../../types';
import { useNavigate } from 'react-router-dom';
import { getAuthHeaders } from '../../../../../../utils/Generic';
import { useActions, useTypedSelector } from '../../../../../../hooks';
import { BASE_URL } from '../../../../../../utils/Constans';
import axios from 'axios';

const SendAnswers_URL = `${BASE_URL}/UpdateStudentExamAnswers`;

interface SubmitModalProps {
	setShowModel: (newType: boolean) => void;
	handleSendAnswers: any;
}

const SubmitModal = ({ setShowModel, handleSendAnswers }: SubmitModalProps) => {
	const handleYesClick = () => {
		handleSendAnswers();
	};

	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowModel(false)}
				></Modal.Header>
				<Modal.Body>
					<h4>Send answers</h4>
					Are you sure you want to send answers ?
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setShowModel(false);
						}}
					>
						No
					</Button>
					<Button
						variant="success"
						onClick={() => {
							setShowModel(false);
							handleYesClick();
						}}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default SubmitModal;

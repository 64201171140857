import { ActionAuthType } from "../actions/types";
import { User } from "../types";

export interface AuthState {
  user?: User;
  loading?: boolean;
  error?: string;
  sync?: boolean;
}

const defaultProps: AuthState = {
  user: undefined,
  loading: false,
  error: "",
  sync: false,
};

interface ActionFetchUser {
  type: ActionAuthType.FETCH_USER;
}

interface ActionLogin {
  type: ActionAuthType.LOGIN_SUCCESS;
  payload: User | undefined;
}

interface ActionLoginFail {
  type: ActionAuthType.LOGIN_FAIL;
  payload?: string;
}

interface ActionLogout {
  type: ActionAuthType.LOGOUT;
}

interface ActionSyncUser {
  type: ActionAuthType.SYNC_USER;
}

interface ActionErrorMsg {
  type: ActionAuthType.SET_ERROR_MSG;
  payload: string;
}

export type ActionAuth =
  | ActionLogin
  | ActionLoginFail
  | ActionLogout
  | ActionFetchUser
  | ActionSyncUser
  | ActionErrorMsg;

const AuthReducer = (
  state: AuthState = defaultProps,
  action: ActionAuth
): AuthState => {
  switch (action.type) {
    case ActionAuthType.LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ActionAuthType.LOGIN_SUCCESS:
      return {
        ...state,
        sync: false,
        loading: false,
        error: "",
        user: action.payload,
      };
    case ActionAuthType.LOGOUT:
      return { ...defaultProps };
    case ActionAuthType.SYNC_USER:
      return { ...state, sync: true };
    case ActionAuthType.SET_ERROR_MSG:
      return { ...state, error: action.payload };
    case ActionAuthType.FETCH_USER:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default AuthReducer;

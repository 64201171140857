import React, { useEffect, useState } from 'react';
import { isRTL } from '../../../../../../locales';
import { Table, Form, Pagination } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HomeWork } from '../../../../../../types';
import { BASE_URL } from '../../../../../../utils/Constans';
import ListHomeworkTime from './ListHomeworkTime';
import { EyeIcon, DeleteIcon, SendIcon } from '../../../../../../assets/icons';
import ShowHomeWork from './ShowHomework';
import DeleteHomeWork from './DeleteHomework';
import SendHomework from './SendHomework';

interface HomeWorkTableProps {
	homeworks: HomeWork[];
}

const HomeWorkTable = ({ homeworks }: HomeWorkTableProps) => {
	const { t } = useTranslation();

	const [pages, setPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(-1);
	const [search, setSearch] = useState<string>('');
	const [allHomeWorks, setAllHomeWorks] = useState<HomeWork[]>([]);
	const [selectedHomeWork, setSelectedHomeWork] = useState<HomeWork>(null!);
	const [showHomeWorkModel, setShowHomeWorkModel] = useState(false);
	const [showDeleteModel, setShowDeleteModel] = useState(false);
	const [showSendModel, setShowSendModel] = useState(false);
	const [startIndex, setStartIndex] = useState(0);

	const rowsPerPage = 4;

	useEffect(() => {
		setPages(Math.ceil(homeworks.length / rowsPerPage));
		if (homeworks.length > 0) {
			setCurrentPage(0);
		}
	}, [homeworks]);

	const onPageChanged = (page: number) => {
		const newStartIndex = page * rowsPerPage;
		setStartIndex(newStartIndex);
		setAllHomeWorks(
			homeworks.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
		);
	};

	const handlePrevPage = () => {
		if (currentPage - 1 >= 0) {
			setCurrentPage(currentPage - 1);
			onPageChanged(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage + 1 < pages) {
			setCurrentPage(currentPage + 1);
			onPageChanged(currentPage + 1);
		}
	};
	useEffect(() => {
		if (search === '') setAllHomeWorks(homeworks.slice(0, rowsPerPage));
	}, [homeworks, search]);

	// Checked Words

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const txt = e.target.value;

		if (txt.trim().length > 0) {
			setSearch(txt);
			// * find student name with search text
			const filteredStudents = homeworks.filter(homework => {
				return homework.title.toLowerCase().includes(txt.toLowerCase());
			});

			setAllHomeWorks(filteredStudents);
			return;
		}

		setSearch('');
	};

	// * Handling Operations
	const handleShowHomeWork = (homework: HomeWork) => {
		setShowHomeWorkModel(true);
		setSelectedHomeWork(homework);
	};

	const handleDeleteHomeWork = async (homework: HomeWork) => {
		setShowDeleteModel(true);
		setSelectedHomeWork(homework);
	};

	const handleSendHomeWork = async (homework: HomeWork) => {
		setShowSendModel(true);
		setSelectedHomeWork(homework);
	};

	return (
		<div>
			{showHomeWorkModel && (
				<ShowHomeWork
					setViewShowModel={setShowHomeWorkModel}
					homeworks={selectedHomeWork}
				/>
			)}

			{showDeleteModel && (
				<DeleteHomeWork
					setShowModel={setShowDeleteModel}
					homeworkKey={selectedHomeWork.key}
				/>
			)}

			{showSendModel && (
				<SendHomework
					setShowSendModel={setShowSendModel}
					homework={selectedHomeWork}
				/>
			)}

			<div className=" mx-4 my-4">
				<div>
					<Form.Control
						className="search"
						style={{ maxWidth: 300 }}
						value={search}
						type="text"
						placeholder={`${t('Search')}...`}
						onChange={handleSearch}
					/>
				</div>
			</div>
			<Table
				striped
				hover
				bordered
				dir={isRTL() ? 'rtl' : 'ltr'}
				className="overflow-hidden"
			>
				<thead>
					<tr>
						<th>
							<div className="flex justify-center text-[#18608C] text-[0.8rem]">
								#
							</div>
						</th>

						<th>
							<div className="flex justify-center text-[#18608C] text-[0.8rem]">
								{t('Title')}
							</div>
						</th>

						<th>
							<div className="flex justify-center text-[#18608C] text-[0.8rem]">
								{t('Created')}
							</div>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{allHomeWorks.map((homework, index) => (
						<tr key={index.toString()}>
							<td>
								<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
									{startIndex + index + 1}
								</div>
							</td>

							<td>
								<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
									{homework.title}
								</div>
							</td>
							<td className="w-[20rem]">
								<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
									<ListHomeworkTime homework={homework} />
								</div>
							</td>
							<td className="w-[20rem]">
								<div className="flex justify-center items-center h-[8vh] ">
									<div className="bg-[#E1F5D8] flex justify-center  gap-2 px-[1rem] py-[1rem]">
										<div
											className="cursor-pointer"
											onClick={() => {
												handleShowHomeWork(homework);
											}}
										>
											<EyeIcon size={16} color="#5DAF3A" />
										</div>
										<div className="border-r border-[#BFBCBC]"></div>
										<div
											className="cursor-pointer"
											onClick={() => {
												handleDeleteHomeWork(homework);
											}}
										>
											<DeleteIcon size={16} color="#5DAF3A" />
										</div>
										<div className="border-r border-[#BFBCBC]"></div>
										<div
											className="cursor-pointer"
											onClick={() => {
												handleSendHomeWork(homework);
											}}
										>
											<SendIcon size={16} color="#5DAF3A" />
										</div>
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{/* pages */}
			<Pagination className="flex justify-end">
				<Pagination.Prev onClick={handlePrevPage} />

				<Pagination.Item>
					<span>
						{currentPage + 1} / {pages}
					</span>
				</Pagination.Item>

				<Pagination.Next onClick={handleNextPage} />
			</Pagination>
		</div>
	);
};

export default HomeWorkTable;

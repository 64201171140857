import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { HomeWork } from '../../../../../../types';

interface ShowHomeWorkProps {
	setViewShowModel: (newType: boolean) => void;
	homeworks: HomeWork;
}

const ShowHomeWork = ({ setViewShowModel, homeworks }: ShowHomeWorkProps) => {
	return (
		<div>
			<Modal show={true}>
				<Modal.Header
					closeButton
					onClick={() => setViewShowModel(false)}
				></Modal.Header>
				<Modal.Body className="max-w-[32rem] h-[30rem] overflow-y-scroll">
					<div className="flex gap-10 mx-4 max-w-[32rem]  flex-wrap">
						{homeworks.words.map(word => (
							<div className="flex flex-col">
								Word: {word.word}
								{word.mean !== '' ? (
									<div className="flex flex-col">Meaning: {word.mean}</div>
								) : (
									<div className="flex flex-col">Meaning: {word.sentences}</div>
								)}
								<div className="border-t border-[#BFBCBC] my-2"></div>
							</div>
						))}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ShowHomeWork;

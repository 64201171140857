import { Modal, Button } from 'react-bootstrap';
import { Exam } from '../../../../../../types';

interface EditTitleProps {
	setShowModel: (newType: boolean) => void;
	setNewTitle: (newType: string) => void;
	handleCopyExam: (newType: Exam) => void;
	newTitle: string;
	newExam: Exam;
}

const EditTitle = ({
	setShowModel,
	setNewTitle,
	newTitle,
	newExam,
	handleCopyExam,
}: EditTitleProps) => {
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowModel(false)}
				></Modal.Header>
				<Modal.Body>
					<div className="flex gap-4">
						<label htmlFor="">Exam Title</label>
						<input
							type="text"
							name=""
							id=""
							value={newTitle}
							onChange={e => setNewTitle(e.target.value)}
							className="border border-[#ADADAD]"
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="success"
						onClick={() => {
							handleCopyExam(newExam);
							setShowModel(false);
						}}
					>
						Duplicate
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default EditTitle;

import React from "react";
import "./msgModal.css";
import { Modal, Alert } from "react-bootstrap";
import { MsgType } from "../../reducers/MsgReducer";
import { isRTL } from "../../locales";

interface MsgModalProps {
  msgType: MsgType;
  msg: string;
  visible: boolean;
  title?: string;
  onDismiss: VoidFunction;
}

function MsgModal({
  msg,
  msgType,
  visible,
  onDismiss,
  title = "",
}: MsgModalProps) {
  return (
    <Modal show={visible} onHide={onDismiss}>
      <Modal.Header closeButton>
        <h2>{title}</h2>
      </Modal.Header>
      <Modal.Body dir={isRTL() ? "rtl" : "ltr"}>
        <Alert className="mt-2" variant={msgType}>
          {msg}
        </Alert>
      </Modal.Body>
    </Modal>
  );
}

export default MsgModal;

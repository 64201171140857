import React, { useEffect } from 'react';
import './StepOne.css';

import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
	Languages,
	SCHOOL_LEVELS,
	TOPICS_OPTIONS,
} from '../../../../../../../utils/Constans';
import { Switch } from '../../../../../../../components';
import { useActions, useTypedSelector } from '../../../../../../../hooks';
import {
	ExamTypesEnum,
	LanguageType,
	LevelType,
	WrittenProductionExam,
} from '../../../../../../../types';
import { isRTL } from '../../../../../../../locales';

interface StepOneProps {
	onPress: VoidFunction;
	writtenExam?: WrittenProductionExam;
}

function StepOne({ onPress, writtenExam }: StepOneProps) {
	const { ExamActions } = useActions();
	const { exam } = useTypedSelector(state => state.ExamReducer);
	const { t } = useTranslation();

	const form = React.useRef<HTMLFormElement>(null);
	const [examLanugage, setExamLanguage] = React.useState<LanguageType>(
		exam?.language || 'English'
	);
	const [examLevel, setExamLevel] = React.useState<LevelType>(
		exam?.level || 'Level1'
	);
	const [examTitle, setExamTitle] = React.useState(exam?.title || '');
	const [examDeadLine, setExamDeadLine] = React.useState<string | undefined>(
		exam?.submitionDate || ''
	);
	const [examModule, setExamModule] = React.useState('');

	const handleExamLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const level = e.target.value as LevelType;
		setExamLevel(level);
	};

	useEffect(() => {
		setExamTitle(writtenExam ? writtenExam.title : exam?.title || '');
		setExamLevel(writtenExam ? writtenExam.level : examLevel);
		setExamDeadLine(
			writtenExam ? writtenExam.submitionDate : exam?.submitionDate || ''
		);
		setExamLanguage(writtenExam ? writtenExam.language : examLanugage);
		setExamModule(writtenExam ? writtenExam.topic.module : examModule);
	}, [writtenExam]);

	const handleExamLanugageChange = (value: string) => {
		const language = value as LanguageType;
		setExamLanguage(language);
		setExamModule('');
	};

	const handleModuleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setExamModule(e.target.value);
	};

	const handleNextPress = (e: React.MouseEvent) => {
		e.preventDefault();
		if (form.current?.checkValidity() === true) {
			const exam: WrittenProductionExam = {
				title: examTitle,
				language: examLanugage,
				level: examLevel,
				examType: ExamTypesEnum.WrittenProduction,
				createTime: new Date().getTime(),
				submitionDate: examDeadLine,
				topic: {
					module: examModule,
					level: examLevel,
					language: examLanugage,
					title: '',
					topic: '',
				},
			};
			ExamActions.updateExam(exam);
			onPress();
			return;
		}
	};

	return (
		<div className="StepOneWritten">
			<Form ref={form} noValidate validated>
				<Card dir={isRTL() ? 'rtl' : 'ltr'}>
					<Card.Body>
						<Row className="inputRow">
							<Form.Group as={Col} className="mb-3">
								<Form.Label>{t('Exam title')}</Form.Label>
								<Form.Control
									value={examTitle}
									required
									type="text"
									placeholder={`${t('Enter exam title')}`}
									onChange={e => setExamTitle(e.target.value)}
								/>
							</Form.Group>

							<Form.Group as={Col} className="mb-3">
								<Form.Label>{t('Exam Is Due')}</Form.Label>
								<Form.Control
									type="datetime-local"
									value={examDeadLine}
									onChange={e => setExamDeadLine(e.target.value)}
								/>
							</Form.Group>
						</Row>

						<Row className="inputRow">
							<Form.Group as={Col} className="mb-3">
								<Form.Label>{t('Exam Level')}</Form.Label>
								<Form.Select
									required
									value={examLevel}
									onChange={handleExamLevelChange}
								>
									{SCHOOL_LEVELS.map(level => (
										<option key={level.value} value={level.value}>
											{t(level.label)}
										</option>
									))}
								</Form.Select>
							</Form.Group>

							<Form.Group as={Col} className="mb-3">
								<Form.Label>{t('t21')}</Form.Label>
								<Switch
									initValue={exam?.language || 'English'}
									values={Languages}
									onSelect={handleExamLanugageChange}
								/>
							</Form.Group>
						</Row>

						<Row className="inputRow">
							<Form.Group as={Col} className="mb-3">
								<Form.Label>{t('Exam Module')}</Form.Label>
								<Form.Select
									required
									value={examModule}
									onChange={handleModuleSelect}
								>
									<option value={''}>{t('Select Module')}</option>
									{TOPICS_OPTIONS[examLanugage].map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</Form.Select>
							</Form.Group>
							<Form.Group as={Col} className="mb-3" />
						</Row>
					</Card.Body>
					<Card.Footer className="step1Footer">
						<Button className="nextBtn" onClick={handleNextPress}>
							{t('next')}
						</Button>
					</Card.Footer>
				</Card>
			</Form>
		</div>
	);
}

export default StepOne;

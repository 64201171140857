import React from "react";
import "./MatchingQuestionSortable.css";
import { useTranslation } from "react-i18next";
import { Button, Card, Stack } from "react-bootstrap";

import { MatchingQuestion } from "../../../../../../../../../types";
import { Icons } from "../../../../../../../../../assets/styles";
import { isRTL } from "../../../../../../../../../locales";

interface MatchingSortableProps {
  onRemovePress: VoidFunction;
  drag: boolean;
  question: MatchingQuestion;
}

const MatchingQuestionSortable = ({
  question,
  onRemovePress,
  drag,
}: MatchingSortableProps) => {
  const { t } = useTranslation();
  return (
    <Card className="MatchingQuestionSortable">
      <Card.Body>
        <Stack
          dir={isRTL() ? "rtl" : "ltr"}
          direction="horizontal"
          gap={2}
          style={{ justifyContent: "space-between" }}
          className="matchingQuestionWrapper"
        >
          <Stack
            direction="horizontal"
            gap={3}
            className="matchingQuestionWrapper"
          >
            <div>
              {question.options.map((option, index) => (
                <p key={index.toString()}>
                  {`${index + 1}) `}
                  {option}
                </p>
              ))}
            </div>
            <div>
              {question.question.map((value, index) => (
                <p key={index.toString()}>___ {value}</p>
              ))}
            </div>
          </Stack>

          <div>
            {drag ? (
              <Icons.DargIcon />
            ) : (
              <Button variant="danger" onClick={onRemovePress}>
                {t("Delete")}
              </Button>
            )}
          </div>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default MatchingQuestionSortable;

import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Exam, CheckedGroups } from "../../../../../../types";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../../../utils/Constans";
import {
  useFetch,
  useActions,
  useTypedSelector,
} from "../../../../../../hooks";
import { Spinner } from "react-bootstrap";
import { getAuthHeaders } from "../../../../../../utils/Generic";
import { useTranslation } from "react-i18next";

const FetchGroup_URL = `${BASE_URL}/FetchGroups`;
const SEND_EXAM_URL = `${BASE_URL}/SendExam`;

interface SendExamProps {
  setShowSendModel: (newType: boolean) => void;
  newExam: Exam;
}

const SendExam = ({ setShowSendModel, newExam }: SendExamProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { MsgActions } = useActions();
  const [groups, setGroups] = useState<CheckedGroups[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<CheckedGroups[]>([]);
  const [sendingExam, setSendingExam] = useState(false);

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const [data, loading, error] = useFetch(
    FetchGroup_URL,
    { teacherId: user?.uid },
    getAuthHeaders,
    "GET"
  );

  useEffect(() => {
    if (!loading && data && data.groups) {
      setGroups(data.groups);
    }
  }, [data, loading, error]);

  const handleSendExam = async () => {
    if (sendingExam) return;
    if (selectedGroups == null || selectedGroups.length == 0) {
      MsgActions.showMsg("Please select at least 1 group", "danger");
      return;
    }
    setSendingExam(true);
    const headers = await getAuthHeaders();
    axios
      .post(
        SEND_EXAM_URL,
        { exam: newExam, groups: selectedGroups, teacherId: user?.uid },
        {
          headers: headers.headers,
        }
      )
      .catch((err) => {
        console.log(err.response.data.error);
        MsgActions.showMsg(err.response.data.error, "danger");
      })
      .finally(() => {
        setSendingExam(false);
        setShowSendModel(false);
      });
  };

  // Handle navigate to create group
  const handleCreateGroup = () => {
    navigate("/groups/createGroup");
  };
  const checkedValuesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    // add checked group to selected groups
    if (checked) {
      const checkedGroups = groups.map((data) =>
        data.key == name ? { ...data, checked: checked } : data
      );
      const filteredGroup = checkedGroups.filter((group) => group.checked);
      setSelectedGroups([...selectedGroups, ...filteredGroup]);
    } else {
      // remove unchecked group from selected groups
      const filteredGroup = selectedGroups.filter((group) => group.key != name);
      setSelectedGroups(filteredGroup);
    }
  };

  return (
    <div>
      <Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
        <Modal.Header
          closeButton
          onClick={() => setShowSendModel(false)}
        ></Modal.Header>
        <Modal.Body>
          <div className="flex gap-4">
            <button
              className="bg-[#9BDE7E] text-white px-4 py-2 rounded font-bold"
              onClick={() => {
                handleSendExam();
              }}
            >
              Send Exam
            </button>
            <button
              className="bg-[#3188C9] text-white px-4 py-2 rounded font-bold"
              onClick={handleCreateGroup}
            >
              Create Group
            </button>
          </div>

          <h4 className="mt-4">{t("Select students group")}</h4>
          {loading ||
            (sendingExam && (
              <div className="flex justify-center items-center ">
                <Spinner
                  animation="border"
                  variant="primary"
                  className="mt-2 "
                />
              </div>
            ))}
          {!loading && groups && (
            <ol className="ml-0">
              {groups.map((group) => (
                <li key={group.title}>
                  <div className="flex mt-2">
                    <input
                      type="checkbox"
                      aria-label={group.title}
                      onChange={checkedValuesHandler}
                      name={group.key}
                      checked={group.checked}
                    />
                    <span className="ml-5">{group.title}</span>
                  </div>
                </li>
              ))}
            </ol>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SendExam;

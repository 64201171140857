import React, { useEffect, useState } from "react";
import {
  useTypedSelector,
  useFetch,
  useActions,
} from "../../../../../../../../hooks";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../../../../utils/Generic";
import axios from "axios";
import { FaRegComment } from "react-icons/fa";
import CommentSidebar from "../../Comments/CommentSidebar";
import CommentModal from "../../Comments/CommentModal";
import CobeAnswerCard from "./CobeAnswerCard";
import { useTranslation } from "react-i18next";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const SingleExam_URL = `${BASE_URL}/FetchSingleExam`;

const CobeResults = () => {
  const { t } = useTranslation();
  const [student, setStudent] = useState<any>();
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [studentExam, setStudentExam] = useState<any>(null);
  const [answersByQuestion, setAnswersByQuestion] = useState<any[]>([]);
  const [studentMarks, setMarks] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentExam, setCurrentExam] = useState<any>(null);

  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { MsgActions } = useActions();
  const { key, uid } = useParams();

  const [comments, setComments] = useState([{ comment: "", index: -1 }]);
  const addCommentToState = (newComment: {
    comment: string;
    index: number;
  }) => {
    setComments((prevComments) => [...prevComments, newComment]);
  };

  const deleteComment = (commentIndex: number) => {
    setComments((prevComments) =>
      prevComments.filter((comment) => comment.index !== commentIndex)
    );
  };
  const [singleExamData, singleExamLoading, singleExamError] = useFetch(
    SingleExam_URL,
    { uid: user?.key, examId: key },
    getAuthHeaders,
    "GET"
  );

  useEffect(() => {
    if (!singleExamLoading && singleExamData && singleExamData.exams) {
      setCurrentExam(singleExamData.exams);
    }
  }, [singleExamData, singleExamLoading, singleExamError]);

  // get user info
  const [data, loading, error] = useFetch(
    `${BASE_URL}/GetUserInfo`,
    { username: uid, accountType: "Student" },
    getAuthHeaders,
    "POST"
  );

  useEffect(() => {}, [comments]);

  useEffect(() => {
    if (!loading && data && data.userInfo) {
      setStudent(data.userInfo);
    }
  }, [data, loading, error]);

  const handleMarksChange = async () => {
    const headers = await getAuthHeaders();
    if (studentMarks) {
      axios
        .put(
          `${BASE_URL}/AddMarksToStudentExam`,
          {
            examId: key,
            studentId: student.key,
            teacherId: user?.key,
            marks: studentMarks,
          },
          { headers: headers.headers }
        )
        .then((res) => {
          MsgActions.showMsg("Marks updated successfully", "success");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (student && studentExam === null) {
      const params = {
        examId: key,
        studentId: student.key,
        teacherId: user?.key,
      };
      const getStudentAnswers = async () => {
        const headers = await getAuthHeaders();
        axios
          .get(`${BASE_URL}/GetStudentExam`, {
            params: { params: JSON.stringify(params) },
            headers: headers.headers,
          })
          .then((res) => {
            setStudentExam(res.data.exam);
            setMarks(res.data.exam.marks);
            const allComments = res.data?.exam?.answers?.map(
              (answer: any, index: number) => ({
                comment: answer.comment,
                index,
              })
            );

            if (allComments.length > 0) {
              setComments(allComments);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      getStudentAnswers();
    }
  }, [student]);

  const fetchAnswersUrl = async (answersQuestion: any[]) => {
    const _answers = [];
    for (let answer of answersQuestion) {
      const _answer = { ...answer };
      if (_answer.answers[0].answerType === "Record") {
        if (_answer.answers[0].answer) {
          const storagePath = ref(getStorage(), _answer.answers[0].answer);
          _answer.answers[0].answer = await getDownloadURL(storagePath);
        } else {
          _answer.answers[0].answer = "";
        }
      }
      _answers.push(_answer);
    }
    setAnswersByQuestion(_answers);
  };

  useEffect(() => {
    if (studentExam !== null) {
      const answersByQuestion =
        studentExam?.answers?.reduce((acc: any, answer: any) => {
          const existingAnswer = acc.find(
            (item: any) => item.questionId === answer.questionId
          );

          if (existingAnswer) {
            existingAnswer.answers.push(answer);
          } else {
            acc.push({
              questionId: answer.questionId,
              answers: [answer],
            });
          }

          return acc;
        }, []) ||
        currentExam?.questions?.map((question: any) => ({
          questionId: question.key,
          answers: [question],
        }));

      fetchAnswersUrl(answersByQuestion);
    }
  }, [studentExam]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <div className=" py-[1rem] flex flex-col bg-[#FFFFFF]">
      {showModal && (
        <CommentModal
          setShowModal={setShowModal}
          examId={key}
          studentId={student?.key}
          questionId={answersByQuestion[currentIndex]?.questionId}
          teacherId={user?.key}
          comments={comments}
          setComments={setComments}
          index={currentIndex}
          addCommentToState={addCommentToState}
        />
      )}
      <div className="md:flex md:flex-row mx-10 flex flex-col-reverse ">
        <div className="flex gap-4 basis-3/4 ">
          <div>
            <div className="shadow py-[1rem] px-[5rem] md:flex md:flex-row md:justify-around flex flex-col w-[20rem] md:w-full border-l-2 border-[#00699B] bg-[#FFFFFF]">
              <div>
                <h5 className="text-[#18608C]">{currentExam?.title}</h5>
                <p className="mt-4 font-semibold">{student?.name}</p>
                <p className="font-light -mt-2">{student?.uid}</p>
              </div>
              <div>
                <p className="font-semibold text-[#7E57C2] bg-[#fafafa] p-4 mt-4">
                  {Math.round(studentMarks)}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex justify-center items-center gap-2">
                  <h6 className="font-semibold mt-2">Marks</h6>
                  <input
                    type="text"
                    name=""
                    id=""
                    value={studentMarks}
                    onChange={(e) =>
                      setMarks(Math.round(Number(e.target.value)))
                    }
                    className="border w-10"
                  />
                </div>
                <button
                  className="bg-[#3188C9] px-8 py-[0.5rem] mx-4 rounded text-white font-semibold "
                  onClick={handleMarksChange}
                >
                  {t("Save")}
                </button>
              </div>
            </div>

            {answersByQuestion?.map((answer: any, index: number) => (
              <div className="w-full">
                {index === currentIndex && (
                  <CobeAnswerCard
                    key={index.toString()}
                    answer={answer.answers}
                    index={index}
                    exam={currentExam}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    lengthFormatted={Number(answersByQuestion?.length)}
                  />
                )}
              </div>
            ))}
          </div>
          <div
            className="mt-[14rem] bg-[#FDF1E0] h-fit p-2 cursor-pointer md:block hidden"
            onClick={handleShowModal}
          >
            <FaRegComment color="#F28705" size={22} />
          </div>
        </div>

        <div className="basis-1/4 flex justify-between md:flex-none my-2">
          <CommentSidebar
            examId={key}
            studentId={student?.key}
            questionId={answersByQuestion[currentIndex]?.questionId!}
            teacherId={user?.key}
            comments={comments}
            setComments={setComments}
            index={currentIndex}
            deleteComment={deleteComment}
          />
          <div
            className=" bg-[#FDF1E0] h-fit p-2 cursor-pointer md:hidden block"
            onClick={handleShowModal}
          >
            <FaRegComment color="#F28705" size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CobeResults;

import { Dispatch } from "redux";

import { User } from "../types";
import { decrypt, logout, siginInWithToken } from "../utils/Generic";
import { ActionAuth } from "../reducers/AuthReducer";
import { BASE_URL } from "../utils/Constans";
import { ActionAuthType } from "./types";
import { ApiRequest } from "../utils/ApiRequests";

const GET_USER_URL = `${BASE_URL}/GetUserInfo`;
const POST_USER_WRONG_TIMES = `${BASE_URL}/UpdateWrongTimes`;
const GENERATE_LOGIN_TOKEN = `${BASE_URL}/CreateLoginToken`;

export const loginUser = (username: string, password: string) => {
  return async (dispatch: Dispatch<ActionAuth>) => {
    try {
      if (username.trim() === "" || password.trim() === "") {
        dispatch({
          type: ActionAuthType.LOGIN_FAIL,
          payload: "INCORRECT_LOGIN_INFO_ERROR",
        });
        return;
      }
      const accountType = username.toLowerCase().startsWith("t")
        ? "Teacher"
        : "Student";

      dispatch({ type: ActionAuthType.FETCH_USER });
      const response = await ApiRequest(
        GET_USER_URL,
        { username, accountType },
        "POST"
      );

      const user: User = response.data.userInfo;
      if (user.wrong_times === 0) {
        dispatch({
          type: ActionAuthType.LOGIN_FAIL,
          payload: "USER_BLOCKED",
        });
        return;
      }
      const serverPassword = decrypt(user.password);
      if (serverPassword != password) {
        await ApiRequest(
          POST_USER_WRONG_TIMES,
          {
            key: user.key,
            wrong_times: user.wrong_times - 1,
            accountType,
          },
          "POST"
        );
        dispatch({
          type: ActionAuthType.LOGIN_FAIL,
          payload: "INCORRECT_LOGIN_INFO_ERROR",
        });
        return;
      }

      const tokenResponse = await ApiRequest(
        GENERATE_LOGIN_TOKEN,
        { uid: username },
        "POST"
      );

      //do signin with token
      const token = tokenResponse.data.token;
      await siginInWithToken(token);
      dispatch({
        type: ActionAuthType.LOGIN_SUCCESS,
        payload: user,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ActionAuthType.LOGIN_FAIL,
        payload: "INCORRECT_LOGIN_INFO_ERROR",
      });
    }
  };
};

export const logoutUser = () => {
  return async (dispatch: Dispatch<ActionAuth>) => {
    await logout();
    dispatch({
      type: ActionAuthType.LOGOUT,
    });
  };
};

export const syncUser = (username: string) => {
  return async (dispatch: Dispatch<ActionAuth>) => {
    try {
      if (!username) throw "User not exit!";
      const accountType = username.toLowerCase().startsWith("t")
        ? "Teacher"
        : "Student";
      dispatch({ type: ActionAuthType.SYNC_USER });

      const tokenResponse = await ApiRequest(
        GENERATE_LOGIN_TOKEN,
        {
          uid: username,
        },
        "POST"
      );

      const response = await ApiRequest(
        GET_USER_URL,
        {
          username,
          accountType,
        },
        "POST"
      );

      const user: User = response.data.userInfo;
      const token = tokenResponse.data.token;
      await siginInWithToken(token);
      dispatch({
        type: ActionAuthType.LOGIN_SUCCESS,
        payload: user,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ActionAuthType.LOGOUT,
      });
    }
  };
};

export const setErrorMsg = (msg: string) => {
  return (dispatch: Dispatch<ActionAuth>) => {
    dispatch({
      type: ActionAuthType.SET_ERROR_MSG,
      payload: msg,
    });
  };
};

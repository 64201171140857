type LevelType = "Level1" | "Level2" | "Level3";
type LanguageType = "English" | "Hebrew";
type QuestionAnswerTypes = "MCQ" | "Matching" | "Written" | "Record";
type ExamTypes = "SampleExam" | "CobeExam" | "WrittenProduction";
type SampleExamQuestion = MCQuestion | MatchingQuestion;

enum ExamTypesEnum {
  SampleExam = "SampleExam",
  CobeExam = "CobeExam",
  WrittenProduction = "WrittenProduction",
}

interface User {
  uid: string;
  schoolCode: string;
  school: string;
  city: string;
  name: string;
  password: string;
  key?: string;
  wrong_times: number;
  accountType: "Teacher" | "Student";
  class?: string;
  token: string;
}

interface TeacherUserInfo extends User {
  checked: boolean;
}

interface Student {
  uid: string;
  schoolCode: string;
  school: string;
  city: string;
  name: string;
  class?: string;
}

interface StudentTable extends Student {
  checked: boolean;
}

type LabelsType = { value: string; label: string };

interface NavTab {
  key: string;
  path: string;
  title: string;
}

interface SampleExamExercise {
  key: string;
  language: LanguageType;
  level: LevelType;
  questionsRef: string;
  title: string;
}

interface Question {
  key?: string;
  title?: string;
  order: number;
  questionRef?: string;
  answerType: QuestionAnswerTypes;
}

interface QuestionResult {
  playerAnswer: string;
  isCorrect: boolean;
}

interface MCQuestion extends Question {
  options: string[];
  answer: string;
  question: string;
}

interface Topic {
  key?: string;
  title: string;
  topic: string;
  module: string;
  level: LevelType;
  language: LanguageType;
}

interface MatchingQuestion extends Question {
  answer: { answer: string; question: string }[];
  options: string[];
  question: string[];
}

interface CobeExamQuestion extends Question {
  question: string;
}

interface Exam {
  title: string;
  language: LanguageType;
  level: LevelType;
  examType: ExamTypes;
  submitionDate?: string;
  key?: string;
  status?: "Not Started" | "In Progress" | "Complete";
  createTime: number;
  attachedFile?: string; // base64
  share?: boolean;
  marks?: number;
}

interface SampleExam extends Exam {
  questions?: Question[];
  questionsRef?: string[];
  questionsType?: SampleExamExercise[];
}

interface SampleExamQuestions extends Exam {
  questions?: MCQuestion[];
  questionsRef?: string[];
  questionsType?: SampleExamExercise[];
}

interface CobeExam extends Exam {
  videoUrl?: string;
  imageUrl?: string;
  questions?: Question[];
}

interface WrittenProductionExam extends Exam {
  topic: Topic;
}

interface Group {
  key?: string;
  students: StudentTable[];
  title: string;
  createdBy: string;
  createdAt: string;
}

interface CheckedGroups extends Group {
  checked: boolean;
}

interface Word {
  word: string;
  mean: string;
  sound: string;
  sentences: string;
  core: string;
  key: string;
  language: LanguageType;
  level: LevelType;
  image?: string;
  checked?: boolean;
}

interface CheckedWords extends Word {
  checked: boolean;
}

interface Answer {
  questionId?: string;
  answer: string;
  originalAnswer: string;
  isCorrect: boolean;
  question: string;
  answerType: string;
  questionTitle?: string;
}

interface CobeAnswer extends Answer {
  storagePath?: string;
}
interface MatchingAnswer {
  questionId?: string;
  optionIndex?: number;
  option?: string;
  answerIndex?: number;
  answer?: string;
  originalAnswer: string;
  isCorrect: boolean;
  question: string;
  answerType: string;
  questionTitle?: string;
  completeQuestion?: MatchingQuestion;
}
interface MCQAnswer extends Answer {
  originalAnswer: string;
}
interface HomeWork {
  key?: string;
  words: Word[];
  title: string;
  createdBy: string;
  createdAt: number;
}

interface ExamReadyToUse {
  title: string;
  examType: string;
  language: LanguageType;
  order: number;
}

interface CobeExamReadyToUse extends ExamReadyToUse {
  videoUrl: string;
  questions: string[];
  group: string;
}

interface Competition {
  key: string;
  language: LanguageType;
  level: LevelType;
  order: number;
  school: string;
  title: string;
}

interface IPlayer {
  key: string;
  globalScore: number;
  localScore: number;
  user: User;
}

type StudentsExamsDashboard = {
  studentsExamDetails: { exam: Exam; student: StudentTable }[];
  teacherExam: Exam;
};

export {
  IPlayer,
  LevelType,
  LanguageType,
  MCQAnswer,
  User,
  LabelsType,
  NavTab,
  SampleExamExercise,
  QuestionAnswerTypes,
  Question,
  MCQuestion,
  SampleExam,
  CobeExam,
  Exam,
  SampleExamQuestion,
  MatchingQuestion,
  ExamTypesEnum,
  CobeExamQuestion,
  WrittenProductionExam,
  Topic,
  Group,
  Student,
  StudentTable,
  CheckedGroups,
  TeacherUserInfo,
  Word,
  CheckedWords,
  HomeWork,
  SampleExamQuestions,
  Answer,
  CobeAnswer,
  MatchingAnswer,
  CobeExamReadyToUse,
  Competition,
  StudentsExamsDashboard,
  QuestionResult,
};

import React from "react";
import "./StepTwo.css";
import { v4 as uuidV4 } from "uuid";
import { Button, Card, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icons } from "../../../../../../../assets/styles";
import { CheckedTable } from "../../../../../../../components";
import {
  CobeExam,
  CobeExamQuestion,
  Question,
  QuestionAnswerTypes,
} from "../../../../../../../types";
import { isRTL } from "../../../../../../../locales";
import {
  useActions,
  useFetch,
  useTypedSelector,
} from "../../../../../../../hooks";
import { BASE_URL } from "../../../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../../../utils/Generic";
import { ApiRequest } from "../../../../../../../utils/ApiRequests";

const TABLE_LINES = 5;

interface StepTwoProps {
  examQuestions: Question[];
  onQuestionsChanged: (question: Question[]) => void;
  singleExam?: CobeExam;
}

const FetchCobeQuestionsUrl = `${BASE_URL}/FetchTeacherCobeQuestions`;
const DeleteCobeQuestionUrl = `${BASE_URL}/RemoveTeacherCobeQuestion`;
const AddCobeQuestionUrl = `${BASE_URL}/AddTeacherCobeQuestion`;

function StepTwo({
  examQuestions,
  onQuestionsChanged,
  singleExam,
}: StepTwoProps) {
  const { t } = useTranslation();
  const { MsgActions } = useActions();
  const { user } = useTypedSelector((state) => state.AuthReducer);

  const [data, loading, error, _] = useFetch(
    FetchCobeQuestionsUrl,
    {
      uid: user?.uid,
    },
    getAuthHeaders,
    "GET"
  );

  const [showModal, setShowModal] = React.useState(false);
  const [newQuestion, setNewQuestion] = React.useState("");
  const [allQuestions, setAllQuestions] = React.useState<CobeExamQuestion[]>(
    []
  );
  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const [filteredQuestions, setFilteredQuestions] = React.useState<
    CobeExamQuestion[]
  >([]);

  React.useEffect(() => {
    setTotalQuestions(allQuestions.length);
  }, [allQuestions]);

  React.useEffect(() => {
    if (singleExam) {
      onQuestionsChanged(singleExam.questions!);
    }
  }, [singleExam]);

  React.useEffect(() => {
    if (!loading && data && data.questions) {
      setAllQuestions(data.questions);
      setFilteredQuestions(data.questions.slice(0, TABLE_LINES));
    }

    if (error != "") {
      MsgActions.showMsg("FetchFailed", "danger");
    }
  }, [loading, data, error]);

  const handleAddNewQuestion = async () => {
    const _question = allQuestions.filter((q) => q.question == newQuestion);
    if (_question.length > 0) {
      MsgActions.showMsg("QuestionAlreadyExist", "danger");
      return;
    }
    if (newQuestion.trim() == "") return;

    let _newQuestion: CobeExamQuestion = {
      question: newQuestion,
      order: new Date().getTime(),
      answerType: "Record",
      key: uuidV4(),
    };

    try {
      const config = await getAuthHeaders();
      await ApiRequest(
        AddCobeQuestionUrl,
        { uid: user?.uid, question: _newQuestion },
        "POST",
        config
      );
    } catch (err) {
      console.log(err);
      MsgActions.showMsg("FailedToCreateQuestion", "danger");
      return;
    }

    setAllQuestions((prev) => [_newQuestion, ...prev]);
    if (filteredQuestions.length < TABLE_LINES) {
      setFilteredQuestions((prev) => [_newQuestion, ...prev]);
    }
    setNewQuestion("");
  };

  const handleRemoveQuestion = async (question: Question) => {
    const _questions = allQuestions.filter((q) => q.key != question.key);
    const _filterQuestions = filteredQuestions.filter(
      (q) => q.key != question.key
    );
    setFilteredQuestions(_filterQuestions);
    setAllQuestions(_questions);
    try {
      const config = await getAuthHeaders();
      await ApiRequest(
        DeleteCobeQuestionUrl,
        { uid: user?.uid, question },
        "DELETE",
        config
      );
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const addQuestion = (question: Question) => {
    if (!isQuestionSelect(question)) {
      onQuestionsChanged([...examQuestions, question]);
    }
  };

  const removeQuestion = (question: Question) => {
    const _questions = examQuestions.filter((q) => q.key != question.key);
    onQuestionsChanged(_questions);
  };

  const isQuestionSelect = (question: Question) => {
    const q = examQuestions.filter((v) => v.key == question.key);
    return q.length > 0;
  };

  const handleAnswerTypeChanged = (
    question: Question,
    answerType: QuestionAnswerTypes
  ) => {
    const _questions = allQuestions.map((q) =>
      q.key == question.key ? { ...q, answerType: answerType } : { ...q }
    );
    setAllQuestions(_questions);
    const _examQuestions = examQuestions.map((q) =>
      q.key == question.key ? { ...q, answerType: answerType } : { ...q }
    );
    onQuestionsChanged(_examQuestions);
  };

  const handleOnQuestionChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      addQuestion(question);
    } else {
      removeQuestion(question);
    }
  };

  const onPageChanged = (page: number) => {
    setFilteredQuestions(
      allQuestions.slice(page * TABLE_LINES, (page + 1) * TABLE_LINES)
    );
  };

  const handleCheckAllChecked = (isChecked: boolean) => {
    if (isChecked) {
      const _questions = [...examQuestions];
      for (let question of filteredQuestions) {
        if (!isQuestionSelect(question)) {
          _questions.push(question);
        }
      }
      onQuestionsChanged(_questions);
    } else {
      let _questions = [...examQuestions];
      for (let question of filteredQuestions) {
        if (isQuestionSelect(question)) {
          _questions = _questions.filter((q) => q.key != question.key);
        }
      }
      onQuestionsChanged(_questions);
    }
  };

  const isAllChecked = () => {
    for (let question of filteredQuestions) {
      if (!isQuestionSelect(question)) return false;
    }
    return true;
  };

  const handleSearch = (txt: string) => {
    if (txt.trim() == "") {
      setFilteredQuestions(allQuestions.slice(0, TABLE_LINES));
      setTotalQuestions(allQuestions.length);
      return;
    }
    const _questions = allQuestions.filter((q) =>
      q.question.toLocaleLowerCase().startsWith(txt.toLowerCase().trim())
    );

    setTotalQuestions(_questions.length);
    setFilteredQuestions(_questions);
  };

  const handleAddQuestionModal = () => {
    const _newQuestions: CobeExamQuestion[] = [];
    for (let question of examQuestions) {
      const res = allQuestions.find(
        (q) => q.question === (question as CobeExamQuestion).question
      );
      if (!res) {
        _newQuestions.push(question as CobeExamQuestion);
      }
    }
    const _allQuestions = [..._newQuestions, ...allQuestions];
    setAllQuestions(_allQuestions);
    setFilteredQuestions(_allQuestions.slice(0, TABLE_LINES));
    setShowModal(true);
  };

  return (
    <div className="StepTwoCobe">
      <Modal
        dir={isRTL() ? "rtl" : "ltr"}
        className="StepTwoCobeModal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton />

        <Modal.Body>
          <Modal.Title>{t("Questions")}</Modal.Title>
          <Form>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>
                  {t("Choose Exam Questions")}{" "}
                  <span className="minQuestionNum">{`( ${t(
                    "at least 2 questions"
                  )} )`}</span>
                </Form.Label>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Control
                  value={newQuestion}
                  type="text"
                  placeholder={`${t("New Question")}`}
                  onChange={(e) => setNewQuestion(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Button onClick={handleAddNewQuestion}>
                  {t("Create Question")}
                </Button>
              </Form.Group>
            </Row>
            <Row>
              <CheckedTable
                onPageChanged={onPageChanged}
                onCheckedAll={handleCheckAllChecked}
                onSearch={handleSearch}
                headers={[t("Question"), t("Question Type"), t("Delete")]}
                totalData={totalQuestions}
                rowsPerPage={TABLE_LINES}
                isAllChecked={isAllChecked()}
              >
                {filteredQuestions.map((question) => (
                  <tr key={question.key}>
                    <td>
                      <Form.Check
                        checked={isQuestionSelect(question)}
                        onChange={(e) => handleOnQuestionChecked(e, question)}
                      />
                    </td>
                    <td>{question.question}</td>
                    <td>
                      <Stack direction="vertical">
                        <Form.Check
                          label="Written"
                          name={`group-${question.key}`}
                          type="radio"
                          id={`inline-radio-1`}
                          onClick={() =>
                            handleAnswerTypeChanged(question, "Written")
                          }
                        />
                        <Form.Check
                          onClick={() =>
                            handleAnswerTypeChanged(question, "Record")
                          }
                          label="Recorded"
                          name={`group-${question.key}`}
                          type="radio"
                          id={`inline-radio-2`}
                        />
                      </Stack>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveQuestion(question)}
                      >
                        {t("Delete")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </CheckedTable>
            </Row>

            <Form.Label>
              {t("Total questions")}: {examQuestions.length}
            </Form.Label>
          </Form>
        </Modal.Body>
      </Modal>
      <Card className="StepTwoWrapper" dir={isRTL() ? "rtl" : "ltr"}>
        <h2>{t("The Second Step")}</h2>
        <p>{t("Choice the questions")}</p>
        <div className="light-text mb-2">
          {t("Select the required questions from the question database")}
        </div>

        <Button onClick={handleAddQuestionModal}>
          <Stack direction="horizontal" gap={2}>
            <Icons.PlusIcon />
            <div>{t("Add Questions")}</div>
          </Stack>
        </Button>
      </Card>
    </div>
  );
}

export default StepTwo;

import React, { useState } from 'react';
import { Exam, Student } from '../../../../../../types';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ResetExamModal from './ResetExamModal';
import { useTypedSelector } from '../../../../../../hooks';
import { BASE_URL } from '../../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../../utils/Generic';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ShareExam_URL = `${BASE_URL}/ShareExamResult`;

interface ExamHeaderProps {
	currentExam: Exam | undefined;
	student: any | undefined;
}

const ExamHeader = ({ currentExam, student }: ExamHeaderProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState<boolean>(false);
	const [checkedShare, setCheckedShare] = useState<boolean>(
		currentExam?.share || false
	);

	const { user } = useTypedSelector(state => state.AuthReducer);

	const handleEdit = () => {
		if (currentExam?.examType === 'WrittenProduction') {
			navigate(`/exams/TopicExam/${currentExam?.key}/${student?.uid}`);
		} else if (currentExam?.examType === 'CobeExam') {
			navigate(`/exams/CobeExam/${currentExam?.key}/${student?.uid}`);
		} else {
			navigate(`/exams/SampleExamResult/${currentExam?.key}/${student?.uid}`);
		}
	};

	const handleShare = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedShare(!checkedShare);
		const isChecked = event.target.checked;

		const { data } = await axios.post(`${BASE_URL}/GetUserInfo`, {
			username: student.uid,
			accountType: 'Student',
		});

		const headers = await getAuthHeaders();

		axios
			.put(
				ShareExam_URL,
				{
					teacherId: user?.key,
					studentId: data?.userInfo.key,
					examId: currentExam?.key,
					share: isChecked,
				},
				{ headers: headers.headers }
			)
			.then(res => {
				// console.log(res.data);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const handleDelete = () => {
		setShowModal(true);
	};

	return (
		<div className="shadow py-[1rem] md:px-[5rem] px-4 md:flex md:justify-between border-l-2 border-[#00699B] ">
			<h5 className="text-[#18608C]">{currentExam?.title}</h5>
			<div>
				<p className="font-semibold text-[#7E57C2] bg-[#fafafa] p-4 mt-4">
					{student?.marks === 0 ? '-' : Math.round(student?.marks)}
				</p>
			</div>
			{showModal && (
				<ResetExamModal
					setShowModal={setShowModal}
					examId={currentExam?.key}
					studentId={student?.uid}
					teacherId={user?.key}
				/>
			)}
			<div className="flex flex-col gap-2">
				{student && (
					<button
						className="bg-[#9BDE7E]  py-[0.5rem] md:mx-4 mx-10 rounded text-white font-semibold "
						onClick={handleEdit}
						disabled={!student}
					>
						{`${t('Edit')}`}
					</button>
				)}

				{student?.isExam && (
					<button
						className="bg-[#FF6868]  py-[0.5rem] md:mx-4 mx-10 rounded text-white font-semibold"
						onClick={handleDelete}
					>
						{`${t('Delete')}`}
					</button>
				)}
				{student && (
					<div className="flex md:mx-4 mx-10">
						<Form.Check
							className="cursor-pointer"
							type="switch"
							checked={checkedShare}
							onChange={handleShare}
						/>
						<p>{`${t('Share answer key')}`} </p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ExamHeader;

import React, { useEffect, useState } from 'react';
import { Table, Pagination, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isRTL } from '../../../../../locales';
import { Exam } from '../../../../../types';
import ExamListTable from './ExamListTable';

import { useActions } from '../../../../../hooks';

interface ListExamTableProps {
	exams: Exam[];
}

const ExamList = ({ exams }: ListExamTableProps) => {
	const { t } = useTranslation();
	const { MsgActions } = useActions();
	const navigate = useNavigate();
	const [pages, setPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(-1);
	const [search, setSearch] = useState<string>('');
	const [allExams, setAllExams] = useState<Exam[]>([]);
	const [startIndex, setStartIndex] = useState(0);

	const rowsPerPage = 4;

	useEffect(() => {
		setPages(Math.ceil(exams.length / rowsPerPage));
		if (exams.length > 0) {
			setCurrentPage(0);
		}
	}, [exams]);

	const onPageChanged = (page: number) => {
		const newStartIndex = page * rowsPerPage;
		setStartIndex(newStartIndex);
		setAllExams(exams.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
	};

	const handlePrevPage = () => {
		if (currentPage - 1 >= 0) {
			setCurrentPage(currentPage - 1);
			onPageChanged(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage + 1 < pages) {
			setCurrentPage(currentPage + 1);
			onPageChanged(currentPage + 1);
		}
	};

	useEffect(() => {
		if (search === '') setAllExams(exams.slice(0, rowsPerPage));
	}, [exams, search]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const txt = e.target.value;

		if (txt.trim().length > 0) {
			setSearch(txt);
			// * find student name with search text
			const filteredStudents = exams.filter(exam => {
				return exam.title.toLowerCase().includes(txt.toLowerCase());
			});

			setAllExams(filteredStudents);
			return;
		}

		setSearch('');
	};

	const handleExamIntroduction = (exam: Exam) => {
		// check if exam time is over
		const examTime = new Date(exam.submitionDate!);

		const currentTime = new Date();
		if (examTime < currentTime) {
			MsgActions.showMsg('Exam time is over', 'danger');
			return;
		}
		navigate(`/student/exams/introduction/${exam.key}`);
	};

	return (
		<div style={{ minHeight: 'calc(90vh + 100px)' }}>
			<div className="flex flex-row gap-4 my-4">
				<Form.Control
					className="search mx-4"
					style={{ maxWidth: 300 }}
					value={search}
					type="text"
					placeholder={`${t('Search')}...`}
					onChange={handleSearch}
				/>
			</div>
			<Table
				striped
				hover
				bordered
				dir={isRTL() ? 'rtl' : 'ltr'}
				className="overflow-hidden "
			>
				<thead>
					<tr>
						<th>
							<div className="flex justify-center  md:text-[1.5rem] text-[#186093] ">
								{t('Title')}
							</div>
						</th>

						<th>
							<div className="flex justify-center text-[#18608C] md:text-[1.5rem]">
								{t('Created')}
							</div>
						</th>
						<th>
							<div className="flex justify-center text-[#18608C] md:text-[1.5rem]">
								{t('Status')}
							</div>
						</th>
						<th>
							<div className="flex justify-center text-[#18608C] md:text-[1.5rem]">
								{t('Submitted')}
							</div>
						</th>
						<th>
							<div className="flex justify-center text-[#18608C] md:text-[1.5rem]"></div>
						</th>
					</tr>
				</thead>
				<tbody>
					{allExams.map((exam, index) => (
						<tr key={index.toString()}>
							<ExamListTable
								exam={exam}
								handleExamIntroduction={handleExamIntroduction}
							/>
						</tr>
					))}
				</tbody>
			</Table>
			{/* pages */}
			<Pagination className="flex justify-end">
				<Pagination.Prev onClick={handlePrevPage} />

				<Pagination.Item>
					<span>
						{currentPage + 1} / {pages}
					</span>
				</Pagination.Item>

				<Pagination.Next onClick={handleNextPage} />
			</Pagination>
		</div>
	);
};

export default ExamList;

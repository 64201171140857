import React, { useEffect } from "react";
import "./StepOne.css";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BASE_URL,
  Languages,
  SCHOOL_LEVELS,
} from "../../../../../../../utils/Constans";
import { Switch, InputTag } from "../../../../../../../components";
import {
  useActions,
  useFetch,
  useTypedSelector,
} from "../../../../../../../hooks";
import { getAuthHeaders } from "../../../../../../../utils/Generic";
import {
  Exam,
  ExamTypesEnum,
  LanguageType,
  LevelType,
  SampleExam,
  SampleExamExercise,
} from "../../../../../../../types";
import { Tag } from "../../../../../../../components/InputTag/InputTag";
import { isRTL } from "../../../../../../../locales";

const FetchExercisesUrl = `${BASE_URL}/FetchSampleExamExercises`;

interface StepOneProps {
  onPress: VoidFunction;
  singleExam?: SampleExam;
}

type ExerciseTag = Tag & SampleExamExercise;

function StepOne({ onPress, singleExam }: StepOneProps) {
  const { MsgActions, ExamActions } = useActions();
  const form = React.useRef<HTMLFormElement>(null);
  const { exam } = useTypedSelector((state) => state.ExamReducer);
  const { t } = useTranslation();
  const [validate, setValidate] = React.useState(false);
  const [exercises, setExercises] = React.useState<SampleExamExercise[]>([]);
  const [examLanugage, setExamLanguage] =
    React.useState<LanguageType>("English");

  const [examLevel, setExamLevel] = React.useState<LevelType>("Level1");
  const [examTitle, setExamTitle] = React.useState("");
  const [examDeadLine, setExamDeadLine] = React.useState<string | undefined>(
    ""
  );
  const [examQuestionsType, setExamQuestionsType] = React.useState("");
  const [tags, setTags] = React.useState<ExerciseTag[]>([]);

  const [data, loading, error, setParams] = useFetch(
    FetchExercisesUrl,
    { language: "English", level: "Level1" },
    getAuthHeaders,
    "GET"
  );

  useEffect(() => {
    if (singleExam) {
      setExamTitle(exam ? exam.title : singleExam.title);
      setExamLevel(exam ? exam.level : singleExam.level);
      setExamLanguage(exam ? exam.language : singleExam.language);
      setExamDeadLine(exam ? exam.submitionDate : singleExam.submitionDate);
      let questionsType: SampleExamExercise[] = [];
      if ((exam as SampleExam)?.questionsType) {
        questionsType = (exam as SampleExam).questionsType || [];
      } else {
        questionsType = singleExam.questionsType || [];
      }
      setExercises(questionsType);
      const _tags: ExerciseTag[] = questionsType.map((ex) => ({
        ...ex,
        label: ex.title,
        value: ex.key,
      }));
      setTags(_tags);
    }
  }, [singleExam]);

  React.useEffect(() => {
    if (!loading && data) {
      setExercises(data.excercises);
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      MsgActions.showMsg("FetchFailed", "danger");
    }
  }, [error]);

  const handleExamLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const level = e.target.value as LevelType;
    setExamLevel(level);
    setExamQuestionsType("");
    setExercises([]);
    setParams({ language: examLanugage, level });
  };

  const handleExamLanugageChange = (value: string) => {
    const language = value as LanguageType;
    setExamLanguage(language);
    setExamQuestionsType("");
    setExercises([]);
    setParams({ language, level: examLevel });
  };

  const handleNextPress = (e: React.MouseEvent) => {
    e.preventDefault();
    if (form.current?.checkValidity() === true) {
      const _exercises = tags.map((tag) => {
        const _exercise: SampleExamExercise = {
          key: tag.key,
          language: tag.language,
          level: tag.level,
          questionsRef: tag.questionsRef,
          title: tag.title,
        };
        return _exercise;
      });

      const exam: SampleExam = {
        title: examTitle,
        language: examLanugage,
        level: examLevel,
        examType: ExamTypesEnum.SampleExam,
        questionsType: _exercises,
        submitionDate: examDeadLine,
        createTime: new Date().getTime(),
      };
      ExamActions.updateExam(exam);
      onPress();
      return;
    }

    setValidate(true);
  };

  const handleExamQuestionTypeSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    setExamQuestionsType(value);
    const ex = exercises.find((exc) => exc.key == value);
    if (ex) {
      const _tags = [...tags];
      const exist = _tags.find((tag) => tag.value == ex.key);
      if (!exist) {
        _tags.push({ label: ex.title, value: ex.key, ...ex });
        setTags(_tags);
      }
    }
  };

  const handleRemoveTag = (tag: Tag) => {
    const _tags = tags.filter((_tag) => _tag.value != tag.value);
    setTags(_tags);
    if (_tags.length == 0) {
      setExamQuestionsType("");
    }
  };

  return (
    <div className="StepOne">
      <Form ref={form} noValidate validated={validate}>
        <Card dir={isRTL() ? "rtl" : "ltr"}>
          <Card.Body>
            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam title")}</Form.Label>
                <Form.Control
                  value={examTitle}
                  required
                  type="text"
                  placeholder={`${t("Enter exam title")}`}
                  onChange={(e) => setExamTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam Is Due")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={examDeadLine}
                  onChange={(e) => setExamDeadLine(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam Level")}</Form.Label>
                <Form.Select
                  required
                  value={examLevel}
                  onChange={handleExamLevelChange}
                >
                  {SCHOOL_LEVELS.map((level) => (
                    <option key={level.value} value={level.value}>
                      {t(level.label)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("t21")}</Form.Label>
                <Switch
                  initValue={
                    singleExam
                      ? singleExam.language
                      : exam?.language || "English"
                  }
                  values={Languages}
                  onSelect={handleExamLanugageChange}
                />
              </Form.Group>
            </Row>

            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Question Type")}</Form.Label>
                <Form.Select
                  value={examQuestionsType}
                  onChange={handleExamQuestionTypeSelect}
                >
                  <option value={""}>{t("Select Type")}</option>
                  {exercises.map((exercise) => (
                    <option key={exercise.key} value={exercise.key}>
                      {exercise.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="mb-3" />
              {tags.length > 0 && (
                <InputTag tags={tags} onTagPress={handleRemoveTag} />
              )}
            </Row>
          </Card.Body>

          <Card.Footer className="step1Footer">
            <Button className="nextBtn" onClick={handleNextPress}>
              {t("next")}
            </Button>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
}

export default StepOne;

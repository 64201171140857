import React from 'react';
import { HomeWork } from '../../../../../../types';
interface ListHomeworkProps {
	homework: HomeWork;
}

const ListHomeworkTime = ({ homework }: ListHomeworkProps) => {
	const date = new Date(homework.createdAt);
	const time = date.toLocaleTimeString();

	const stringDate = date.toLocaleDateString();

	return (
		<div>
			{stringDate}, {time}
		</div>
	);
};

export default ListHomeworkTime;

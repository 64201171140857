import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ListExamTable from './Components/ListExamTable';

import { useActions, useTypedSelector } from '../../../../../hooks';

const ListExam = () => {
	const { ExamActions } = useActions();

	const { exams, loading } = useTypedSelector(state => state.ExamReducer);

	const { user } = useTypedSelector(state => state.AuthReducer);

	useEffect(() => {
		if (user) ExamActions.fetchExams(user?.key);
	}, [user]);

	return (
		<div className="container" style={{ minHeight: 'calc(100vh + 100px)' }}>
			{loading && (
				<div className="flex justify-center items-center h-[80vh]">
					<Spinner animation="border" variant="primary" className="mt-2 " />
				</div>
			)}

			{!loading && exams && (
				<div className="StudentsTable w-full  ">
					<ListExamTable exams={exams} />
				</div>
			)}
		</div>
	);
};

export default ListExam;

interface ListExamTimeProps {
	examDate: any;
}

const ListExamTime = ({ examDate }: ListExamTimeProps) => {
	// console.log(exam);
	const date = new Date(examDate);
	const time = date.toLocaleTimeString();

	const stringDate = date.toLocaleDateString();

	return (
		<div className="flex justify-center text-[0.8rem]">
			{stringDate}, {time}
		</div>
	);
};

export default ListExamTime;

import { useTranslation } from "react-i18next";
import { MCQuestion, Question } from "../../../../../../../types";
import { Button } from "react-bootstrap";
import { Icons } from "../../../../../../../assets/styles";

interface MCQuestionViewProps {
  question: Question;
  viewBtnPress: VoidFunction;
}

const MCQuestionView = ({ question, viewBtnPress }: MCQuestionViewProps) => {
  const { t } = useTranslation();
  return (
    <>
      <td>
        <div className="titleQuestionText">
          {(question as MCQuestion).title}
        </div>
        <div>
          {(question as MCQuestion).question.split("@").map((word, i) => (
            <span
              key={i.toString()}
              style={{
                color: i % 2 == 0 ? "var(--text)" : "var(--orange)",
                fontWeight: i % 2 == 0 ? "normal" : "bold",
              }}
            >
              {word}
            </span>
          ))}
        </div>
      </td>
      <td className="answerQuestionText">
        <div>{(question as MCQuestion).answer}</div>
      </td>
      <td>
        <div>
          <Button variant="success" onClick={viewBtnPress}>
            <Icons.EyeIcon color={"white"} size={20} /> {t("view")}
          </Button>
        </div>
      </td>
    </>
  );
};

export default MCQuestionView;

import React from "react";
import "./languageWrapper.css";
import { useTranslation } from "react-i18next";
import { Stack } from "react-bootstrap";
import { LOGIN_VECTOR4 } from "../../../../assets/images";
import { isRTL } from "../../../../locales";

function LanguageWrapper() {
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="languageWrapper">
      <Stack
        direction="horizontal"
        gap={2}
        style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
      >
        <img src={LOGIN_VECTOR4} alt="LOGIN_VECTOR4" />
        <Stack
          direction="vertical"
          style={{
            alignItems: isRTL() ? "flex-end" : "flex-start",
          }}
        >
          <h3>{t("t3")}</h3>
          <p style={{ textAlign: isRTL() ? "right" : "left" }}>{t("t4")}</p>
        </Stack>
      </Stack>

      <Stack
        direction="horizontal"
        className="mt-4"
        style={{ flexDirection: isRTL() ? "row-reverse" : "row" }}
      >
        <div
          className="languageCard"
          style={{
            backgroundColor: i18n.language === "en" ? "#f28705" : "white",
            color: i18n.language === "en" ? "white" : "black",
          }}
          onClick={() => handleChangeLanguage("en")}
        >
          <div className="letterLanguageCard">
            <h2>A</h2>
          </div>
          <h4>English</h4>
        </div>
        <div
          className="languageCard"
          style={{
            backgroundColor: i18n.language === "he" ? "#f28705" : "white",
            color: i18n.language === "he" ? "white" : "black",
          }}
          onClick={() => handleChangeLanguage("he")}
        >
          <div className="letterLanguageCard">
            <h2>א</h2>
          </div>
          <h4>עברית</h4>
        </div>
      </Stack>
    </div>
  );
}

export default LanguageWrapper;

import { ActionMsg, MsgType } from "../reducers/MsgReducer";
import { ActionMsgType } from "./types";
import { Dispatch } from "redux";

export const cleanMsg = () => {
  return (dispatch: Dispatch<ActionMsg>) => {
    dispatch({
      type: ActionMsgType.CLEAN_MSG,
    });
  };
};

export const showMsg = (msg: string, msgType: MsgType) => {
  return (dispatch: Dispatch<ActionMsg>) => {
    switch (msgType) {
      case "danger":
        dispatch({
          type: ActionMsgType.SHOW_ERROR_MSG,
          payload: msg,
        });
        break;
      case "info":
        dispatch({
          type: ActionMsgType.SHOW_INFO_MSG,
          payload: msg,
        });
        break;
      case "success":
        dispatch({
          type: ActionMsgType.SHOW_SUCCESS_MSG,
          payload: msg,
        });
        break;
      default:
        dispatch({
          type: ActionMsgType.SHOW_INFO_MSG,
          payload: msg,
        });
        break;
    }
  };
};

import { ActionMsgType } from "../actions/types";

export type MsgType = "danger" | "info" | "success" | "";

export interface MsgState {
  msg: string;
  showMsg: boolean;
  msgType: MsgType;
}

interface ActionClean {
  type: ActionMsgType.CLEAN_MSG;
}

interface Action {
  type:
    | ActionMsgType.SHOW_ERROR_MSG
    | ActionMsgType.SHOW_SUCCESS_MSG
    | ActionMsgType.SHOW_INFO_MSG;
  payload: string;
}

export type ActionMsg = Action | ActionClean;

const defaultProps: MsgState = {
  msg: "",
  showMsg: false,
  msgType: "",
};

const MsgReducer = (
  state: MsgState = defaultProps,
  action: ActionMsg
): MsgState => {
  switch (action.type) {
    case ActionMsgType.SHOW_ERROR_MSG:
      return { msg: action.payload, showMsg: true, msgType: "danger" };
    case ActionMsgType.SHOW_INFO_MSG:
      return { msg: action.payload, showMsg: true, msgType: "info" };
    case ActionMsgType.SHOW_SUCCESS_MSG:
      return { msg: action.payload, showMsg: true, msgType: "success" };
    case ActionMsgType.CLEAN_MSG:
      return { msg: "", showMsg: false, msgType: "" };
    default:
      return state;
  }
};

export default MsgReducer;

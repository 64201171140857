import React from "react";
import "./StepThree.css";
import { Button, Card, Modal, Spinner, Stack } from "react-bootstrap";
import { Icons } from "../../../../../../../assets/styles";
import { useTranslation } from "react-i18next";
import { isRTL } from "../../../../../../../locales";
import {
  CobeExam,
  CobeExamQuestion,
  Question,
} from "../../../../../../../types";
import {
  SortableItemProps,
  SortableList,
} from "@thaddeusjiang/react-sortable-list";
import { MIN_EXAM_QUESTIONS_NUM } from "../../../../../../../utils/Constans";
import { useActions, useTypedSelector } from "../../../../../../../hooks";
import { useParams } from "react-router-dom";

interface StepThreeProps {
  examQuestions: Question[];
  onCreatePress: () => void;
  onUpdatePress: () => void;
  onQuestionsChanged: (questions: Question[]) => void;
  singleExam?: CobeExam;
}

interface SortableQuestion extends SortableItemProps {
  question: Question;
}

function StepThree({
  examQuestions,
  onCreatePress,
  onUpdatePress,
  onQuestionsChanged,
  singleExam,
}: StepThreeProps) {
  const { t } = useTranslation();
  const { loading } = useTypedSelector((state) => state.ExamReducer);

  const [modalVisible, setModalVisible] = React.useState(false);
  const [questions, setQuestions] = React.useState<SortableItemProps[]>([]);
  const { key } = useParams();
  const [drag, setDrag] = React.useState(false);
  React.useEffect(() => {
    const _questions = examQuestions.map((question) => {
      return { id: question.key || "", question };
    });
    setQuestions(_questions);
  }, [examQuestions]);

  const handleRemoveQuestion = (question: Question) => {
    const _questions = examQuestions.filter((q) => q.key != question.key);

    if (_questions.length < MIN_EXAM_QUESTIONS_NUM) {
      alert(
        t("Minimum number of questions in exam should be") +
          ` ${MIN_EXAM_QUESTIONS_NUM}`
      );
      return;
    }
    onQuestionsChanged(_questions);
  };

  const handleCreateExam = () => {
    if (loading) return;
    onCreatePress();
  };
  const handleUpdateExam = () => {
    if (loading) return;
    onUpdatePress();
  };

  return (
    <div className="StepThreeCobe">
      <Modal
        dir={isRTL() ? "rtl" : "ltr"}
        className="StepThreeCobeModal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h3>{t("Selected Questions")}</h3>
          <div className="light-text">
            {t("Hold press to drag and sort the exam questions")}
          </div>
          <div className="mb-2">
            {t("Total questions")}: {questions.length}
          </div>
          <Stack gap={2} direction="horizontal" className="mb-5">
            {!key && (
              <Button className="createExamBtn" onClick={handleCreateExam}>
                {t("t7")}
              </Button>
            )}
            {key && (
              <Button className="updateExamBtn" onClick={handleUpdateExam}>
                {t("EditExam")}
              </Button>
            )}

            {loading && <Spinner variant="primary" />}
          </Stack>

          <div
            className={`dargWrapper ${drag ? "dargAble" : "dragDisable"} `}
            onClick={() => setDrag(!drag)}
          >
            <Icons.SortIcon color={drag ? "white" : "black"} />
          </div>
          <SortableList
            disabled={!drag}
            items={questions}
            setItems={setQuestions}
            itemRender={({ item }) => {
              const question = (item as SortableQuestion)
                .question as CobeExamQuestion;
              return (
                <Card className="mb-2" key={question.key}>
                  <Card.Body>
                    <Stack
                      direction="horizontal"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <h3>{question.question}</h3>
                        <div className="light-text">{question.answerType}</div>
                      </div>
                      {drag ? (
                        <Icons.DargIcon />
                      ) : (
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveQuestion(question)}
                        >
                          {t("Delete")}
                        </Button>
                      )}
                    </Stack>
                  </Card.Body>
                </Card>
              );
            }}
          />
        </Modal.Body>
      </Modal>
      <Card className="StepThreeWrapper" dir={isRTL() ? "rtl" : "ltr"}>
        <h2>{t("The Third Step")}</h2>
        <p>{t("Review questions")}</p>
        <div className="light-text mb-2">{t("See the questions I added")}</div>
        <Button
          disabled={questions.length == 0}
          onClick={() => setModalVisible(true)}
        >
          <Stack direction="horizontal" gap={2}>
            <Icons.EyeIcon color="white" />
            <div>{t("Selected")}</div>
          </Stack>
        </Button>
      </Card>
    </div>
  );
}

export default StepThree;

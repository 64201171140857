import React from "react";
import { v4 as uuidV4 } from "uuid";
import { CobeExamQuestion, Answer } from "../../../../../types";
import Recorder from "./components/Recorder";
import { useTypedSelector } from "../../../../../hooks";
import SubmitModal from "./components/SubmitAnswer";

import { storage } from "./firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

interface QuestionCardProps {
  question: CobeExamQuestion;
  questionIndex: string | undefined;
  setAnswers: React.Dispatch<React.SetStateAction<any[]>>;
  answers: any[];
  currentExam: any;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  lengthFormatted: number;
  handleSendAnswers: () => void;
}

const CobeQuestionCard = ({
  question,
  questionIndex,
  setAnswers,
  answers,
  currentExam,
  currentIndex,
  setCurrentIndex,
  lengthFormatted,
  handleSendAnswers,
}: QuestionCardProps) => {
  const [answer, setAnswer] = React.useState<string>("");
  const [audioBlob, setAudioBlob] = React.useState<Blob>();
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleAnswer = async () => {
    if (question.answerType === "Record" && audioBlob) {
      const audioPath = `recording/${user?.uid}/${
        currentExam?.key
      }-${uuidV4()}-${question?.order}.m4a`;
      const storageRef = ref(storage, audioPath);
      const uploadTask = uploadBytesResumable(storageRef, audioBlob!);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            const index = answers.findIndex(
              (answer: Answer) => answer.questionId === question.key
            );

            if (index !== -1) {
              answers[index] = {
                questionId: question.key,
                answer: url,
                answerType: "Record",
                storagePath: audioPath,
              };
              setAnswers(answers);
              return;
            } else {
              setAnswers((prev) => [
                ...prev,
                {
                  questionId: question.key,
                  answer: url,
                  answerType: "Record",
                  storagePath: audioPath,
                },
              ]);
            }
          });
        }
      );
    }

    if (question.answerType === "Written" && answer) {
      const index = answers.findIndex(
        (answer: Answer) => answer.questionId === question.key
      );

      if (index !== -1) {
        answers[index] = {
          questionId: question.key,
          answer,
          answerType: "Written",
        };
        setAnswers(answers);
        return;
      } else {
        setAnswers((prev) => [
          ...prev,
          { questionId: question.key, answer, answerType: "Written" },
        ]);
      }
    }
  };

  return (
    <div style={{ minHeight: "calc(120vh + 100px)" }}>
      {questionIndex === question.key && (
        <div className="flex  justify-center items-center ">
          <div className="flex flex-col shadow-md md:px-10 mx-4 md:mx-0 py-2">
            {currentExam.imageUrl && (
              <img
                src={currentExam.imageUrl}
                alt="question"
                className="w-[20rem] md:mx-[10rem]  my-2"
              />
            )}

            {showModal && (
              <SubmitModal
                handleSendAnswers={handleSendAnswers}
                setShowModel={handleShowModal}
              />
            )}

            {currentExam.videoUrl && (
              <div className="aspect-w-16 aspect-h-9  md:mx-[10rem]">
                <iframe
                  // width="600"
                  // height="280"
                  src={currentExam.videoUrl}
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="h-full w-full"
                  title="Embedded youtube"
                />
              </div>
            )}
            <p className="mt-1 text-[1.3rem]">{question.question}</p>
            <div className="md:flex md:flex-col md:text-left md:items-center my-2">
              {question.answerType === "Written" && (
                <textarea
                  name=""
                  id=""
                  cols={80}
                  rows={15}
                  className="mt-2 border p-2 w-[18rem] md:w-full h-full"
                  onChange={(e) => setAnswer(e.target.value)}
                ></textarea>
              )}

              {question.answerType === "Record" && (
                <Recorder
                  initUrl={answers[currentIndex]?.answer}
                  audioBlob={audioBlob}
                  setAudioBlob={setAudioBlob}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="md:flex  md:justify-center md:mx-[12rem] md:gap-[10rem] flex gap-4 mx-2 my-4">
        <div>
          <button
            className={`bg-[#3188C9] text-white px-4 py-2 w-[8rem] font-semibold rounded ${
              currentIndex === 0 && "bg-[#D3D3D3]"
            }`}
            onClick={() => {
              handleAnswer();
              setCurrentIndex(currentIndex - 1);
            }}
            disabled={currentIndex === 0}
          >
            Previous
          </button>
          {currentIndex < lengthFormatted - 1 && (
            <button
              className="bg-[#3188C9] text-white px-6 w-[8rem] py-2 font-semibold rounded "
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
                handleAnswer();
              }}
            >
              Next
            </button>
          )}
        </div>
        <div>
          {currentIndex === lengthFormatted - 1 && (
            <button
              className="bg-[#9BDE7E] text-white px-6 w-[10rem] py-2 font-semibold rounded "
              onClick={() => {
                handleAnswer();
                handleShowModal();
              }}
            >
              Send Answers
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CobeQuestionCard;

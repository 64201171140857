import React from "react";
import "./CompetitionScreen.css";
import { Alert, Form, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { isRTL } from "../../../../locales";
import { BASE_URL } from "../../../../utils/Constans";
import { useActions, useFetch, useTypedSelector } from "../../../../hooks";
import { Competition, IPlayer } from "../../../../types";
import { getAuthHeaders } from "../../../../utils/Generic";
import { ApiRequest } from "../../../../utils/ApiRequests";

const FETCH_STUDENTS_SCHOOL_COMPETION_URL = `${BASE_URL}/fetch-competition-players`;
const FETCH_SCHOOL_COMPETITIONS_URL = `${BASE_URL}/FetchCompetitionsBySchool`;

const CompetitionScreen = () => {
  const { t } = useTranslation();
  const [competitions, setCompetitions] = React.useState<Competition[]>([]);
  const [allPlayers, setAllPlayers] = React.useState<IPlayer[]>([]);
  const [filteredPlayers, setFilteredPlayers] = React.useState<IPlayer[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [classes, setClasses] = React.useState<string[]>([]);

  const { user } = useTypedSelector((state) => state.AuthReducer);
  const { MsgActions } = useActions();
  const [data, loading, error, _] = useFetch(
    FETCH_SCHOOL_COMPETITIONS_URL,
    { school: user?.schoolCode, uid: user?.uid },
    getAuthHeaders,
    "POST"
  );

  React.useEffect(() => {
    if (!loading && data && data.competitions.length > 0) {
      setCompetitions(data.competitions);
      return;
    }
    if (error) {
      MsgActions.showMsg("FetchFailed", "danger");
    }
  }, [data, loading, error]);

  React.useEffect(() => {
    if (competitions.length > 0) {
      handleFetchPlayers(data.competitions[0].key);
    }
  }, [competitions]);

  React.useEffect(() => {
    const _classes = new Set<string>();
    for (let player of allPlayers) {
      _classes.add(player.user.class as string);
    }

    setClasses(Array.from(_classes));
  }, [allPlayers]);

  const handleFetchPlayers = async (competitionKey: string) => {
    try {
      const competition = competitions.find(
        (competition) => competition.key === competitionKey
      );

      setFilteredPlayers([]);
      setAllPlayers([]);
      setFetching(true);
      const params = {
        level: competition?.level,
        language: competition?.language,
        title: competition?.title,
        school: competition?.school,
        key: competition?.key,
      };
      const headers = await getAuthHeaders();
      const response = await ApiRequest(
        FETCH_STUDENTS_SCHOOL_COMPETION_URL,
        params,
        "GET",
        headers
      );

      setFilteredPlayers(response.data.players);
      setAllPlayers(response.data.players);
    } catch (err) {
      console.log((err as Error).message);
    } finally {
      setFetching(false);
    }
  };

  const handleFilterPlayersByClass = (className: string) => {
    if (className === "all") {
      setFilteredPlayers(allPlayers);
      return;
    }
    const _players = allPlayers.filter((p) => p.user.class == className);
    setFilteredPlayers(_players);
  };

  return (
    <div dir={isRTL() ? "rtl" : "ltr"} className="CompetitionScreen p-2">
      <h1>{t("Competition")}</h1>

      {/* select competition */}
      <div className="mb-2">
        <Form.Label>
          <h4>{t("SelectCompetiton")}</h4>
        </Form.Label>

        <Form.Select
          className="Select w-50"
          defaultValue={t("SelectCompetiton") || "Select Competiton"}
          onChange={(e) => handleFetchPlayers(e.target.value)}
        >
          {competitions.map((competition) => (
            <option key={competition.key} value={competition.key}>
              {t(competition.title)}
            </option>
          ))}
        </Form.Select>
      </div>
      {/* filter by class */}
      <div className="mb-2">
        <Form.Label>
          <h4>{t("Class")}</h4>
        </Form.Label>

        <Form.Select
          style={{ maxWidth: 150 }}
          onChange={(e) => handleFilterPlayersByClass(e.target.value)}
        >
          <option value={"all"}>{t("All")}</option>
          {classes.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
      </div>
      {/* table */}
      <Table striped hover bordered dir={isRTL() ? "rtl" : "ltr"}>
        <thead>
          <tr>
            <th />
            <th>{t("User")}</th>
            <th>{t("Name")}</th>
            <th>{t("Class")}</th>
            <th>{t("LocalScore")}</th>
            {/* <th>{t("GlobalScore")}</th> */}
          </tr>
        </thead>
        <tbody>
          {filteredPlayers.map((player, index) => (
            <tr key={player.key}>
              <td>{`${index + 1}`}</td>
              <td>{player.user.uid}</td>
              <td>{player.user.name}</td>
              <td>{player.user.class}</td>
              <td>{player.localScore}</td>
              {/* <td>{player.globalScore}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
      {filteredPlayers.length == 0 && !fetching && competitions.length > 0 && (
        <div>
          <Alert variant={"warning"}>{t("NoStudentsFound")}</Alert>
        </div>
      )}
      {fetching && (
        <div className="SpinnerWrapper">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export default CompetitionScreen;

import { Modal, Button } from 'react-bootstrap';
import { Exam } from '../../../../../../types';
import { BASE_URL } from '../../../../../../utils/Constans';
import axios from 'axios';
import { getAuthHeaders } from '../../../../../../utils/Generic';
import { useActions, useTypedSelector } from '../../../../../../hooks';

interface DeleteExamProps {
	setShowDeleteModel: (newType: boolean) => void;
	newExam: Exam;
	setAllExams: (newType: Exam[]) => void;
	allExams: Exam[];
}

const DeleteExam = ({
	setShowDeleteModel,
	newExam,
	setAllExams,
	allExams,
}: DeleteExamProps) => {
	const { MsgActions } = useActions();
	const { user } = useTypedSelector(state => state.AuthReducer);

	const handleDeleteExam = async () => {
		const DELETE_EXAM = `${BASE_URL}/DeleteTeacherExam/${user?.key}/${newExam.key}`;
		const headers = await getAuthHeaders();
		await axios
			.delete(
				`${DELETE_EXAM}`,

				{
					headers: headers.headers,
				}
			)
			.then(res => {
				MsgActions.showMsg(res.data.msg, 'success');
				// Delete exam with key from all exams
				const filteredArray = allExams.filter(exam => exam.key !== newExam.key);
				setAllExams(filteredArray);
			})
			.catch(err => {
				MsgActions.showMsg(err.response.data.msg, 'danger');
			});
	};
	return (
		<div>
			<Modal show={true}>
				<Modal.Header
					closeButton
					onClick={() => setShowDeleteModel(false)}
				></Modal.Header>
				<Modal.Body>Are you sure you want to delete this exam?</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setShowDeleteModel(false);
						}}
					>
						No
					</Button>
					<Button
						variant="success"
						onClick={() => {
							setShowDeleteModel(false);
							handleDeleteExam();
						}}
					>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DeleteExam;

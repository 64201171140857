import { useState, useEffect } from "react";
import EditTitle from "./EditTitle";
import ListExamTime from "./ListExamTime";
import DeleteExam from "./DeleteExam";
import UpdateExam from "./UpdateExam";
import SendExam from "./SendExam";
import ShareExam from "./ShareExam";
import { useTranslation } from "react-i18next";
import { Form, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isRTL } from "../../../../../../locales";
import { Table } from "react-bootstrap";
import { Exam, Question, SampleExam } from "../../../../../../types";
import { useActions, useTypedSelector } from "../../../../../../hooks";
import {
  DownloadIcon,
  ShareIcon,
  SendIcon,
  CopyIcon,
  EditIcon,
  DeleteIcon,
  EyeIcon,
} from "../../../../../../assets/icons";
import { generateSampleExamWordDocument } from "../../../../../../utils/Reports";
import { ApiRequest } from "../../../../../../utils/ApiRequests";
import { BASE_URL } from "../../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../../utils/Generic";
const SHARE_EXAM_WITH_TEACHERS_UTL = `${BASE_URL}/ShareExamsWithTeachers`;
const Fetch_Exam_Questions = `${BASE_URL}/FetchExamQuestions`;
interface ListExamTableProps {
  exams: Exam[];
}

const ListExamTable = ({ exams }: ListExamTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ExamActions } = useActions();
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const [newTitle, setNewTitle] = useState("");
  const [newExam, setNewExam] = useState<Exam>(exams[0]);
  const [showModel, setShowModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [showSendModel, setShowSendModel] = useState(false);
  const [showShareModel, setShowShareModel] = useState(false);
  const [allExams, setAllExams] = useState<Exam[]>([]);
  const [search, setSearch] = useState<string>("");
  const [startIndex, setStartIndex] = useState(0);

  // * Pagination
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);

  const rowsPerPage = 4;

  useEffect(() => {
    setPages(Math.ceil(exams.length / rowsPerPage));
    if (exams.length > 0) {
      setCurrentPage(0);
    }
  }, [exams]);

  const onPageChanged = (page: number) => {
    const newStartIndex = page * rowsPerPage;
    setStartIndex(newStartIndex);
    setAllExams(exams.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
  };

  const handlePrevPage = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      onPageChanged(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < pages) {
      setCurrentPage(currentPage + 1);
      onPageChanged(currentPage + 1);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value;

    if (txt.trim().length > 0) {
      setSearch(txt);
      // * find student name with search text
      const filteredStudents = exams.filter((exam) => {
        return exam.title.toLowerCase().includes(txt.toLowerCase());
      });

      setAllExams(filteredStudents);
      return;
    }

    setSearch("");
  };

  const handleDownloadExam = async (exam: Exam) => {
    // answer type MCQ
    const config = await getAuthHeaders();
    const response = await ApiRequest(
      Fetch_Exam_Questions,
      { examKey: exam.key, userKey: user?.key },
      "GET",
      config
    );

    let questions: Question[] = [];
    if (response.data && response.data.questions) {
      questions = response.data.questions;
    }
    generateSampleExamWordDocument(
      exam.title,
      questions,
      exam.language == "Hebrew"
    );
  };

  useEffect(() => {
    if (search === "") setAllExams(exams.slice(0, rowsPerPage));
  }, [exams, search]);

  // Handle copy exam
  const handleCopyExam = async (exam: Exam) => {
    const newExam: Exam = {
      ...exam,
      title: newTitle,
      createTime: new Date().getTime(),
    };

    if (user) {
      const headers = await getAuthHeaders();
      await ApiRequest(
        SHARE_EXAM_WITH_TEACHERS_UTL,
        { teachers: [user.uid], exam: newExam },
        "POST",
        headers
      );
    }

    window.location.reload();
  };

  function handleOnDeletePress(exam: Exam) {
    setNewExam(exam);

    setShowDeleteModel(!showModel);
  }

  function handleOnCopyPress(exam: Exam) {
    setNewExam(exam);
    setNewTitle(exam.title);
    setShowModel(!showModel);
  }
  function handleOnUpdatePress(exam: Exam) {
    setNewExam(exam);
    setShowUpdateModel(!showUpdateModel);
  }
  function handleOnSendPress(exam: Exam) {
    setNewExam(exam);
    setShowSendModel(!showSendModel);
  }
  function handleOnSharePress(exam: Exam) {
    setNewExam(exam);
    setShowShareModel(!showShareModel);
  }

  const handleViewExam = (exam: Exam) => {
    navigate(`/exams/ExamResults/${exam.key}`);
  };
  return (
    <div className="w-[20rem] md:w-full">
      {showModel && (
        // duplicate exam
        <EditTitle
          setShowModel={setShowModel}
          setNewTitle={setNewTitle}
          newTitle={newTitle}
          newExam={newExam}
          handleCopyExam={handleCopyExam}
        />
      )}

      {showDeleteModel && (
        <DeleteExam
          setShowDeleteModel={setShowDeleteModel}
          newExam={newExam}
          setAllExams={setAllExams}
          allExams={allExams}
        />
      )}
      {showUpdateModel && (
        <UpdateExam setShowUpdateModel={setShowUpdateModel} newExam={newExam} />
      )}
      {showSendModel && (
        <SendExam setShowSendModel={setShowSendModel} newExam={newExam} />
      )}
      {showShareModel && (
        <ShareExam setShowShareModel={setShowShareModel} newExam={newExam} />
      )}
      <div className="flex flex-row gap-4 my-4">
        <Form.Control
          className="search"
          style={{ maxWidth: 300 }}
          value={search}
          type="text"
          placeholder={`${t("Search")}...`}
          onChange={handleSearch}
        />
      </div>
      <Table
        striped
        hover
        bordered
        dir={isRTL() ? "rtl" : "ltr"}
        className="overflow-hidden "
      >
        <thead>
          <tr>
            <th>
              <div className="flex justify-center text-[#18608C] text-[0.8rem]">
                #
              </div>
            </th>
            <th>
              <div className="flex justify-center text-[#18608C] text-[0.8rem]">
                {t("Title")}
              </div>
            </th>

            <th>
              <div className="flex justify-center text-[#18608C] text-[0.8rem]">
                {t("Created")}
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allExams.map((exam, index) => (
            <tr key={index.toString()}>
              <td className="flex justify-center text-[0.8rem] items-center h-[15vh]">
                {startIndex + index + 1}
              </td>
              <td>
                <div className="flex justify-center items-center text-[0.8rem]  h-[12vh]">
                  {exam.title}
                </div>
              </td>
              <td>
                <div className="flex justify-center items-center text-[0.8rem]  h-[12vh]">
                  <ListExamTime examDate={exam.createTime} />
                </div>
              </td>
              <td>
                <div className="flex justify-center gap-2 text-[0.8rem] ">
                  <button
                    disabled={exam.examType !== "SampleExam"}
                    onClick={() => handleDownloadExam(exam)}
                    className={
                      exam.examType !== "SampleExam"
                        ? "bg-gray-200 flex justify-center items-center px-[12px] py-2 h-[12vh]"
                        : "bg-[#FFDCB1] flex justify-center items-center px-[12px] py-2 h-[12vh] cursor-pointer"
                    }
                  >
                    <DownloadIcon size={14} color="#8A7356" />
                  </button>
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3 bg-[#CCE1EB] px-4 py-[10px] cursor-pointer">
                      <div onClick={() => handleOnSendPress(exam)}>
                        <SendIcon size={14} color="#00699B" />
                      </div>
                      <div className="border-l-[1.5px] border-[#BFBCBC] cursor-pointer"></div>
                      <div onClick={() => handleOnCopyPress(exam)}>
                        <CopyIcon size={14} color="#00699B" />
                      </div>
                    </div>
                    <div className="flex gap-3 bg-[#E1F5D8] px-4 py-[10px] cursor-pointer">
                      <div onClick={() => handleOnUpdatePress(exam)}>
                        <EditIcon size={14} color="#5DAF3A" />
                      </div>
                      <div className="border-l-[1.5px] border-[#BFBCBC] "></div>
                      <div onClick={() => handleOnDeletePress(exam)}>
                        <DeleteIcon size={14} color="#5DAF3A" />
                      </div>
                    </div>
                  </div>

                  <div className="bg-[#FFDCB1] flex flex-col gap-3 px-[12px] py-2 h-[12vh]  cursor-pointer">
                    <div onClick={() => handleOnSharePress(exam)}>
                      <ShareIcon size={14} color="#8A7356" />
                    </div>
                    <div className="border-b-[1.5px] border-[#BFBCBC]"></div>
                    <div onClick={() => handleViewExam(exam)}>
                      <EyeIcon size={14} color="#8A7356" />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* pages */}
      <Pagination className="flex justify-end">
        <Pagination.Prev onClick={handlePrevPage} />

        <Pagination.Item>
          <span>
            {currentPage + 1} / {pages}
          </span>
        </Pagination.Item>

        <Pagination.Next onClick={handleNextPage} />
      </Pagination>
    </div>
  );
};

export default ListExamTable;

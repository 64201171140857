import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import rootReducer from "../reducers";

const persistConfig = {
  key: "MyLexisPlusStorage",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefualtMiddleware) =>
    getDefualtMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(thunk),
});

const persistor = persistStore(store);

export { persistor, store };

import React from "react";
import { useTranslation } from "react-i18next";

interface StatsProps {
  examData?: any;
}

const Stats = ({ examData }: StatsProps) => {
  const { t } = useTranslation();
  let participants: any[] = [];
  let average: number = 0;
  let max: number = 0;
  let min: number = 0;
  let countMax: number = 0;
  let countMin: number = 0;

  if (examData) {
    participants = examData?.filter((exam: any) => exam.marks !== undefined);
    let sum = 0;
    for (let exam of examData) {
      if (exam.marks !== undefined) {
        sum += exam.marks;
      }
    }
    average = Math.round(
      sum / examData.filter((_exam: any) => _exam.marks).length
    );

    const grades = examData?.filter((exam: any) => exam.marks !== undefined);

    max = Math.max(...grades.map((exam: any) => exam.marks));
    min = Math.min(...grades.map((exam: any) => exam.marks));
    examData.forEach((exam: any) => {
      if (exam.marks === max) {
        countMax++;
      }

      if (exam.marks === min) {
        countMin++;
      }
    });
  }

  return (
    <div style={{ minHeight: "calc(90vh + 100px)" }}>
      {examData && (
        <div className="my-4 md:flex md:justify-evenly md:gap-24 space-y-4">
          <div className="flex flex-col justify-center items-center bg-[#f9efe0] p-4 flex-1 ">
            <p>{`${t("Number of participants")}`} </p>
            {participants.length}
            <p>{`${t("Average")}`} </p>
            {Math.round(average)}
          </div>
          <div className="flex flex-col justify-center items-center bg-[#ccf3f5] p-4 flex-1 ">
            <p>{`${t("Highest degree")}`}</p>
            {Math.round(max)}
            <p>{`${t("Students")}`}</p>
            {countMax}
          </div>
          <div className="flex flex-col justify-center items-center bg-[#d1d6d9] p-4 flex-1">
            <p>{`${t("Lowest degree")}`} </p>
            {Math.round(min)}
            <p>{`${t("Students")}`} </p>
            {countMin}
          </div>{" "}
        </div>
      )}
    </div>
  );
};

export default Stats;

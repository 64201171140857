import React from "react";
import "./sideBar.css";
import { NavLink } from "react-router-dom";
import { Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icons } from "../../assets/styles";
import useActions from "../../hooks/useActions";

type Tab = {
  id: string;
  title: string;
  link: string;
  icon: ({ color, size }: { color?: string; size?: number }) => JSX.Element;
};

interface SidebarProps {
  visible: boolean;
  SideBarVisibilty: (visible: boolean) => void;
  tabs: Tab[];
}

const Sidebar = ({ visible, SideBarVisibilty, tabs }: SidebarProps) => {
  const { t } = useTranslation();
  const { AuthActions } = useActions();
  return (
    <nav className={`sidebar ${!visible ? "closeSideBar" : "openSideBar"}`}>
      <div className="top" onClick={() => SideBarVisibilty(!visible)}>
        {[1, 2, 3].map((value) => (
          <Stack
            key={value.toString()}
            direction="horizontal"
            gap={2}
            className={`mx-auto ${value != 1 ? "mt-2" : ""}`}
          >
            <div className="dot" />
            <div className="dot" />
            <div className="dot" />
          </Stack>
        ))}
      </div>

      <div className="down">
        {tabs.map((tab) => (
          <NavLink
            to={tab.link}
            className={({ isActive }) => (isActive ? "activeTab" : "")}
            key={tab.id}
            id="tab"
            onClick={() => SideBarVisibilty(false)}
          >
            {({ isActive }) => (
              <>
                {<tab.icon color={isActive ? "#2f4858" : "white"} />}

                <span
                  className={`${isActive ? "activeText" : ""} ${
                    visible ? "showtitle" : "hidetitle"
                  }`}
                >
                  {t(tab.title)}
                </span>
              </>
            )}
          </NavLink>
        ))}

        <div
          id="tab"
          className="logoutWrapper"
          style={{ position: "absolute", bottom: 50 }}
          onClick={AuthActions.logoutUser}
        >
          <Icons.LogoutIcon color="white" size={20} />
          {visible && <span>{t("Logout")}</span>}
        </div>
      </div>
    </nav>
  );
};
export default Sidebar;

import React, { useEffect } from 'react';
import { StudentsTable } from '../components';
import { Form, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../../../../utils/Constans';
import { useFetch, useTypedSelector, useActions } from '../../../../../hooks';
import { getAuthHeaders } from '../../../../../utils/Generic';
import { StudentTable } from '../../../../../types';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UPDATEGROUP_URL = `${BASE_URL}/UpdateGroup`;

const FETCH_GROUP_STUDENTS_URL = `${BASE_URL}/FetchGroupByKey`;
const FETCH_SCHOOL_STUDENTS_URL = `${BASE_URL}/FetchSchoolStudents`;

function CreateGroup() {
	const { key } = useParams();

	const navigate = useNavigate();
	const { t } = useTranslation();
	const [search, setSearch] = React.useState<string>('');
	const { user } = useTypedSelector(state => state.AuthReducer);
	const { MsgActions } = useActions();
	const [classes, setClasses] = React.useState<string[]>([]);
	const [selectedClassStudents, setSelectedClassStudents] = React.useState<
		StudentTable[]
	>([]);
	const [students, setStudents] = React.useState<StudentTable[]>([]);
	const [groupName, setGroupName] = React.useState<string>('');

	const [checkedStudents, setCheckedStudents] = React.useState<StudentTable[]>(
		[]
	);

	const [data, loading, error] = useFetch(
		FETCH_SCHOOL_STUDENTS_URL,
		{ schoolCode: user?.schoolCode },
		getAuthHeaders,
		'GET'
	);

	const [studentData, studentLoading, studentError] = useFetch(
		FETCH_GROUP_STUDENTS_URL,
		{ key },
		getAuthHeaders,
		'GET'
	);

	// React.useEffect(() => {
	// 	if (!loading && data && data.students && search === '' && studentData) {
	// 		const students: StudentTable[] = [];
	// 		let classesList: string[] = [];

	// 		for (let student of data.students) {
	// 			for (let groupStudent of studentData?.groups.students) {
	// 				const s: StudentTable = {
	// 					uid: student.uid,
	// 					schoolCode: student.schoolCode,
	// 					school: student.school,
	// 					city: student.city,
	// 					name: student.name,
	// 					class: student.class == '' ? '-' : student.class,
	// 					checked: groupStudent.uid === student.uid ? true : false,
	// 				};

	// 				if (s.class != '-' && !classesList.includes(s.class!)) {
	// 					classesList.push(s.class!);
	// 				}
	// 				setGroupName(studentData?.groups.title);
	// 				students.push(s);
	// 			}
	// 		}

	// 		setClasses(classesList.sort((a, b) => a.localeCompare(b)));
	// 		setStudents(students);
	// 		setCheckedStudents([...studentData.groups.students]);
	// 	}
	// }, [data, loading, error, search]);

	React.useEffect(() => {
		if (!loading && data && data.students && search === '' && studentData) {
			const students: StudentTable[] = [];
			let classesList: string[] = [];

			for (let student of data.students) {
				const groupStudent = studentData?.groups.students.find(
					(el: any) => el.uid === student.uid
				);

				const s: StudentTable = {
					uid: student.uid,
					schoolCode: student.schoolCode,
					school: student.school,
					city: student.city,
					name: student.name,
					class: student.class === '' ? '-' : student.class,
					checked: groupStudent ? true : false,
				};

				if (s.class !== '-' && !classesList.includes(s.class!)) {
					classesList.push(s.class!);
				}

				students.push(s);
			}

			setGroupName(studentData?.groups.title);
			setClasses(classesList.sort((a, b) => a.localeCompare(b)));
			setStudents(students);
			setCheckedStudents([...studentData.groups.students]);
		}
	}, [data, loading, error, search]);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const txt = e.target.value;

		if (txt.trim().length > 0) {
			setSearch(txt);
			// * find student name with search text
			const filteredStudents = students.filter(student => {
				return student.name.toLowerCase().includes(txt.toLowerCase());
			});

			setStudents(filteredStudents);
			return;
		}

		setSearch('');
	};

	// * Handling Add Group
	const handleAddGroup = async () => {
		const headers = await getAuthHeaders();

		if (checkedStudents.length < 2) {
			MsgActions.showMsg(
				'You have to add at least 2 students to the group!',
				'danger'
			);
			return;
		}
		if (!groupName) {
			MsgActions.showMsg('Group name is required!', 'danger');
			return;
		}

		await axios
			.put(
				UPDATEGROUP_URL,
				{
					key,
					title: groupName,
					students: checkedStudents,
					createdBy: user?.uid,
				},
				{
					headers: headers.headers,
				}
			)
			.then(res => {
				if (res.status === 200) {
					navigate('/groups');
				}
			})
			.catch(err => {
				MsgActions.showMsg(err.response.data.msg, 'danger');
			});
	};
	// * Handling Group Name
	const handleGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setGroupName(e.target.value);
	};

	// * Handling selected Class
	const handleSelectedClass = (e: React.ChangeEvent<HTMLSelectElement>) => {
		// filter students by class
		const filteredStudents = students.filter(student => {
			return student.class === e.target.value;
		});

		setSelectedClassStudents(filteredStudents);
	};

	return (
		<div className="container">
			{/* <Stack gap={4} direction="horizontal" className="my-4 "> */}
			<div className="flex flex-col gap-2 md:flex md:flex-row my-4 md:justify-between">
				<div className="flex flex-col gap-2 md:flex md:flex-row md:gap-4">
					<Form.Control
						className="search"
						style={{ maxWidth: 300 }}
						value={search}
						type="text"
						placeholder={`${t('Search')}...`}
						onChange={handleSearch}
					/>
					<Form.Select
						style={{ maxWidth: 150 }}
						onChange={e => handleSelectedClass(e)}
					>
						<option value={'all'}>{t('All')}</option>
						{classes.map(value => (
							<option key={value} value={value}>
								{value}
							</option>
						))}
					</Form.Select>

					<Form.Control
						style={{ maxWidth: 300 }}
						type="text"
						placeholder={`${t('Group Name')}`}
						value={groupName}
						onChange={handleGroupName}
					/>
				</div>

				<div className="md:flex md:justify-end">
					<button
						className="bg-[#3188C9] text-white px-2 py-1 md:px-4 md:py-2 rounded "
						type="submit"
						onClick={handleAddGroup}
					>
						Update Group
					</button>
				</div>
			</div>
			{/* </Stack> */}
			<StudentsTable
				students={
					selectedClassStudents.length > 0 ? selectedClassStudents : students
				}
				setCheckedStudents={setCheckedStudents}
				checkedStudents={checkedStudents}
			/>
		</div>
	);
}

export default CreateGroup;

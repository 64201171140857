import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import MsgReducer from "./MsgReducer";
import ExamReducer from "./ExamReducer";
const reducers = combineReducers({
  AuthReducer,
  MsgReducer,
  ExamReducer,
});
export default reducers;

export type RootState = ReturnType<typeof reducers>;

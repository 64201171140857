export enum ActionMsgType {
  SHOW_ERROR_MSG = "SHOW_ERROR_MSG",
  SHOW_SUCCESS_MSG = "SHOW_SUCCESS_MSG",
  SHOW_INFO_MSG = "SHOW_INFO_MSG",
  CLEAN_MSG = "CLEAN_MSG",
}

export enum ActionAuthType {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAIL = "LOGIN_FAIL",
  LOGOUT = "LOGOUT",
  FETCH_USER = "FETCH_USER",
  SYNC_USER = "SYNC_USER",
  SET_ERROR_MSG = "SET_ERROR_MSG",
}

export enum ActionExamType {
  // CURRENT_MATCHING_EXAM = 'CURRENT_MATCHING_EXAM',
  // CURRENT_EXAM = 'CURRENT_EXAM',
  STUDENT_EXAMS = "STUDENT_EXAMS",
  UPDATE_EXAM = "UPDATE_EXAM",
  SAVEING_EXAM = "SAVEING_EXAM",
  SAVE_EXAM_COMPLETE = "SAVE_EXAM_COMPLETE",
  SHARE_EXAM_COMPLETE = "SHARE_EXAM_COMPLETE",
  CLEAN_EXAM = "CLEAN_EXAM",
  UPDATE_COBE_EXAM = "UPDATE_COBE_EXAM",
  FETCH_EXAM_LIST = "FETCH_EXAM_LIST",
  FETCH_SINGLE_EXAM = "FETCH_SINGLE_EXAM",
  FETCH_WRITTEN_EXAM = "FETCH_WRITTEN_EXAM",
}

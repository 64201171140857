import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../../../utils/Constans';
import { useTypedSelector } from '../../../../../hooks';
import { getAuthHeaders } from '../../../../../utils/Generic';
import { HomeWork } from '../../../../../types';
import { useFetch } from '../../../../../hooks';
import { Spinner } from 'react-bootstrap';
import HomeWorkTable from './components/HomeworkTable';

const FETCH_HOMEWORK_URL = `${BASE_URL}/FetchHomeWork`;

const ListHomeWork = () => {
	const { user } = useTypedSelector(state => state.AuthReducer);
	const [homeworks, setHomeworks] = useState<HomeWork[]>([]);

	const [data, loading, error] = useFetch(
		FETCH_HOMEWORK_URL,
		{ teacherId: user?.key },
		getAuthHeaders,
		'GET'
	);

	useEffect(() => {
		if (!loading && data && data.homeWork) {
			setHomeworks(data.homeWork);
		}
	}, [data, loading, error]);

	return (
		<div>
			{loading && (
				<div className="flex justify-center items-center">
					<Spinner animation="border" />
				</div>
			)}

			{!loading && !data && (
				<div className="flex justify-center items-center">
					<h1>No homeworks</h1>
				</div>
			)}

			{!loading && data && data.homeWork && (
				<HomeWorkTable homeworks={homeworks} />
			)}
		</div>
	);
};

export default ListHomeWork;

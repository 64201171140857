import React, { useState, useEffect } from "react";
import "./StepOne.css";
import { Button, Card, Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4 } from "uuid";
import { Switch } from "../../../../../../../components";
import {
  BASE_URL,
  Languages,
  SCHOOL_LEVELS,
} from "../../../../../../../utils/Constans";
import {
  useActions,
  useFetch,
  useTypedSelector,
} from "../../../../../../../hooks";
import {
  CobeExam,
  CobeExamQuestion,
  CobeExamReadyToUse,
  ExamTypesEnum,
  LanguageType,
  LevelType,
  Question,
} from "../../../../../../../types";
import { isRTL } from "../../../../../../../locales";
import { ATTACH_IMAGE } from "../../../../../../../assets/images";
import { ATTACH_VIDEO } from "../../../../../../../assets/images";
import { getAuthHeaders } from "../../../../../../../utils/Generic";

const EXAM_READY_TO_USE_URL = `${BASE_URL}//ExamsReadyToUse`;

interface StepOneProps {
  isValid: (status: boolean) => void;
  singleExam?: CobeExam;
  handleSelectExamReadyToUseQuestions: (questions: Question[]) => void;
}

type AttachedFileTypes = "video" | "image" | "";

function StepOne({
  isValid,
  singleExam,
  handleSelectExamReadyToUseQuestions,
}: StepOneProps) {
  const { ExamActions } = useActions();
  const [groupsTitle, setGroupsTitle] = React.useState<string[]>([]);
  const [readyToUseExams, setReadyToUseExams] = React.useState<
    Map<string, CobeExamReadyToUse[]>
  >(new Map());
  const form = React.useRef<HTMLFormElement>(null);
  const { exam } = useTypedSelector((state) => state.ExamReducer);
  const { t } = useTranslation();
  const [validate, setValidate] = React.useState(false);
  const [examLanugage, setExamLanguage] = React.useState<LanguageType>(
    exam?.language || "English"
  );
  const [examLevel, setExamLevel] = React.useState<LevelType>(
    exam?.level || "Level1"
  );
  const [examTitle, setExamTitle] = React.useState(exam?.title || "");
  const [examDeadLine, setExamDeadLine] = React.useState<string | undefined>(
    exam?.submitionDate || ""
  );
  const [videoUrl, setVideoUrl] = React.useState(
    (exam as CobeExam)?.videoUrl || ""
  );
  const [image, setImage] = React.useState<{
    data: string;
    preview: string;
  }>({ data: "", preview: (exam as CobeExam)?.imageUrl || "" });

  const [showVideo, setShowVideo] = React.useState(false);
  const [attachedFileType, setAttachedFileType] =
    useState<AttachedFileTypes>("");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [data, loading, error, setParams] = useFetch(
    EXAM_READY_TO_USE_URL,
    { language: "English", examType: "CobeExam" },
    getAuthHeaders,
    "GET"
  );

  React.useEffect(() => {
    if (!loading && data) {
      const groups: Map<string, CobeExamReadyToUse[]> = new Map();

      for (let ex of data.exams) {
        if (groups.get(ex.group)) {
          groups.set(
            ex.group,
            [...groups.get(ex.group)!, ex].sort((a, b) => a.order - b.order)
          );
        } else {
          groups.set(ex.group, [ex]);
        }
      }
      setGroupsTitle(Array.from(groups.keys()));
      setReadyToUseExams(groups);
    } else {
      console.log(error);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setExamTitle(singleExam ? singleExam.title : exam?.title || "");
    setExamLevel(singleExam ? singleExam.level : examLevel);
    setExamDeadLine(
      singleExam ? singleExam.submitionDate : exam?.submitionDate || ""
    );
    setExamLanguage(singleExam ? singleExam.language : examLanugage);
    const _vidio_url = singleExam?.videoUrl
      ? `https://www.youtube.com/watch?v=${singleExam.videoUrl
          .split("/")
          .pop()}`
      : "";
    setVideoUrl(_vidio_url);
    setImage({ data: "", preview: (singleExam as CobeExam)?.imageUrl || "" });
    setAttachedFileType(singleExam?.videoUrl ? "video" : "");
  }, [singleExam]);

  React.useEffect(() => {
    if (form.current?.checkValidity() == true) {
      const _videoUrl =
        attachedFileType == "video" ? generateVidioLink(videoUrl) : "";

      const _exam: CobeExam = {
        title: examTitle,
        language: examLanugage,
        level: examLevel,
        createTime: new Date().getTime(),
        examType: ExamTypesEnum.CobeExam,
        submitionDate: examDeadLine,
        videoUrl: _videoUrl,
        imageUrl: image.preview,
        attachedFile: image.data,
        questions: (exam as CobeExam)?.questions || [],
      };
      ExamActions.updateExam(_exam);
    }
    setValidate(true);
    isValid(form.current?.checkValidity() || false);
  }, [examLanugage, examLevel, examTitle, image, videoUrl, examDeadLine]);

  const handleExamLevelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const level = e.target.value as LevelType;
    setExamLevel(level);
  };

  const handleExamLanugageChange = (value: string) => {
    const language = value as LanguageType;
    setExamLanguage(language);
  };

  const handleSelectFileType = (type: AttachedFileTypes) => {
    if (attachedFileType == type) {
      setAttachedFileType("");
      setVideoUrl("");
      setImage({ data: "", preview: "" });
      return;
    }

    setAttachedFileType(type);
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files![0]) {
      let file = files![0];
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        const base64String = fileReader.result;
        if (base64String) {
          setImage({
            preview: URL.createObjectURL(file),
            data: base64String.toString(),
          });
        }
      };
    } else {
      setImage({ data: "", preview: "" });
    }
  };

  const generateVidioLink = (videoLink: string) => {
    let id = "";
    if (videoLink.toLowerCase().includes("youtube")) {
      id = videoLink.split("?v=")[1];
      if (videoLink.split("?v=")[1].includes("&")) {
        id = videoLink.split("?v=")[1].split("&")[0];
      }
    } else if (videoLink.includes("youtu.be")) {
      id = videoLink.split("/").pop()!;
    }
    return "https://www.youtube.com/embed/" + id;
  };

  const handleUseExam = (exam: CobeExamReadyToUse) => {
    const _videoUrl =
      attachedFileType == "video" && exam.videoUrl
        ? generateVidioLink(exam.videoUrl)
        : "";

    const questions = [];
    for (let question of exam.questions) {
      let _newQuestion: CobeExamQuestion = {
        question: question,
        order: new Date().getTime(),
        answerType: "Record",
        key: uuidV4(),
      };

      questions.push(_newQuestion);
    }

    const _exam: CobeExam = {
      title: exam.title,
      language: exam.language,
      level: "Level3",
      createTime: new Date().getTime(),
      examType: ExamTypesEnum.CobeExam,
      submitionDate: examDeadLine,
      videoUrl: _videoUrl,
      imageUrl: image.preview,
      attachedFile: image.data,
    };

    setExamTitle(exam.title);
    setExamLevel("Level3");
    setExamLanguage(exam.language);
    setVideoUrl(exam.videoUrl);
    setAttachedFileType(exam?.videoUrl ? "video" : "");
    setModalVisible(false);
    ExamActions.updateExam(_exam);
    handleSelectExamReadyToUseQuestions(questions);
  };

  return (
    <div className="StepOneCobe">
      <Modal
        dir={isRTL() ? "rtl" : "ltr"}
        className="StepThreeCobeModal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {groupsTitle.map((title) => (
            <div className="mb-2" key={title}>
              <h1>{title}</h1>
              <ul>
                {readyToUseExams.get(title)?.map((exam) => (
                  <li key={exam.order}>
                    <Card className="mb-2 p-2">
                      <h3>{exam.title}</h3>
                      <ul>
                        {exam.questions.map((q, index) => (
                          <li key={q}>
                            {`${index + 1}) `}
                            {q}
                          </li>
                        ))}
                      </ul>
                      {exam.videoUrl && (
                        <iframe
                          className="mt-2"
                          width="420"
                          height="345"
                          src={generateVidioLink(exam.videoUrl)}
                        />
                      )}
                      <Button
                        onClick={() => handleUseExam(exam)}
                        className="w-25 mt-2 btn-success"
                      >
                        Use Exam
                      </Button>
                    </Card>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Modal.Body>
      </Modal>
      <Form ref={form} noValidate validated={validate}>
        <Card dir={isRTL() ? "rtl" : "ltr"}>
          <Card.Header>
            <h2>{t("The First Step")}</h2>
          </Card.Header>
          <Card.Body>
            {examLanugage == "English" && (
              <Button
                onClick={() => setModalVisible(true)}
                className="mb-2 btn-success"
              >
                Exams ready to use
              </Button>
            )}

            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam title")}</Form.Label>
                <Form.Control
                  value={examTitle}
                  required
                  type="text"
                  placeholder={`${t("Enter exam title")}`}
                  onChange={(e) => setExamTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam Is Due")}</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={examDeadLine}
                  onChange={(e) => setExamDeadLine(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("Exam Level")}</Form.Label>
                <Form.Select
                  required
                  value={examLevel}
                  onChange={handleExamLevelChange}
                >
                  {SCHOOL_LEVELS.map((level) => (
                    <option key={level.value} value={level.value}>
                      {t(level.label)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} className="mb-3">
                <Form.Label>{t("t21")}</Form.Label>
                <Switch
                  initValue={exam?.language || "English"}
                  values={Languages}
                  onSelect={handleExamLanugageChange}
                />
              </Form.Group>
            </Row>

            <Row className="inputRow">
              <Form.Group as={Col} className="mb-3">
                <Stack direction="horizontal">
                  <div
                    style={{
                      backgroundColor:
                        attachedFileType == "video"
                          ? "var(--light-blue)"
                          : "var(--light-orange)",
                    }}
                    className="attachFilesWrapper"
                    onClick={() => handleSelectFileType("video")}
                  >
                    <img src={ATTACH_VIDEO} alt="video" />
                    <p>{t("Attach a video link")}</p>
                  </div>
                  <div
                    style={{
                      backgroundColor:
                        attachedFileType == "image"
                          ? "var(--light-blue)"
                          : "var(--light-orange)",
                    }}
                    className="attachFilesWrapper"
                    onClick={() => handleSelectFileType("image")}
                  >
                    <img src={ATTACH_IMAGE} alt="image" />
                    <p>{t("Attach a picture")}</p>
                  </div>
                </Stack>
                {attachedFileType == "image" && (
                  <div className="mt-2" style={{ maxWidth: 400 }}>
                    <Form.Control
                      required
                      type="file"
                      dir="ltr"
                      onChange={handleFileSelect}
                    />
                    {image.preview && (
                      <div className="attachedImageWrapper">
                        <img src={image.preview} alt="examImage" />
                      </div>
                    )}
                  </div>
                )}

                {attachedFileType == "video" && (
                  <div className="mt-2" style={{ maxWidth: 400 }}>
                    <Stack direction="horizontal" gap={2}>
                      <Form.Control
                        required
                        type="text"
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                        placeholder="https://www.youtube.com/watch?v=8MeDkBOtAVw"
                      />
                      <Button onClick={() => setShowVideo(!showVideo)}>
                        {showVideo ? "Hide" : "Show"}
                      </Button>
                    </Stack>
                    {showVideo && (
                      <iframe
                        className="mt-2"
                        width="420"
                        height="345"
                        src={generateVidioLink(videoUrl)}
                      />
                    )}
                  </div>
                )}
              </Form.Group>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
}

export default StepOne;

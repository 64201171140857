import React from "react";
import AuthNavigation from "./AuthNavigation";
import TeacherNavigation from "./TeacherNavigation";
import useTypedSelector from "../hooks/useTypedSelector";
import StudentNavigation from "./StudentNavigation";
import useActions from "../hooks/useActions";

const MainNavigation = () => {
  const { user, sync } = useTypedSelector((state) => state.AuthReducer);
  const { AuthActions } = useActions();
  React.useEffect(() => {
    if (user) {
      AuthActions.syncUser(user.uid);
    }
  }, []);

  if (sync) {
    return <div>loading...</div>;
  }

  if (!user) {
    return <AuthNavigation />;
  }

  if (user.accountType === "Teacher") return <TeacherNavigation />;

  return <StudentNavigation />;
};

export default MainNavigation;

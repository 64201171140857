import React from "react";
import "./TeacherNavigation.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { Header, HelpVideo, Sidebar, TabsNav } from "../components";
import {
  Languages,
  TEACHER_TABS as TABS,
  NAV_TABS_TEACHER as NAV_TABS,
  NAV_TABS_HOMEWORK,
} from "../utils/Constans";

import {
  CustomExam,
  SampleExam,
  WrittenExam,
  ListExam,
  CompetitionScreen,
  DashboardScreen,
} from "../screens/Home";
import ViewExam from "../screens/Home/TeacherScreens/ExamScreens/ViewExam/ViewExam";
import Groups from "../screens/Home/TeacherScreens/GroupScreens/Groups/Groups";
import CreateGroup from "../screens/Home/TeacherScreens/GroupScreens/CreateGroup/CreateGroup";
import EditGroup from "../screens/Home/TeacherScreens/GroupScreens/EditGroup/EditGroup";
import CreateHomeWork from "../screens/Home/TeacherScreens/HomeWrokScreens/CreateHomeWork/CreateHomeWork";
import ListHomeWork from "../screens/Home/TeacherScreens/HomeWrokScreens/ListHomeWork/ListHomeWork";
import ProfileSettings from "../screens/Home/TeacherScreens/ProfileSettingsScreens/ProfileSettings";
import SampleExamResult from "../screens/Home/TeacherScreens/ExamScreens/ViewExam/components/Results/Results";
import WrittenResult from "../screens/Home/TeacherScreens/ExamScreens/ViewExam/components/Results/WrittenResult/WrittenResult";
import CobeResults from "../screens/Home/TeacherScreens/ExamScreens/ViewExam/components/Results/Cobe/CobeResults";

const TeacherNavigation = () => {
  const [visible, setVisible] = React.useState(false);
  const { i18n } = useTranslation();
  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value;
    i18n.changeLanguage(language.slice(0, 2).toLocaleLowerCase());
  };

  return (
    <div className="TeacherNavigation">
      <div className={!visible ? "sidebarWrapper" : "openSidebar"}>
        <Sidebar
          visible={visible}
          SideBarVisibilty={(visible) => setVisible(visible)}
          tabs={TABS}
        />
      </div>
      <div className="wrapper">
        <Header onPress={() => setVisible(!visible)}>
          <HelpVideo titleVisible={false} />
          <Form.Select
            className="languageSelect"
            aria-label="English"
            defaultValue={"English"}
            onChange={handleChangeLanguage}
          >
            {Languages.map((language) => (
              <option key={language.value} value={language.value}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Header>

        {/* router */}
        <Routes>
          <Route path="/home" element={<DashboardScreen />} />
          <Route path="/exams" element={<TabsNav tabs={NAV_TABS} />}>
            <Route index element={<SampleExam />} />
            <Route path="sample/:key?" element={<SampleExam />} />
            <Route path="custom/:key?" element={<CustomExam />} />
            <Route path="topic/:key?" element={<WrittenExam />} />
            <Route path="list/:key?" element={<ListExam />} />
            <Route path="ExamResults/:key" element={<ViewExam />} />
            <Route
              path="SampleExamResult/:key/:uid"
              element={<SampleExamResult />}
            />
            <Route path="TopicExam/:key/:uid" element={<WrittenResult />} />
            <Route path="CobeExam/:key/:uid" element={<CobeResults />} />
          </Route>

          <Route
            path="/homework"
            element={<TabsNav tabs={NAV_TABS_HOMEWORK} />}
          >
            <Route index element={<CreateHomeWork />} />
            <Route path="createHomeWork" element={<CreateHomeWork />} />
            <Route path="homeworkList" element={<ListHomeWork />} />
          </Route>

          <Route path="/competition" element={<CompetitionScreen />} />
          <Route path="/groups">
            <Route index element={<Groups />} />
            <Route path="createGroup" element={<CreateGroup />} />
            <Route path="editGroup/:key" element={<EditGroup />} />
          </Route>
          <Route path="/settings" element={<ProfileSettings />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Routes>
      </div>
    </div>
  );
};

export default TeacherNavigation;

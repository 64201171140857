import React from "react";
import "./footer.css";
import {
  FacebookImage,
  AppleStore,
  GoogleStore,
} from "../../../../assets/images";
import { Link } from "react-router-dom";
import { androidStore, appleStore } from "../../../../utils/Constans";
function Footer() {
  return (
    <div className="Footer">
      <div>
        <h2>SOCIAL MEDIA</h2>
        <h3>FOLLOW US</h3>
        <div className="socialWrapper">
          <img src={FacebookImage} alt="FacebookImage" />
        </div>
      </div>

      <div>
        <h2>S I T E</h2>
        <Link to={""}>Privacy Police</Link>
      </div>

      <div>
        <h2>MY LEXIS PLUS</h2>
        <h3>Download Now</h3>
        <div>
          <a href={androidStore} target="_blank" rel="noreferrer">
            <img src={GoogleStore} alt="android" />
          </a>
          <a href={appleStore} target="_blank" rel="noreferrer">
            <img src={AppleStore} alt="apple" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

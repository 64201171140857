import React from 'react';
import './TabsNav.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Form, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavTab } from '../../types';

interface TabsNavProps {
	tabs: NavTab[];
}

function TabsNav({ tabs }: TabsNavProps) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	const [selectedKey, setSelectedKey] = React.useState<string | null>(
		tabs[0].key
	);

	React.useEffect(() => {
		// console.log(tabs);
		// console.log(location.pathname);
		const _selectedTab = tabs.find(tab =>
			location.pathname.startsWith(tab.path)
		);
		if (_selectedTab) setSelectedKey(_selectedTab.key);
	}, [location]);

	return (
		<div className="TabsNav">
			<Nav className="tabs" variant="tabs" onSelect={e => setSelectedKey(e)}>
				{tabs.map((tab, index) => (
					<Nav.Item key={index.toString()}>
						<Nav.Link
							style={{
								color:
									selectedKey == tab.key
										? 'var(--darkBlue)'
										: 'var(--lightText)',
							}}
							eventKey={tab.key}
							active={tab.key == selectedKey}
							onClick={() => navigate(tab.path)}
						>
							{t(tab.title)}
						</Nav.Link>
					</Nav.Item>
				))}
			</Nav>

			<Form.Select
				className="Select"
				aria-label={tabs[0].path}
				defaultValue={tabs[0].path}
				onChange={e => navigate(e.target.value)}
			>
				{tabs.map(tab => (
					<option key={tab.key} value={tab.path}>
						{t(tab.title)}
					</option>
				))}
			</Form.Select>
			<Outlet />
		</div>
	);
}

export default TabsNav;

import React, { useState, useEffect } from 'react';
import ListExamTime from '../../../TeacherScreens/ExamScreens/ListExam/Components/ListExamTime';
import { EyeIcon } from '../../../../../assets/icons';
import { useFetch } from '../../../../../hooks';
import { BASE_URL } from '../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../utils/Generic';
import { useTypedSelector } from '../../../../../hooks';
import { useNavigate } from 'react-router-dom';

interface ExamListTableProps {
	exam: any;
	handleExamIntroduction: any;
}

const ExamListTable = ({
	exam,
	handleExamIntroduction,
}: ExamListTableProps) => {
	const { user } = useTypedSelector(state => state.AuthReducer);
	const navigate = useNavigate();

	const [studentExam, setStudentExam] = useState<any>();
	const answerType = exam?.questions?.[0]?.answerType;

	const [data, loading, error] = useFetch(
		`${BASE_URL}/GetStudentAnswer`,
		{ examId: exam?.key, studentId: user?.key },
		getAuthHeaders,
		'GET'
	);

	useEffect(() => {
		if (!loading && data && data.answer) {
			setStudentExam(data.answer);
		}
	}, [data, loading, error]);
	const handleShowExam = () => {
		if (exam.examType === 'WrittenProduction') {
			navigate(`/student/ViewWrittenResults/${exam?.key}/${user?.uid}`);
		} else if (exam.examType === 'CobeExam') {
			navigate(`/student/ViewCobeResults/${exam?.key}/${user?.uid}`);
		} else {
			navigate(`/student/SampleExamResults/${exam?.key}/${user?.uid}`);
		}
	};

	return (
		<>
			<td>
				<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
					{exam.title}
				</div>
			</td>
			<td>
				<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
					<ListExamTime examDate={exam.createTime} />
				</div>
			</td>
			<td>
				<div className="flex justify-center items-center text-[0.8rem] h-[8vh]">
					<p
						className={
							exam.status! === 'In Progress'
								? 'bg-[#FCCCC6] p-1 '
								: exam.status === 'Not Started'
								? 'bg-[#FCE9C6] p-2 '
								: 'bg-[#C9E4FF] p-1  '
						}
					>
						{exam.status!}
					</p>
				</div>
			</td>

			<td>
				<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
					{studentExam && studentExam.submissionTime !== null && (
						<ListExamTime examDate={studentExam.submissionTime} />
					)}
					{(!studentExam || !studentExam.submissionTime) && '-'}
				</div>
			</td>
			<td>
				<div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
					{exam.status === 'Not Started' ? (
						<button
							className="bg-[#FCE9C6] text-[#FEC048] text-bold text-[1rem] md:px-4 md:py-2 px-1 py-1 rounded font-bold"
							onClick={() => handleExamIntroduction(exam)}
						>
							Start Exam
						</button>
					) : (
						<button
							onClick={handleShowExam}
							disabled={studentExam && !studentExam.share}
							className="cursor-pointer"
						>
							<EyeIcon size={25} color="#9BDE7E" />
						</button>
					)}
				</div>
			</td>
		</>
	);
};

export default ExamListTable;

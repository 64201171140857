import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../../../utils/Generic';
import { useTranslation } from 'react-i18next';

const AddComment_URL = `${BASE_URL}/AddCommentToStudentExamAnswer`;
interface Comment {
	comment: string;
	index: number;
}
interface ViewGroupProps {
	setShowModal: (newType: boolean) => void;
	examId: string | undefined;
	studentId: string;
	questionId: string;
	teacherId: string | undefined;
	comments: Array<{ comment: string; index: number }>; // Define comments as an array of objects with a 'comment' property
	setComments: (newComments: Array<{ comment: string; index: number }>) => void; // Updated type
	index: number;
	addCommentToState: (newComment: Comment) => void;
}

const CommentModal = ({
	setShowModal,
	examId,
	studentId,
	questionId,
	teacherId,
	comments,
	setComments,
	index,
	addCommentToState,
}: ViewGroupProps) => {
	const { t } = useTranslation();
	const [commentText, setCommentText] = useState<string>('');
	const handleAddComment = async () => {
		const headers = await getAuthHeaders();

		axios
			.put(
				AddComment_URL,
				{
					examId,
					studentId,
					questionId,
					comment: commentText,
					teacherId,
				},
				{ headers: headers.headers }
			)
			.then(res => {
				setShowModal(false);
				const newComment: Comment = { comment: commentText, index };
				addCommentToState(newComment);
			})
			.catch(err => {
				console.log(err);
			});
	};
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowModal(false)}
				></Modal.Header>
				<Modal.Body className="max-w-[32rem]  overflow-y-scroll">
					<div className="flex gap-2">
						<h6 className="font-normal">{t('Comment:')}</h6>
						<textarea
							name=""
							id=""
							cols={50}
							rows={5}
							className="border p-2"
							placeholder="write a comment..."
							onChange={e => setCommentText(e.target.value)}
						></textarea>
					</div>
					<button
						className="bg-[#3188C9] px-3 py-1 text-white mt-2 rounded font-semibold"
						disabled={commentText === ''}
						onClick={() => {
							handleAddComment();
							setShowModal(false);
						}}
					>
						{t('Add comment')}
					</button>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default CommentModal;

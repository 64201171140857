import React from "react";
import { useTypedSelector } from "../../../../../hooks";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../utils/Generic";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const UpdateStatus_URL = `${BASE_URL}/UpdateExamStatus`;

const ExamIntroduction = () => {
  const { key } = useParams();
  const { t } = useTranslation();
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);
  const currentExam = studentExams?.find((exam) => exam?.key === key);
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const navigate = useNavigate();

  const handleStartExam = async () => {
    const headers = await getAuthHeaders();
    // const teacherId = currentExam?.key?.split('/')[0];
    const examId = currentExam?.key;
    if (currentExam.examType === "CobeExam") {
      navigate(`/student/CustomExamQuestions/${currentExam?.key}`);
    } else {
      axios
        .put(
          UpdateStatus_URL,
          {
            userId: user?.key,
            examId,
            status: "Complete",
          },
          { headers: headers.headers }
        )
        .then((res) => {
          if (currentExam.examType === "SampleExam") {
            navigate(`/student/SampleExamQuestions/${currentExam?.key}`);
          } else if (currentExam.examType === "WrittenProduction") {
            navigate(`/student/TopicExam/${currentExam?.key}`);
          } else {
            alert("Exam type not supported");
            axios.put(
              UpdateStatus_URL,
              {
                userId: user?.key,
                examId,
                status: "Not Started",
              },
              { headers: headers.headers }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <section className="bg-[#FAFAFA] w-[20rem] md:w-full mx-4 md:mx-0">
      <div className="flex  justify-center items-center h-[70vh] ">
        <div className="flex flex-col shadow-md px-10 py-5">
          <h3 className="text-[#18608C] font-bold text-center">
            {currentExam?.title}
          </h3>
          <h4 className="text-[#FF6868] font-semibold md:text-left mt-2">
            {t("Attention before starting the exam")}
          </h4>
          <p className="md:text-[1.3rem]">
            {t("The questions page can only be entered once")}
            <br />
            {t(
              "The results of the solution will be sent directly to the teacher"
            )}
          </p>
          <p className="text-[#67C06F] md:text-[1.4rem]">
            {t("With great success")}
          </p>
          <button
            className="bg-[#3188C9] text-white px-4 py-2 md:mx-[12rem] font-semibold rounded mt-4"
            onClick={handleStartExam}
          >
            {t("start exam")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ExamIntroduction;

import React from "react";
import "./StepCard.css";
import { Stack } from "react-bootstrap";
import { CORENR_GREEN } from "../../assets/images";

interface StepCardProps {
  image: string;
  title: string;
  subTtile: string;
}

function StepCard({ image, title, subTtile }: StepCardProps) {
  return (
    <Stack direction="horizontal" className="StepCard">
      <div className="line" />
      <div>
        <Stack direction="vertical" gap={2} className="titleWrapper">
          <h2>{title}</h2>
          <p>{subTtile}</p>
        </Stack>
      </div>
      <img src={image} alt="step" className="stepImage" />
      <img src={CORENR_GREEN} alt="corener green" className="stepCornerImg" />
    </Stack>
  );
}

export default StepCard;

import { Icons } from "../assets/styles";
import { LabelsType, NavTab } from "../types";

const DEV_MODE = false;

let BASE_URL =
  "https://us-central1-mylexisplus-2f66b.cloudfunctions.net/app/api"; // product

if (DEV_MODE) {
  BASE_URL = `http://127.0.0.1:5001/mylexisplus-2f66b/us-central1/app/api`; // debug
}

const KEY = "MY_LEXIS_PLUS_APP_2019";

const VIDEOS = [
  {
    url: "https://drive.google.com/file/d/1FBDyL5KEGiQrCdOz-7isnwnfIBvr4MSm/preview",
    title: "Application and website",
    desc: "",
  },
  {
    url: "https://drive.google.com/file/d/1vJ0_sXsSfu53U-kEnAyO3fTPx-6DYi3e/preview",
    title: "Competition",
    desc: "",
  },
  {
    url: "https://drive.google.com/file/d/1we_CGEKf7XGsDwMivwKuj8kVw1yXZUV8/preview",
    title: "Homework",
    desc: "",
  },
  {
    url: "https://drive.google.com/file/d/1ZTDUUQqyRyP0J-LXzTZCud8RR9RxkrlE/preview",
    title: "Exams",
    desc: "",
  },
];

const androidStore =
  "https://play.google.com/store/apps/details?id=com.tareqyassin.MYLIXISPLUS&hl=en&gl=US";
const appleStore = "https://apps.apple.com/il/app/my-lexis-plus/id1532255919";

const Languages: LabelsType[] = [
  { value: "English", label: "English" },
  { value: "Hebrew", label: "עברית" },
];

const TEACHER_TABS = [
  { id: "1", title: "Home", link: "/home", icon: Icons.HomeIcon },
  { id: "2", title: "Exams", link: "/exams", icon: Icons.ExamIcon },
  {
    id: "3",
    title: "Homework",
    link: "/homework",
    icon: Icons.HomeworkIcon,
  },
  {
    id: "4",
    title: "Students",
    link: "/competition",
    icon: Icons.CompetitonIcon,
  },
  {
    id: "5",
    title: "Groups",
    link: "/groups",
    icon: Icons.GroupIcon,
  },
  {
    id: "6",
    title: "Settings",
    link: "/settings",
    icon: Icons.SettingsIcon,
  },
];

const STUDENT_TABS = [
  // { id: "1", title: "Home", link: "/student/home", icon: Icons.HomeIcon },
  { id: "2", title: "Exams", link: "/student/exams", icon: Icons.ExamIcon },
];

const NAV_TABS_TEACHER: NavTab[] = [
  { key: "sample", path: "/exams/sample", title: "Sample Exam" },
  { key: "custom", path: "/exams/custom", title: "Create Exam" },
  { key: "topic", path: "/exams/topic", title: "topics_exam" },
  { key: "list", path: "/exams/list", title: "List Of Exams" },
];
const NAV_TABS_STUDENT: NavTab[] = [
  { key: "english", path: "/student/exams/english", title: "English Exams" },
  { key: "hebrew", path: "/student/exams/hebrew", title: "Hebrew Exam" },
];
const NAV_TABS_HOMEWORK: NavTab[] = [
  {
    key: "create",
    path: "/homework/createHomework",
    title: "Create Homework",
  },
  {
    key: "list",
    path: "/homework/homeworkList",
    title: "Homework List",
  },
];

const SCHOOL_LEVELS: LabelsType[] = [
  { value: "Level1", label: "level1" },
  { value: "Level2", label: "level2" },
  { value: "Level3", label: "level3" },
];

const MIN_EXAM_QUESTIONS_NUM = 2;

const TOPICS_OPTIONS = {
  English: [
    { label: "Module C", value: "Module C" },
    { label: "Module G Opinion", value: "Module G Opinion" },
    { label: "Module G Descriptive", value: "Module G Descriptive" },
    { label: "Create New Topic", value: "create_topic" },
  ],
  Hebrew: [{ label: "מטלת כתיבה חדשה", value: "create_topic" }],
};

const HOMEWORK_TYPES = {
  English: {
    level1: [
      {
        title: "Vocabulary",
        name: "word",
        core: "",
        location: "Languages/English/Level1",
      },
      {
        title: "Definitions Band I",
        name: "definitions",
        core: "band1",
        location: "HomeWorks/English/type0",
      },
    ],
    level2: [
      {
        title: "Vocabulary",
        name: "word",
        core: "",
        location: "Languages/English/Level2",
      },
      {
        title: "Definitions Band I",
        name: "definitions",
        core: "band1",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions Band II",
        name: "definitions",
        core: "band2",
        location: "HomeWorks/English/type0",
      },
    ],
    level3: [
      {
        title: "Vocabulary",
        name: "word",
        core: "",
        location: "Languages/English/Level3",
      },
      {
        title: "Definitions Pilot-E",
        name: "definitions",
        core: "pilot-e",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions Band I",
        name: "definitions",
        core: "band1",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions Band II",
        name: "definitions",
        core: "band2",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions List-A",
        name: "definitions",
        core: "lista",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions List-B",
        name: "definitions",
        core: "listb",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions List-C",
        name: "definitions",
        core: "listc",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Definitions List-D",
        name: "definitions",
        core: "listd",
        location: "HomeWorks/English/type0",
      },
      {
        title: "Abridged List C",
        name: "definitions",
        core: "AbridgedListC",
        location: "HomeWorks/English/type0",
      },
    ],
  },
  Hebrew: {
    level1: [
      {
        title: "מילים ",
        name: "מילים",
        core: "",
        location: "Languages/Hebrew/Level1",
      },
    ],
    level2: [
      {
        title: "מילים ",
        name: "מילים",
        core: "",
        location: "Languages/Hebrew/Level2",
      },
    ],
    level3: [
      {
        title: "מילים ",
        name: "מילים",
        core: "",
        location: "Languages/Hebrew/Level3",
      },
    ],
  },
};

enum QuestionsTypes {
  VocabularyPracticeQuestions = "Vocabulary Practice",
  SentenceCompletionQuestions = "Sentence Completion",
  DefinitionsQuestions = "Definitions",
  MatchingQuestions = "Matching",
  HebrewVocabularyPracticeQuestions = "פירושים",
  SynonymsQuestions = "Synonyms",
}

export {
  androidStore,
  appleStore,
  VIDEOS,
  BASE_URL,
  Languages,
  NAV_TABS_TEACHER,
  TEACHER_TABS,
  SCHOOL_LEVELS,
  MIN_EXAM_QUESTIONS_NUM,
  TOPICS_OPTIONS,
  NAV_TABS_HOMEWORK,
  HOMEWORK_TYPES,
  KEY,
  STUDENT_TABS,
  NAV_TABS_STUDENT,
  QuestionsTypes,
};

import React from "react";
import "./StepTwo.css";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useActions,
  useFetch,
  useTypedSelector,
} from "../../../../../../../hooks";
import {
  BASE_URL,
  MIN_EXAM_QUESTIONS_NUM,
  QuestionsTypes,
} from "../../../../../../../utils/Constans";
import {
  Question,
  SampleExam,
  SampleExamExercise,
  SampleExamQuestion,
} from "../../../../../../../types";
import { getAuthHeaders } from "../../../../../../../utils/Generic";
import { isRTL } from "../../../../../../../locales";
import SampleExamQuestionsTable from "../SampleExamQuestionsTable/SampleExamQuestionsTable";
import { YesNoModal } from "../../../../../../../components";

const FetchSampleExamExcercise = `${BASE_URL}/FetchSampleExamExcercisesQuestions`;

type QuestionsData = [SampleExamExercise, SampleExamQuestion[]];

interface StepTwoProps {
  onNextPress: VoidFunction;
  onPrevPress: VoidFunction;
  singleExam: SampleExam | undefined;
}
function StepTwo({ onNextPress, onPrevPress, singleExam }: StepTwoProps) {
  const { t } = useTranslation();
  const { MsgActions, ExamActions } = useActions();
  const { exam } = useTypedSelector((state) => state.ExamReducer);
  const [AllExercises, setAllExercises] = React.useState<QuestionsData[]>([]);
  const [examQuestions, setExamQuestions] = React.useState<Question[]>([]);
  const [visbileModal, setVisibleModal] = React.useState(false);

  const [data, loading, error, _] = useFetch(
    FetchSampleExamExcercise,
    {
      excercises: (exam as SampleExam).questionsType,
    },
    getAuthHeaders,
    "POST"
  );

  React.useEffect(() => {
    if (!loading && data.exercises) {
      const _AllExercises = [];
      for (let excercise of data.exercises) {
        const _questionData: QuestionsData = excercise;
        if (
          _questionData[0].title.startsWith(
            QuestionsTypes.VocabularyPracticeQuestions
          ) ||
          _questionData[0].title.startsWith(QuestionsTypes.DefinitionsQuestions)
        ) {
          _questionData[1] = _questionData[1].sort((a, b) =>
            (a.question as string).localeCompare(b.question as string)
          );
        } else if (
          _questionData[0].title.startsWith(
            QuestionsTypes.SentenceCompletionQuestions
          )
        ) {
          _questionData[1] = _questionData[1].sort((a, b) =>
            (a.answer as string).localeCompare(b.answer as string)
          );
        }
        _AllExercises.push(_questionData);
      }
      setAllExercises(_AllExercises);
    }
  }, [loading, data]);

  React.useEffect(() => {
    if (singleExam) {
      const _questions: Question[] = [];
      const _questionsRef = singleExam.questionsRef;
      if (_questionsRef) {
        for (let questionRef of _questionsRef) {
          for (let ex of AllExercises) {
            if (questionRef.startsWith(ex[0].questionsRef)) {
              const _question = ex[1].find((q) => q.questionRef == questionRef);
              if (_question) {
                _questions.push(_question);
              }
            }
          }
        }
      }

      setExamQuestions(_questions);
    }
  }, [AllExercises]);
  React.useEffect(() => {
    if (error != "") {
      MsgActions.showMsg("FetchFailed", "danger");
    }
  }, [error]);

  const handleSetSelectedQuestions = (questions: Question[]) => {
    setExamQuestions(questions);
  };

  const handleNextPress = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (examQuestions.length < MIN_EXAM_QUESTIONS_NUM) {
      MsgActions.showMsg("MinSelectQuestionMsg", "danger");
      return;
    }
    if (!exam) return;

    const _exam: SampleExam = { ...exam };
    _exam.questions = examQuestions;
    ExamActions.updateExam(_exam);
    onNextPress();
  };

  const handlePrevPress = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setVisibleModal(true);
  };

  return (
    <div className="StepTwo">
      {/* main form */}
      <YesNoModal
        msg={t("questionsWillRemoved")}
        title={t("warning")}
        visible={visbileModal}
        onDissmis={() => setVisibleModal(false)}
        YesPress={() => onPrevPress()}
        NoPress={() => setVisibleModal(false)}
      />
      <Form>
        <Card>
          <Card.Header dir={isRTL() ? "rtl" : "ltr"}>
            <Row>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>
                  {t("Choose Exam Questions")}{" "}
                  <span className="minQuestionNum">{`( ${t(
                    "at least 2 questions"
                  )} )`}</span>
                </Form.Label>
              </Form.Group>
            </Row>
          </Card.Header>
          <Card.Body>
            {/* loading data */}
            {loading && <Spinner variant="primary" />}

            <Accordion defaultActiveKey="0">
              {AllExercises.map((item, index) => (
                <Accordion.Item
                  key={index.toString()}
                  eventKey={index.toString()}
                >
                  <Accordion.Header>{item[0].title}</Accordion.Header>
                  <Accordion.Body>
                    <SampleExamQuestionsTable
                      questions={item[1]}
                      exercise={item[0]}
                      examQuestions={examQuestions}
                      onQuestionsChanged={(questions) =>
                        handleSetSelectedQuestions(questions)
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Card.Body>
          {/* button wrapper */}
          <Card.Footer style={{ minHeight: " 200px" }}>
            <Stack direction="horizontal" gap={2} dir={isRTL() ? "rtl" : "ltr"}>
              <div style={{ fontSize: 20 }}>{t("Total questions")}:</div>
              <span style={{ color: "var(--orange)", fontSize: 20 }}>
                {examQuestions.length}
              </span>
            </Stack>
            <Stack direction="horizontal" gap={3} className="btnsWrapper">
              <Button className="prevBtn" onClick={handlePrevPress}>
                {t("prev")}
              </Button>
              <Button className="nextBtn" onClick={handleNextPress}>
                {t("next")}
              </Button>
            </Stack>
          </Card.Footer>
        </Card>
      </Form>
    </div>
  );
}

export default StepTwo;

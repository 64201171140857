import React from "react";
import "./TeacherNavigation.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { Header, HelpVideo, Sidebar, TabsNav } from "../components";
import {
  Languages,
  STUDENT_TABS as TABS,
  NAV_TABS_STUDENT as NAV_TABS,
} from "../utils/Constans";

import EnglishExam from "../screens/Home/StudentScreens/EnglishExams/EnglishExam";
import ExamIntroduction from "../screens/Home/StudentScreens/EnglishExams/components/ExamIntroduction";
import SampleExamQuestions from "../screens/Home/StudentScreens/EnglishExams/SampleExam/SampleExamQuestions";
import HebrewExams from "../screens/Home/StudentScreens/HebrewExams/HebrewExams";
import WrittenExam from "../screens/Home/StudentScreens/EnglishExams/WrittenExam/WrittenExam";
import CobeExam from "../screens/Home/StudentScreens/EnglishExams/CobeExam/CobeExam";
import ViewSampleResults from "../screens/Home/StudentScreens/ViewResult/ViewSampleResults";
import ViewWrittenResult from "../screens/Home/StudentScreens/ViewResult/ViewWrittenResult";
import ViewCobeResult from "../screens/Home/StudentScreens/ViewResult/ViewCobeResult";

const StudentNavigation = () => {
  const [visible, setVisible] = React.useState(false);
  const { i18n } = useTranslation();
  const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const language = e.target.value;
    i18n.changeLanguage(language.slice(0, 2).toLocaleLowerCase());
  };

  return (
    <div className="TeacherNavigation">
      {/* <div className="sidebarWrapper">
       */}
      <div className={!visible ? "sidebarWrapper" : "openSidebar"}>
        <Sidebar
          visible={visible}
          SideBarVisibilty={(visible) => setVisible(visible)}
          tabs={TABS}
        />
      </div>
      <div className="wrapper">
        <Header onPress={() => setVisible(!visible)}>
          <Form.Select
            className="languageSelect"
            aria-label="English"
            defaultValue={"English"}
            onChange={handleChangeLanguage}
          >
            {Languages.map((language) => (
              <option key={language.value} value={language.value}>
                {language.label}
              </option>
            ))}
          </Form.Select>
        </Header>

        {/* router */}
        <Routes>
          <Route path="/student/exams" element={<TabsNav tabs={NAV_TABS} />}>
            <Route index element={<EnglishExam />} />

            <Route path="english" element={<EnglishExam />} />
            <Route path="hebrew" element={<HebrewExams />} />
          </Route>

          <Route
            path="/student/exams/introduction/:key"
            element={<ExamIntroduction />}
          />
          <Route
            path="/student/SampleExamQuestions/:key"
            element={<SampleExamQuestions />}
          />

          <Route path="/student/TopicExam/:key" element={<WrittenExam />} />
          <Route
            path="/student/CustomExamQuestions/:key"
            element={<CobeExam />}
          />
          <Route
            path="/student/SampleExamResults/:key/:uid"
            element={<ViewSampleResults />}
          />
          <Route
            path="/student/ViewWrittenResults/:key/:uid"
            element={<ViewWrittenResult />}
          />
          <Route
            path="/student/ViewCobeResults/:key/:uid"
            element={<ViewCobeResult />}
          />

          <Route path="*" element={<Navigate to={"/student/exams"} />} />
        </Routes>
      </div>
    </div>
  );
};

export default StudentNavigation;

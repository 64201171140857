import React, { useState, useEffect } from "react";
import AudioPlayer from "./Player";
import { MicIcon } from "../../../../../../assets/icons";
import { StopIcon } from "../../../../../../assets/icons";

interface VoiceRecorderProps {
  initUrl?: string;
  audioBlob: Blob | undefined;
  setAudioBlob: React.Dispatch<React.SetStateAction<Blob | undefined>>;
}

const VoiceRecorder = ({
  audioBlob,
  setAudioBlob,
  initUrl,
}: VoiceRecorderProps) => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );

  const [audioUrl, setAudioUrl] = useState<string>(initUrl ? initUrl : "");

  useEffect(() => {
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  }, [audioBlob]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        // setAudioBlob();
        recorder.start();
        recorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0) {
            setAudioBlob(event.data);
          }
        });
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
    setRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }

    setRecording(false);
    if (audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);
    }
  };

  return (
    <div className="flex gap-4">
      {recording ? (
        <button onClick={stopRecording} className="bg-[#3A8688] p-2">
          <StopIcon color="white" />
        </button>
      ) : (
        <button onClick={startRecording} className="bg-[#3A8688] p-2 ">
          <MicIcon color="white" />
        </button>
      )}

      <AudioPlayer audioUrl={audioUrl} />
    </div>
  );
};

export default VoiceRecorder;

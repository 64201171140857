import React, { useEffect, useState } from 'react';
import {
	useTypedSelector,
	useFetch,
	useActions,
} from '../../../../../../../../hooks';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../../../../../../utils/Constans';
import { getAuthHeaders } from '../../../../../../../../utils/Generic';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FaRegComment } from 'react-icons/fa';
import CommentSidebar from '../../Comments/CommentSidebar';
import CommentModal from '../../Comments/CommentModal';

const WrittenResult = () => {
	const { t } = useTranslation();
	const [student, setStudent] = useState<any>();
	const [studentExam, setStudentExam] = useState<any>(null);
	const [studentMarks, setMarks] = useState<number>(0);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [comments, setComments] = useState([{ comment: '', index: -1 }]);

	const { exams } = useTypedSelector(state => state.ExamReducer);
	const { user } = useTypedSelector(state => state.AuthReducer);
	const { MsgActions } = useActions();
	const { key, uid } = useParams();

	const currentExam: any = exams?.find(exam => exam.key === key);

	// get user info
	const [data, loading, error] = useFetch(
		`${BASE_URL}/GetUserInfo`,
		{ username: uid, accountType: 'Student' },
		getAuthHeaders,
		'POST'
	);

	useEffect(() => {}, [comments]);

	const addCommentToState = (newComment: {
		comment: string;
		index: number;
	}) => {
		setComments(prevComments => [...prevComments, newComment]);
	};

	const deleteComment = (commentIndex: number) => {
		setComments(prevComments =>
			prevComments.filter(comment => comment.index !== commentIndex)
		);
	};

	useEffect(() => {
		if (!loading && data && data.userInfo) {
			setStudent(data.userInfo);
		}
	}, [data, loading, error]);

	const handleMarksChange = async () => {
		const headers = await getAuthHeaders();
		if (studentMarks) {
			axios
				.put(
					`${BASE_URL}/AddMarksToStudentExam`,
					{
						examId: key,
						studentId: student.key,
						teacherId: user?.key,
						marks: studentMarks,
					},
					{ headers: headers.headers }
				)
				.then(res => {
					MsgActions.showMsg('Marks updated successfully', 'success');
				})
				.catch(err => {
					console.log(err);
					MsgActions.showMsg('Error updating marks', 'danger');
				});
		}
	};

	useEffect(() => {
		if (student && studentExam === null) {
			const params = {
				examId: key,
				studentId: student.key,
				teacherId: user?.key,
			};
			const getStudentAnswers = async () => {
				const headers = await getAuthHeaders();
				axios
					.get(`${BASE_URL}/GetStudentExam`, {
						params: { params: JSON.stringify(params) },
						headers: headers.headers,
					})
					.then(res => {
						setStudentExam(res.data.exam);
						setMarks(res.data.exam.marks);
						const allComments = res.data?.exam?.answers?.map(
							(answer: any, index: number) => ({
								comment: answer.comment,
								index,
							})
						);

						if (allComments.length > 0) {
							setComments(allComments);
						}
					})
					.catch(err => {
						console.log(err);
						MsgActions.showMsg('Error getting student exam', 'danger');
					});
			};
			getStudentAnswers();
		}
	}, [student]);

	const handleShowModal = () => {
		setShowModal(true);
	};

	return (
		<div className=" py-[1rem] flex flex-col bg-[#FFFFFF]">
			{showModal && (
				<CommentModal
					setShowModal={setShowModal}
					examId={key}
					studentId={student?.key}
					teacherId={user?.key}
					comments={comments}
					setComments={setComments}
					questionId={
						studentExam && studentExam?.answers
							? studentExam?.answers[0]?.questionId
							: currentExam.topic.key
					}
					index={0}
					addCommentToState={addCommentToState}
				/>
			)}
			<div className="md:flex md:flex-row mx-10 flex flex-col-reverse">
				<div className="flex gap-4 basis-3/4">
					<div className="flex flex-col w-full">
						<div className="shadow py-[1rem] px-[5rem] md:flex md:flex-row md:justify-around flex flex-col w-[20rem] md:w-full border-l-2 border-[#00699B] bg-[#FFFFFF]">
							<div>
								<h5 className="text-[#18608C]">{currentExam?.title}</h5>
								<p className="mt-4 font-semibold">{student?.name}</p>
								<p className="font-light -mt-2">{student?.uid}</p>
							</div>
							<div>
								<p className="font-semibold text-[#7E57C2] bg-[#fafafa] p-4 mt-4">
									{studentMarks}
								</p>
							</div>
							<div className="flex flex-col gap-2">
								<div className="flex justify-center items-center gap-2">
									<h6 className="font-semibold mt-2">{t('Marks')}</h6>
									<input
										type="text"
										name=""
										id=""
										value={studentMarks}
										onChange={e => setMarks(Math.round(Number(e.target.value)))}
										className="border w-10"
									/>
								</div>
								<button
									className="bg-[#3188C9] px-8 py-[0.5rem] mx-4 rounded text-white font-semibold "
									onClick={handleMarksChange}
								>
									{t('Save')}
								</button>
							</div>
						</div>

						<div
							className="my-4 w-[20rem] md:w-full"
							style={{ minHeight: 'calc(70vh + 100px)' }}
						>
							<h4 className="text-center">{t('Question')} </h4>
							<div className="border-b-2 w-full"></div>
							<h5 className="text-[#18608C] mt-4">{currentExam.topic.title}</h5>
							<h5 className=" mt-4 ">{currentExam.topic.topic}</h5>

							<div className="border-b-2 w-full my-4 "></div>
							{studentExam && studentExam?.answers && (
								<p>
									Student Answer:{'    '}
									<span className="bg-[#67C06B] inline py-1 px-2 rounded">
										{studentExam?.answers[0].answer}
									</span>
								</p>
							)}
							{(!studentExam || !studentExam?.answers) && (
								<p>
									Student Answer:{'    '}
									<span className="bg-[#FF6868] inline py-1 px-2 rounded">
										{'-'}
									</span>
								</p>
							)}
						</div>
					</div>
					<div
						className="mt-[14rem] bg-[#FDF1E0] h-fit p-2 cursor-pointer md:block hidden"
						onClick={handleShowModal}
					>
						<FaRegComment color="#F28705" size={22} />
					</div>
				</div>

				<div className="basis-1/4 flex justify-between md:flex-none my-2">
					<CommentSidebar
						examId={key}
						studentId={student?.key}
						questionId={
							studentExam && studentExam?.answers
								? studentExam?.answers[0]?.questionId
								: currentExam.topic.key
						}
						teacherId={user?.key}
						comments={comments}
						setComments={setComments}
						index={0}
						deleteComment={deleteComment}
					/>
					<div
						className=" bg-[#FDF1E0] h-fit p-2 cursor-pointer md:hidden block"
						onClick={handleShowModal}
					>
						<FaRegComment color="#F28705" size={22} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WrittenResult;

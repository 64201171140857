import React, { useEffect } from 'react';
import './WrittenExam.css';
import StepOne from './components/StepOne/StepOne';
import StepTwo from './components/StepTwo/StepTwo';
import { useActions, useTypedSelector } from '../../../../../hooks';
import { useParams } from 'react-router-dom';

function WrittenExam() {
	const [currentStep, setCurrentStep] = React.useState(0);
	const { ExamActions } = useActions();
	const { user } = useTypedSelector(state => state.AuthReducer);
	const { key } = useParams();
	const { writtenExam } = useTypedSelector(state => state.ExamReducer);

	// if key in params then fetch exam data
	useEffect(() => {
		if (key && user) {
			ExamActions.fetchSingleExams(user.key, key);
		}
	}, [key]);
	React.useEffect(() => {
		return () => {
			ExamActions.cleanExam();
		};
	}, []);
	switch (currentStep) {
		case 0:
			return (
				<StepOne
					onPress={() => setCurrentStep(currentStep + 1)}
					writtenExam={key !== undefined ? writtenExam : undefined}
				/>
			);
		case 1:
			return (
				<StepTwo writtenExam={key !== undefined ? writtenExam : undefined} />
			);
		default:
			return <div />;
	}
}

export default WrittenExam;

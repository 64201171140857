import React, { useEffect, useState } from "react";
import { isRTL } from "../../../../../../locales";
import { Table, Form, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Word, CheckedWords } from "../../../../../../types";
import { useActions, useTypedSelector } from "../../../../../../hooks";
import { BASE_URL } from "../../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../../utils/Generic";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ADD_HOMEWORK_URL = `${BASE_URL}/CreateHomeWork`;

interface WordsTableProps {
  words: Word[];
  homeworkTitle: string;
  homeworkLanguage: string;
  isDefinition: boolean;
}

const WordsTable = ({
  words,
  homeworkTitle,
  homeworkLanguage,
  isDefinition,
}: WordsTableProps) => {
  const { t } = useTranslation();
  const { MsgActions } = useActions();
  const navigate = useNavigate();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(-1);
  const [search, setSearch] = useState<string>("");
  const [allWords, setAllWords] = useState<any[]>([]);
  const [homeworkWords, setHomeworkWords] = useState<Word[]>([]);
  const [allChecked, setAllChecked] = useState(false);

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const rowsPerPage = 4;

  useEffect(() => {
    setPages(Math.ceil(words.length / rowsPerPage));
    if (words.length > 0) {
      setCurrentPage(0);
    }
  }, [words]);

  const isCheckedAll = (words: Word[]) => {
    for (let word of words) {
      if (!homeworkWords.find((_w) => _w.key === word.key)) {
        return false;
      }
    }

    return true;
  };

  const onPageChanged = (page: number) => {
    setCurrentPage(page);
    // Load the selected words for the current page from selectedWords
    const pageWords = words.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

    setAllChecked(isCheckedAll(pageWords));
    setAllWords(pageWords);
  };

  const handlePrevPage = () => {
    if (currentPage - 1 >= 0) {
      setCurrentPage(currentPage - 1);
      onPageChanged(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage + 1 < pages) {
      setCurrentPage(currentPage + 1);
      onPageChanged(currentPage + 1);
    }
  };

  useEffect(() => {
    if (search === "") setAllWords(words.slice(0, rowsPerPage));
  }, [words, search]);

  const togglePageSelection = () => {
    setAllChecked(!allChecked);
    for (let word of allWords) {
      toggleWordSelection(word, !allChecked);
    }
  };

  const toggleWordSelection = (word: Word, isChecked: boolean) => {
    if (isChecked) {
      // add to homework wrods
      setHomeworkWords((prev) => [...prev, word]);
    } else {
      setAllChecked(false);
      // remove from homework words
      setHomeworkWords((prev) => prev.filter((_w) => _w.key !== word.key));
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value.replace(/[\u0591-\u05C7]/g, "").toLowerCase();

    if (txt.trim().length > 0) {
      setSearch(txt);

      const filteredWords = words.filter((word) => {
        return word.word
          .replace(/[\u0591-\u05C7]/g, "")
          .toLowerCase()
          .includes(txt.toLowerCase());
      });

      setAllWords(filteredWords);
      return;
    }

    setSearch("");
  };

  const handleCreateHomeWork = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (homeworkWords.length < 2) {
      MsgActions.showMsg(
        "You have to add at least 2 words to the homework!",
        "danger"
      );
      return;
    }
    if (!homeworkTitle) {
      MsgActions.showMsg("Homework name is required!", "danger");
      return;
    }

    const headers = await getAuthHeaders();

    await axios
      .post(
        ADD_HOMEWORK_URL,
        {
          title: homeworkTitle,
          words: homeworkWords,
          teacherId: user?.uid,
          homeworkLanguage,
        },
        {
          headers: headers.headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          navigate("/homework/homeworkList");
        }
      })
      .catch((err) => {
        console.log(err);
        MsgActions.showMsg(err.response.data.msg, "danger");
      });
  };

  return (
    <div style={{ minHeight: "calc(100vh + 10px)" }}>
      <div className="flex flex-row gap-4 my-4 justify-between">
        <div>
          <Form.Control
            className="search"
            style={{ maxWidth: 300 }}
            value={search}
            type="text"
            placeholder={`${t("Search")}...`}
            onChange={handleSearch}
          />
        </div>
        <div
          onClick={(e) => handleCreateHomeWork(e)}
          className="cursor-pointer bg-[#3188C9] text-white px-3 rounded font-semibold justify-center flex items-center"
        >
          <button>{t("Create Homework")}</button>
        </div>
      </div>
      <Table bordered dir={isRTL() ? "rtl" : "ltr"} className="overflow-hidden">
        <thead>
          <tr>
            <th>
              <div>
                <Form.Check
                  type="switch"
                  checked={allChecked}
                  onChange={() => togglePageSelection()}
                />
              </div>
            </th>
            <th>
              <div className="flex justify-center text-[#18608C] text-[0.8rem]">
                {t("Word")}
              </div>
            </th>
            <th>
              <div className="flex justify-center text-[#18608C] text-[0.8rem]">
                {t("Mean")}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {allWords.map((word, index) => (
            <tr key={index.toString()}>
              <td>
                <Form.Check
                  className="cursor-pointer"
                  type="switch"
                  onChange={(e) => toggleWordSelection(word, e.target.checked)}
                  checked={
                    homeworkWords.find((_w) => word.key === _w.key) !==
                    undefined
                  }
                />
              </td>

              <td>
                <div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
                  {word.word}
                </div>
              </td>
              <td>
                <div className="flex justify-center items-center text-[0.8rem]  h-[8vh]">
                  {!isDefinition ? word.mean : word.sentences}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="flex justify-end">
        <Pagination.Prev onClick={handlePrevPage} />
        <Pagination.Item>
          <span>
            {currentPage + 1} / {pages}
          </span>
        </Pagination.Item>
        <Pagination.Next onClick={handleNextPage} />
      </Pagination>
    </div>
  );
};

export default WordsTable;

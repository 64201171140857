import React from "react";
import "./SampleExamQuestionsTable.css";
import { useTranslation } from "react-i18next";
import { Form, Modal } from "react-bootstrap";

import { CheckedTable } from "../../../../../../../components";
import MCQuestionView from "./MCQuestionView";
import {
  MCQuestion,
  MatchingQuestion,
  Question,
  SampleExamExercise,
  SampleExamQuestion,
} from "../../../../../../../types";
import MatchingQuestionView from "./MatchingQuestionView";
import { QuestionsTypes } from "../../../../../../../utils/Constans";

const TABLE_LINES = 10;

interface SampleExamQuestionsTableProps {
  questions: SampleExamQuestion[];
  exercise: SampleExamExercise;
  examQuestions: Question[];
  onQuestionsChanged: (question: Question[]) => void;
}

function SampleExamQuestionsTable({
  questions,
  exercise,
  examQuestions,
  onQuestionsChanged,
}: SampleExamQuestionsTableProps) {
  const { t } = useTranslation();
  const columns = exercise.title.endsWith("Definitions Bagrut Style")
    ? [t("Questions")]
    : [t("Questions"), t("Correct Answer"), t("Options")];
  const [AllQuestions, setAllQuestions] = React.useState<SampleExamQuestion[]>(
    []
  );
  const [filteredQuestions, setFilteredQuestions] = React.useState<
    SampleExamQuestion[]
  >([]);

  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const [optionsModelVisible, setOptionsModalVisible] = React.useState(false);
  const [selectedModalQuestion, setSelectedModalQuestion] =
    React.useState<MCQuestion>();

  const handleShowOptions = (question: MCQuestion) => {
    setSelectedModalQuestion(question);
    setOptionsModalVisible(true);
  };

  React.useEffect(() => {
    setAllQuestions(questions);
    setTotalQuestions(questions.length);
    setFilteredQuestions(questions.slice(0, TABLE_LINES));
  }, [questions]);

  const onPageChanged = (page: number) => {
    setFilteredQuestions(
      AllQuestions.slice(page * TABLE_LINES, (page + 1) * TABLE_LINES)
    );
  };

  const isAllChecked = () => {
    for (let question of filteredQuestions) {
      if (!isQuestionSelect(question)) return false;
    }
    return true;
  };

  const isQuestionSelect = (question: Question) => {
    const q = examQuestions.filter((v) => v.key == question.key);
    return q.length > 0;
  };

  const handleCheckAllChecked = (isChecked: boolean) => {
    if (isChecked) {
      const _questions = [...examQuestions];
      for (let question of filteredQuestions) {
        if (!isQuestionSelect(question)) {
          _questions.push(question);
        }
      }
      onQuestionsChanged(_questions);
    } else {
      let _questions = [...examQuestions];
      for (let question of filteredQuestions) {
        if (isQuestionSelect(question)) {
          _questions = _questions.filter((q) => q.key != question.key);
        }
      }
      onQuestionsChanged(_questions);
    }
  };

  const handleOnQuestionChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    question: Question
  ) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      addQuestion(question);
    } else {
      removeQuestion(question);
    }
  };

  const addQuestion = (question: Question) => {
    if (!isQuestionSelect(question)) {
      onQuestionsChanged([...examQuestions, question]);
    }
  };

  const removeQuestion = (question: Question) => {
    const _questions = examQuestions.filter((q) => q.key != question.key);

    onQuestionsChanged(_questions);
  };

  const handleSearch = (txt: string) => {
    if (txt == "") {
      setTotalQuestions(AllQuestions.length);
      setFilteredQuestions(AllQuestions.slice(0, TABLE_LINES));
    } else {
      const _questions = AllQuestions.filter((question) =>
        filterQuestion(question, txt)
      );

      setTotalQuestions(_questions.length);
      setFilteredQuestions(_questions.slice(0, TABLE_LINES));
    }
  };

  const isSearchByQuestion = (title: string): boolean => {
    const questionTypes = [
      QuestionsTypes.VocabularyPracticeQuestions,
      QuestionsTypes.DefinitionsQuestions,
      QuestionsTypes.HebrewVocabularyPracticeQuestions,
      QuestionsTypes.SynonymsQuestions,
    ];
    for (let questionType of questionTypes) {
      if (title.startsWith(questionType)) return true;
    }
    return false;
  };

  const filterQuestion = (question: Question, txt: string) => {
    if (question.answerType == "MCQ") {
      if (isSearchByQuestion(exercise.title)) {
        return (question as MCQuestion).question
          .replace(/[\u0591-\u05C7]/g, "")
          .toLowerCase()
          .startsWith(txt.toLowerCase());
      } else {
        return (question as MCQuestion).answer
          .replace(/[\u0591-\u05C7]/g, "")
          .toLocaleLowerCase()
          .startsWith(txt.toLowerCase());
      }
    }

    if (question.answerType == "Matching") {
      const _question = question as MatchingQuestion;
      return (
        _question.options.filter((option) =>
          option.toLowerCase().startsWith(txt.toLowerCase())
        ).length > 0
      );
    }

    return false;
  };

  return (
    <div className="SampleExamQuestions">
      {/* options modal */}
      <Modal
        className="StepTwoOptionModal"
        show={optionsModelVisible}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setOptionsModalVisible(false);
          setSelectedModalQuestion(undefined);
        }}
      >
        <Modal.Header
          closeButton
          onClick={() => setOptionsModalVisible(false)}
        />

        <Modal.Body>
          <ul>
            {selectedModalQuestion?.options.map((option, index) => (
              <li
                key={index.toString()}
                style={{
                  backgroundColor:
                    selectedModalQuestion.answer == option
                      ? "rgb(188, 245, 229)"
                      : "var(--primary2)",
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
      {/* table questions */}
      <CheckedTable
        rowsPerPage={10}
        totalData={totalQuestions}
        onPageChanged={onPageChanged}
        headers={columns}
        onCheckedAll={handleCheckAllChecked}
        isAllChecked={isAllChecked()}
        onSearch={handleSearch}
      >
        {filteredQuestions.map((question, _) => (
          <tr key={question.key}>
            <td>
              <Form.Check
                checked={isQuestionSelect(question)}
                onChange={(e) => handleOnQuestionChecked(e, question)}
              />
            </td>

            {question.answerType == "MCQ" && (
              <MCQuestionView
                question={question}
                viewBtnPress={() => handleShowOptions(question as MCQuestion)}
              />
            )}

            {question.answerType == "Matching" && (
              <MatchingQuestionView question={question as MatchingQuestion} />
            )}
          </tr>
        ))}
      </CheckedTable>
    </div>
  );
}

export default SampleExamQuestionsTable;

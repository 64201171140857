import React from 'react';
import { useTypedSelector } from '../../../../hooks';
import ChangePassword from './components/ChangePassword';
import ResetPassword from './components/ResetPassword';
import userImg from '../../../../assets/images/user.png';
import {
	UserIcon,
	SchoolIcon,
	LocationIcon,
	EmailIcon,
	LockIcon,
} from '../../../../assets/icons';

const ProfileSettings = () => {
	const { user } = useTypedSelector(state => state.AuthReducer);
	const [passwordChangeModel, setChangePasswordModel] = React.useState(false);
	const [resetPasswordModel, setResetPasswordModel] = React.useState(false);

	const handlePasswordChange = () => {
		setChangePasswordModel(true);
	};

	const handleResetPassword = () => {
		setResetPasswordModel(true);
	};

	return (
		<section
			className="bg-[#fafafa]"
			style={{ minHeight: 'calc(100vh + 100px)' }}
		>
			{passwordChangeModel && (
				<ChangePassword setViewShowModel={setChangePasswordModel} />
			)}
			{resetPasswordModel && (
				<ResetPassword setViewShowModel={setResetPasswordModel} />
			)}

			<div className="bg-[#ffffff] md:h-[65vh] w-[23rem] md:w-[70vw] md:mx-[10rem] my-[3rem] shadow-sm">
				<div className="flex justify-evenly md:flex md:flex-row md:justify-between md:mx-10 pt-4">
					<div>
						<h3>Personal Information</h3>
						<h6 className="text-[#ADADAD] pt-3">
							Update your personal information
						</h6>
					</div>
					<div>
						<img src={userImg} alt="user" className="h-20 w-20" />
						<p className="text-[#ADADAD] pt-2">{user?.name}</p>
					</div>
				</div>
				<div className="border-b-2 w-[90%]  mx-10"></div>
				<div className="flex flex-col justify-center items-center gap-[2rem] md:flex md:flex-row md:gap-[14rem] md:mx-14 pt-4 mt-4">
					<div className="flex flex-col gap-10">
						<div className="flex items-center gap-3">
							<h6>Name</h6>
							<label className="relative block">
								<span className="sr-only">Name</span>
								<span className="absolute inset-y-0 left-0 flex items-center pl-2">
									<UserIcon color="#ADADAD" />
								</span>
								<input
									className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12 pr-3 w-[18rem]"
									value={user?.name}
									type="text"
									name="search"
									disabled
								/>
							</label>
						</div>
						<div className="flex items-center gap-2">
							<h6>School</h6>
							<label className="relative block">
								<span className="sr-only">School</span>
								<span className="absolute inset-y-0 left-0 flex items-center pl-2">
									<SchoolIcon color="#ADADAD" />
								</span>
								<input
									className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12  pr-3 w-[18rem]"
									value={user?.school}
									type="text"
									name="School"
									disabled
								/>
							</label>
						</div>
					</div>
					<div className="flex flex-col gap-10">
						<div className="flex items-center gap-3">
							<h6>City</h6>
							<label className="relative block">
								<span className="sr-only">City</span>
								<span className="absolute inset-y-0 left-0 flex items-center pl-2">
									<LocationIcon color="#ADADAD" />
								</span>
								<input
									className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12  pr-3 w-[18rem]"
									value={user?.city}
									type="text"
									name="City"
									disabled
								/>
							</label>
						</div>
						<div className="flex items-center gap-[0.6rem]">
							<h6>User</h6>
							<label className="relative block">
								<span className="sr-only">User</span>
								<span className="absolute inset-y-0 left-0 flex items-center pl-2">
									<EmailIcon color="#ADADAD" />
								</span>
								<input
									className=" block bg-white border-[1.5px] border-[#ADADAD] rounded-md py-2 pl-12  pr-3 w-[18rem]"
									value={user?.uid}
									type="text"
									name="User"
									disabled
								/>
							</label>
						</div>
					</div>
				</div>

				{/* Buttons */}
				<div className="flex flex-col gap-2 mx-8 md:flex md:flex-row md:justify-center md:gap-4 md:mx-10 pt-4 mt-12">
					<button
						className="bg-[#9BDE7E] text-white flex items-center px-10 py-2 rounded font-semibold gap-4"
						onClick={handlePasswordChange}
					>
						<LockIcon color="white" size={18} />
						Change Password
					</button>
					<button
						className="bg-[#3188C9] text-white flex items-center px-10 py-2 rounded font-semibold gap-4 "
						onClick={handleResetPassword}
					>
						<LockIcon color="white" size={18} />
						Reset Student Password
					</button>
				</div>
			</div>
		</section>
	);
};

export default ProfileSettings;

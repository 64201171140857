import axios from "axios";

export type Method = "POST" | "GET" | "DELETE" | "PUT";

export const ApiRequest = async (
  url: string,
  params = {},
  method: Method,
  config: any = undefined
) => {
  let response;
  let headerConfig = {};
  if (config) {
    headerConfig = config;
  }

  switch (method) {
    case "GET":
      response = await axios.get(url, {
        ...headerConfig,
        params: { params: JSON.stringify(params) },
      });
      break;
    case "POST":
      response = await axios.post(url, params, headerConfig);
      break;
    case "DELETE":
      response = await axios.delete(url, {
        ...headerConfig,
        params: { params: JSON.stringify(params) },
      });
      break;
    case "PUT":
      response = await axios.put(url, params, headerConfig);
      break;
    default:
      throw "Wrong method!";
  }

  return response;
};

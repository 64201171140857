import React from 'react';
import './StepTwo.css';
import {
	useActions,
	useFetch,
	useTypedSelector,
} from '../../../../../../../hooks';
import { Topic, WrittenProductionExam } from '../../../../../../../types';
import { getAuthHeaders } from '../../../../../../../utils/Generic';
import { BASE_URL } from '../../../../../../../utils/Constans';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, Spinner, Stack } from 'react-bootstrap';
import { isRTL } from '../../../../../../../locales';
import { useNavigate, useParams } from 'react-router-dom';

const FetchTopicsUrl = `${BASE_URL}/FetchTopicsByModule`;

interface StepTwoProps {
	writtenExam?: WrittenProductionExam;
}
function StepTwo({ writtenExam }: StepTwoProps) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { key } = useParams();
	const { MsgActions, ExamActions } = useActions();
	const { exam, loading: saving } = useTypedSelector(
		state => state.ExamReducer
	);
	const { user } = useTypedSelector(state => state.AuthReducer);
	const { msgType } = useTypedSelector(state => state.MsgReducer);
	const [topics, setTopics] = React.useState<Topic[]>([]);
	const [selectedTopicKey, setSelectedTopicKey] = React.useState('');
	const [topicTitle, setTopicTitle] = React.useState('');
	const [topicDesc, setTopicDesc] = React.useState('');

	const [data, loading, error] = useFetch(
		FetchTopicsUrl,
		{ module: (exam as WrittenProductionExam)?.topic.module },
		getAuthHeaders,
		'GET'
	);

	React.useEffect(() => {
		if (error != '') {
			MsgActions.showMsg('FetchFailed', 'danger');
			return;
		}
		if (!loading && data && data.topics) {
			setTopics(data.topics);
		}
	}, [data, loading, error]);

	React.useEffect(() => {
		if (msgType == 'success') {
			navigate('/exams/list');
		}
	}, [msgType]);

	const handleCreateExam = () => {
		try {
			let topic: Topic;
			if ((exam as WrittenProductionExam).topic.module == 'create_topic') {
				topic = {
					module: '',
					title: topicTitle,
					topic: topicDesc,
					level: exam!.level,
					language: exam!.language,
				};
			} else {
				topic = topics.filter(t => t.key == selectedTopicKey)[0];
			}

			(exam as WrittenProductionExam).topic = topic;
			exam!.createTime = new Date().getTime();

			if (exam && user) ExamActions.saveExam(exam, user.uid);
			else MsgActions.showMsg('FailedToCreateExam', 'danger');
		} catch (err) {
			MsgActions.showMsg('FailedToCreateExam', 'danger');
		}
	};
	const handleUpdateExam = () => {
		try {
			let topic: Topic;
			if ((exam as WrittenProductionExam).topic.module == 'create_topic') {
				topic = {
					module: '',
					title: topicTitle,
					topic: topicDesc,
					level: exam!.level,
					language: exam!.language,
				};
			} else {
				topic = topics.filter(t => t.key == selectedTopicKey)[0];
			}

			(exam as WrittenProductionExam).topic = topic;
			exam!.createTime = new Date().getTime();
			if (exam && user) ExamActions.editExam(user?.key, writtenExam?.key, exam);
			else MsgActions.showMsg('FailedToCreateExam', 'danger');
		} catch (err) {
			console.log(err);
			MsgActions.showMsg('FailedToCreateExam', 'danger');
		}
	};

	const isDisabled = () => {
		if ((exam as WrittenProductionExam).topic?.module === 'create_topic') {
			if (topicDesc === '' || topicTitle === '') return true;
		} else if (selectedTopicKey === '') {
			return true;
		}
		return false;
	};

	return (
		<div className="StepTwoWritten" dir={isRTL() ? 'rtl' : 'ltr'}>
			<Stack direction="vertical" className="wrapper">
				<h2>{t('Select Topic')}</h2>
				<Form validated>
					<Stack direction="horizontal" gap={2}>
						{!key && (
							<Button disabled={isDisabled()} onClick={handleCreateExam}>
								{t('t7')}
							</Button>
						)}
						{key && (
							<Button className="updateExamBtn" onClick={handleUpdateExam}>
								{t('EditExam')}
							</Button>
						)}
						{saving && <Spinner variant="primary" />}
					</Stack>

					{loading && <Spinner variant="primary" />}
					{(exam as WrittenProductionExam)?.topic.module === 'create_topic' && (
						<div className="mt-5">
							<Form.Group className="mb-3">
								<Form.Control
									value={topicTitle}
									required
									type="text"
									placeholder={`${t(
										'Write notes for students, eg: Write 70-90 words on the following topic'
									)}`}
									onChange={e => setTopicTitle(e.target.value)}
								/>

								<Form.Control
									required
									className="mt-2"
									as="textarea"
									rows={3}
									value={topicDesc}
									onChange={e => setTopicDesc(e.target.value)}
									placeholder={t('Write a topic subject') + '...'}
								/>
							</Form.Group>
						</div>
					)}
					{topics.map((topic, index) => (
						<Card
							key={index.toString()}
							className="mt-2 topicWrapper"
							onClick={() => setSelectedTopicKey(topic.key!)}
						>
							<Form.Check
								checked={topic.key === selectedTopicKey}
								inline
								name="topics"
								type="checkbox"
								id={topic.key}
							/>
							<Stack>
								<h5>{topic.title}</h5>
								<section>{topic.topic}</section>
							</Stack>
						</Card>
					))}
				</Form>
			</Stack>
		</div>
	);
}

export default StepTwo;

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../../../../utils/Constans';
import { useActions, useTypedSelector } from '../../../../../../hooks';
import { getAuthHeaders } from '../../../../../../utils/Generic';

const DELETE_HOMEWORK_URL = `${BASE_URL}/DeleteHomeWork`;

interface DeleteHomeWorkProps {
	setShowModel: (newType: boolean) => void;
	homeworkKey: string | undefined;
}

const DeleteHomeWork = ({ setShowModel, homeworkKey }: DeleteHomeWorkProps) => {
	const { MsgActions } = useActions();
	const { user } = useTypedSelector(state => state.AuthReducer);

	const handleOnDeletePress = async () => {
		setShowModel(false);
		const headers = await getAuthHeaders();
		await axios
			.delete(
				`${DELETE_HOMEWORK_URL}/${user?.key}/${homeworkKey}`,

				{
					headers: headers.headers,
				}
			)
			.then(res => {
				if (res.status === 200) {
					window.location.reload();
				}
			})
			.catch(err => {
				MsgActions.showMsg(err.response.data.msg, 'danger');
			});
	};
	return (
		<div>
			<Modal show={true}>
				<Modal.Header closeButton onClick={() => setShowModel(false)}>
					<Modal.Title>Remove homework</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure do you want to remove the homework?
				</Modal.Body>
				<Modal.Footer>
					<Button variant="success" onClick={() => setShowModel(false)}>
						No
					</Button>
					<Button variant="danger" onClick={handleOnDeletePress}>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default DeleteHomeWork;

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useFetch, useTypedSelector } from "../../../../../hooks";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../utils/Generic";
import { Group, Student } from "../../../../../types";
import ExamHeader from "./components/ExamHeader";
import ChartData from "./components/Results/ChartData";
import Stats from "./components/Results/Stats";
import Sidebar from "./components/Sidebar";

const ViewExam = () => {
  const { t } = useTranslation();
  const { user } = useTypedSelector((state) => state.AuthReducer);
  const [student, setStudent] = useState<Student>();

  const { exams } = useTypedSelector((state) => state.ExamReducer);
  const [examData, setExamData] = useState<any>(null);
  const { key } = useParams();

  const currentExam = exams?.find((exam) => exam.key === key);

  useEffect(() => {}, [examData]);

  const [groups, setGroups] = useState<Group[]>([]);

  const FetchGroups_URL = `${BASE_URL}/FetchGroupsByExam`;

  const [data, loading, error] = useFetch(
    FetchGroups_URL,
    { examId: key, teacherId: user?.uid, teacherKey: user?.key },
    getAuthHeaders,
    "GET"
  );

  useEffect(() => {
    if (!loading && data && data.groups) {
      setGroups(data.groups);
    }
  }, [data, loading, error]);

  return (
    <div className="flex justify-center mt-8 bg-[#fff]">
      {!loading &&
        (data === null || !data.groups || data.groups.length < 1) && (
          <div className=" bg-[#FBEFD8] text-[#FDCA4F] text-[1.2rem] px-16 py-2">
            {t("No one joined this exam")}
          </div>
        )}

      {!loading && groups.length > 0 && (
        <div className="w-[20rem] md:flex md:flex-row flex  flex-col-reverse md:w-full mx-4 gap-5">
          <div
            className="md:basis-3/4 w-[20rem] md:w-full "
            style={{ minHeight: "calc(100vh + 400px)" }}
          >
            <ExamHeader student={student} currentExam={currentExam} />
            <ChartData examData={examData} />
            <Stats examData={examData} />
          </div>
          <div className="md:basis-1/4 w-[20rem] md:w-full ">
            <Sidebar
              setStudent={setStudent}
              groups={groups}
              setExamData={setExamData}
            />
          </div>
        </div>
      )}

      {loading && (
        <div className="flex justify-center items-center">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
};

export default ViewExam;

import { Modal, Button } from 'react-bootstrap';
import { Exam } from '../../../../../../types';
import { useNavigate } from 'react-router-dom';

interface UpdateExamProps {
	setShowUpdateModel: (newType: boolean) => void;
	newExam: Exam;
}

const UpdateExam = ({ setShowUpdateModel, newExam }: UpdateExamProps) => {
	const navigate = useNavigate();

	const handleUpdateExam = async () => {
		if (newExam.examType === 'SampleExam') {
			navigate(`/exams/sample/${newExam.key}`);
		}

		if (newExam.examType === 'CobeExam') {
			navigate(`/exams/custom/${newExam.key}`);
		}

		if (newExam.examType === 'WrittenProduction') {
			navigate(`/exams/topic/${newExam.key}`);
		}
	};
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowUpdateModel(false)}
				></Modal.Header>
				<Modal.Body>
					<h4>Update Exam</h4>
					When you edit the exam students will be removed, are you sure you want
					to edit the exam ?
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setShowUpdateModel(false);
						}}
					>
						No
					</Button>
					<Button
						variant="success"
						onClick={() => {
							setShowUpdateModel(false);
							handleUpdateExam();
						}}
					>
						Yes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default UpdateExam;

import React from "react";
import { MatchingQuestion } from "../../../../../../../types";
import { Stack } from "react-bootstrap";
interface MatchingQuestionViewProps {
  question: MatchingQuestion;
}

function MatchingQuestionView({ question }: MatchingQuestionViewProps) {
  return (
    <>
      <td>
        <Stack
          direction="horizontal"
          gap={3}
          className="matchingQuestionWrapper"
        >
          <div>
            {question.options.map((option, index) => (
              <p key={index.toString()}>
                {`${index + 1}) `}
                {option}
              </p>
            ))}
          </div>
          <div>
            {question.question.map((value, index) => (
              <p key={index.toString()}>___ {value}</p>
            ))}
          </div>
        </Stack>
      </td>
    </>
  );
}

export default MatchingQuestionView;

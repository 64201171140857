import React from 'react';
interface SampleResultCardProps {
	answer: any;
}

const SampleResultCard = ({ answer }: SampleResultCardProps) => {
	return (
		<div>
			<td>
				{answer[0]?.completeQuestion?.question.map(
					(question: string, qIndex: number) => (
						<div key={qIndex.toString()} className="font-semibold">
							<ol>
								<li>{question}</li>
							</ol>
						</div>
					)
				)}
			</td>
		</div>
	);
};

export default SampleResultCard;

import { Dispatch } from "redux";

import { Exam, SampleExam, MatchingQuestion, User } from "../types";
import { ActionExamType, ActionMsgType } from "./types";
import { BASE_URL } from "../utils/Constans";
import { getAuthHeaders } from "../utils/Generic";
import { ActionMsg } from "../reducers/MsgReducer";
import { ActionExam } from "../reducers/ExamReducer";
import { ApiRequest } from "../utils/ApiRequests";

const CREATE_EXAM_URL = `${BASE_URL}/CreateTeacherExam`;
const FETCH_EXAM_URL = `${BASE_URL}/FetchTeacherExam`;
const FETCH_SINGLE_URL = `${BASE_URL}/FetchSingleExam`;
const EDIT_EXAM_URL = `${BASE_URL}/UpdateTeacherExam`;

export const setStudentExams = (exams: Exam[]) => {
  return (dispatch: Dispatch<ActionExam>) => {
    dispatch({ type: ActionExamType.STUDENT_EXAMS, payload: exams });
  };
};

export const saveExam = (exam: Exam, uid: string) => {
  return async (dispatch: Dispatch<ActionMsg | ActionExam>) => {
    try {
      dispatch({ type: ActionExamType.SAVEING_EXAM });

      const config = await getAuthHeaders();
      if (exam.attachedFile) {
        await ApiRequest(CREATE_EXAM_URL, { exam, uid }, "POST", config);
      } else {
        await ApiRequest(CREATE_EXAM_URL, { exam, uid }, "POST", config);
      }

      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });

      dispatch({
        type: ActionMsgType.SHOW_SUCCESS_MSG,
        payload: "SuccessToCreateExam",
      });
    } catch (err) {
      console.log((err as Error).message);
      dispatch({
        type: ActionMsgType.SHOW_ERROR_MSG,
        payload: "FailedToCreateExam",
      });
      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    }
  };
};

export const updateExam = (exam: Exam) => {
  return (dispatch: Dispatch<ActionExam>) => {
    dispatch({ type: ActionExamType.UPDATE_EXAM, payload: exam });
  };
};

export const editExam = (
  uid: string | undefined,
  examId: string | undefined,
  exam: Exam
) => {
  return async (dispatch: Dispatch<ActionMsg | ActionExam>) => {
    try {
      dispatch({ type: ActionExamType.SAVEING_EXAM });
      const config = await getAuthHeaders();
      const data = await ApiRequest(
        EDIT_EXAM_URL,
        { uid, examId, exam },
        "PUT",
        config
      );

      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
      dispatch({
        type: ActionMsgType.SHOW_SUCCESS_MSG,
        payload: "Exam updated successfully",
      });
    } catch (err: any) {
      console.log(err);
      dispatch({
        type: ActionMsgType.SHOW_ERROR_MSG,
        payload: err.response.data.msg,
      });
      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    }
  };
};

export const cleanExam = () => {
  return (dispatch: Dispatch<ActionExam>) => {
    dispatch({ type: ActionExamType.CLEAN_EXAM });
  };
};

export const fetchExams = (uid: string | undefined) => {
  return async (dispatch: Dispatch<ActionMsg | ActionExam>) => {
    try {
      dispatch({ type: ActionExamType.SAVEING_EXAM });

      const config = await getAuthHeaders();

      const data = await ApiRequest(FETCH_EXAM_URL, { uid }, "GET", config);
      // sort by date
      data.data.exams.sort((a: SampleExam, b: SampleExam) => {
        return (
          new Date(b.createTime).getTime() - new Date(a.createTime).getTime()
        );
      });

      dispatch({
        type: ActionExamType.FETCH_EXAM_LIST,
        payload: data.data.exams,
      });

      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    } catch (err: any) {
      dispatch({
        type: ActionMsgType.SHOW_ERROR_MSG,
        payload: err.response.data.msg,
      });
      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    }
  };
};

export const fetchSingleExams = (uid: string | undefined, examId: string) => {
  return async (dispatch: Dispatch<ActionMsg | ActionExam>) => {
    try {
      dispatch({ type: ActionExamType.SAVEING_EXAM });

      const config = await getAuthHeaders();

      const data = await ApiRequest(
        FETCH_SINGLE_URL,
        { uid, examId },
        "GET",
        config
      );

      if (data.data.exams.examType === "WrittenProduction") {
        dispatch({
          type: ActionExamType.FETCH_WRITTEN_EXAM,
          payload: data.data.exams,
        });
      } else {
        dispatch({
          type: ActionExamType.FETCH_SINGLE_EXAM,
          payload: data.data.exams,
        });
      }
      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    } catch (err: any) {
      dispatch({
        type: ActionMsgType.SHOW_ERROR_MSG,
        payload: err.response.data.msg,
      });
      dispatch({ type: ActionExamType.SAVE_EXAM_COMPLETE });
    }
  };
};

import React from "react";
import { useTypedSelector } from "../../../../../hooks";
import CobeQuestionCard from "./CobeQuestionCard";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Answer, CobeAnswer, CobeExamQuestion } from "../../../../../types";
import { useActions } from "../../../../../hooks";
import axios from "axios";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders } from "../../../../../utils/Generic";
import { useNavigate, useParams } from "react-router-dom";

const SendAnswers_URL = `${BASE_URL}/UpdateStudentExamAnswers`;

const SampleExamQuestions = () => {
  const { key } = useParams();
  const { MsgActions } = useActions();
  const navigate = useNavigate();
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);

  const currentExam = studentExams?.find((exam: any) => exam?.key === key);

  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [answers, setAnswers] = React.useState<CobeAnswer[]>([]);

  const { user } = useTypedSelector((state) => state.AuthReducer);

  const questionIndex = currentExam?.questions?.[currentIndex]?.key;

  const length = currentExam?.questions?.length;
  let lengthFormatted = length !== undefined ? length.toFixed(2) : 0;

  lengthFormatted = Number(lengthFormatted);

  let answerPercentage = ((currentIndex + 1) / lengthFormatted) * 100;
  answerPercentage = Number(answerPercentage.toFixed(0));

  // * Handle sending answers
  const handleSendAnswers = async () => {
    if (answers.length < Number(lengthFormatted)) {
      MsgActions.showMsg("You must answer all the questions !", "danger");
      return;
    }

    const headers = await getAuthHeaders();
    const _answers = answers.map((ans) => ({
      questionId: ans.questionId,
      answer: ans.storagePath ? ans.storagePath : ans.answer,
      answerType: ans.answerType,
    }));

    axios
      .put(
        SendAnswers_URL,
        {
          userId: user?.key,
          examId: currentExam?.key,
          answers: _answers,
        },
        { headers: headers.headers }
      )
      .then((res) => {
        MsgActions.showMsg("Answers submitted successfully", "success");
        navigate("/student/exams");
      })
      .catch((err) => {
        MsgActions.showMsg("Failed to submit exam answers", "danger");
      });
  };

  return (
    <section className="my-4 w-[20rem] md:w-full">
      <p className="mx-6 my-4 bg-[#F3F3F3] w-fit text-[1.3rem] px-2 py-1">
        {currentExam?.title}
      </p>
      <div>
        <div className="mx-[1rem] md:mx-[18rem] ">
          <ProgressBar now={answerPercentage} label={`${answerPercentage}%`} />
        </div>

        {currentExam?.questions?.map(
          (question: CobeExamQuestion, index: number) => (
            <div key={index.toString()} className="my-5">
              {index === currentIndex && (
                <>
                  <CobeQuestionCard
                    question={question}
                    questionIndex={questionIndex}
                    setAnswers={setAnswers}
                    answers={answers}
                    currentExam={currentExam}
                    currentIndex={currentIndex}
                    setCurrentIndex={setCurrentIndex}
                    lengthFormatted={lengthFormatted}
                    handleSendAnswers={handleSendAnswers}
                  />
                </>
              )}
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default SampleExamQuestions;

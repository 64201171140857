import { Modal } from 'react-bootstrap';

interface SubmitModalProps {
	setShowModal: (newType: boolean) => void;
	exam: any;
}

const AssetModal = ({ setShowModal, exam }: SubmitModalProps) => {
	return (
		<div>
			<Modal show={true} className="max-w-[20rem] mx-4 md:mx-0 md:max-w-full">
				<Modal.Header
					closeButton
					onClick={() => setShowModal(false)}
				></Modal.Header>
				<Modal.Body>
					{exam.imageUrl && (
						<img
							src={exam.imageUrl}
							alt="exam"
							className="w-[20rem] mx-4 my-2"
						/>
					)}

					{exam.videoUrl && (
						<div className="flex  justify-center items-center">
							<iframe
								width="600"
								height="280"
								src={exam.videoUrl}
								// allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								title="Embedded youtube"
							/>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AssetModal;

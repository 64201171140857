import React from "react";
import { useTypedSelector } from "../../../../../hooks";
import QuestionCard from "./QuestionCard";
import MatchingQuestionCard from "./MatchingQuestionCard";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Answer,
  MCQAnswer,
  MCQuestion,
  MatchingAnswer,
  MatchingQuestion,
  Question,
  SampleExam,
} from "../../../../../types";
import { useActions } from "../../../../../hooks";
import { BASE_URL } from "../../../../../utils/Constans";
import { getAuthHeaders, shuffle } from "../../../../../utils/Generic";
import { useNavigate, useParams } from "react-router-dom";
import { ApiRequest } from "../../../../../utils/ApiRequests";
import { useTranslation } from "react-i18next";

const SendAnswers_URL = `${BASE_URL}/UpdateStudentExamAnswers`;

const SampleExamQuestions = () => {
  const { t } = useTranslation();
  const { key } = useParams();
  const { MsgActions } = useActions();
  const navigate = useNavigate();
  const { studentExams } = useTypedSelector((state) => state.ExamReducer);
  const { user } = useTypedSelector((state) => state.AuthReducer);

  const currentExam = studentExams?.find((exam) => exam?.key === key);

  const [loading, setLoading] = React.useState(false);
  const [exam, setExam] = React.useState<SampleExam>(currentExam);
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [answerIndex, setAnswerIndex] = React.useState(0);
  const [answers, setAnswers] = React.useState<(MatchingAnswer | MCQAnswer)[]>(
    []
  );

  React.useEffect(() => {
    if (exam && exam.questions) {
      const questions: MCQuestion[] | MatchingQuestion[] = [
        ...exam.questions,
      ] as MCQuestion[] | MatchingQuestion[];
      for (let i = 0; i < questions.length; i++) {
        questions[i].options = shuffle(questions[i].options);
      }
      setExam({ ...exam, questions });
    }
  }, []);

  const handleMCSelectAnswer = (
    studentAnswer: string,
    question: MCQuestion
  ) => {
    const _answers = [...answers];
    const answer: MCQAnswer = {
      questionId: question.key,
      answer: studentAnswer,
      isCorrect: studentAnswer === question.answer,
      question: question.question,
      answerType: question.answerType,
      questionTitle: question.title,
      originalAnswer: question.answer,
    };

    _answers[answerIndex] = answer;

    setAnswers(_answers);
  };
  const handleSelectMatchingAnswer = (
    studentAnswer: string[],
    question: MatchingQuestion
  ) => {
    const _answers = [...answers];
    for (let i = 0; i < studentAnswer.length; i++) {
      const _answer: MatchingAnswer = {
        questionId: question.key,
        answer: question.question[i],
        isCorrect: question.answer[i].answer === studentAnswer[i],
        question: question.question[i],
        answerType: "Matching",
        questionTitle: question.title,
        originalAnswer: question.answer[i].answer,
        option: studentAnswer[i],
        completeQuestion: question,
      };
      _answers[answerIndex + i] = _answer;
    }

    setAnswers(_answers);
  };

  const checkAnswers = () => {
    for (let answer of answers) {
      if (!answer) return false;
    }
    return true;
  };

  const sendStudentAnswers = async () => {
    try {
      if (!checkAnswers()) {
        MsgActions.showMsg("You have to answer all questions", "danger");
        return;
      }
      if (loading) return;
      setLoading(true);
      const headers = await getAuthHeaders();
      await ApiRequest(
        SendAnswers_URL,
        { userId: user?.key, examId: exam.key, answers },
        "PUT",
        headers
      );
      MsgActions.showMsg("Answers submitted successfully", "success");
      navigate("/student/exams");
    } catch (err) {
      MsgActions.showMsg("Failed to submit exam answers", "danger");
    } finally {
      setLoading(false);
    }
  };

  const currentMatchingQuestionAnswer = (): string[] => {
    if (answers[answerIndex] === undefined) {
      return [];
    }

    const _answers: string[] = answers
      .slice(answerIndex, answerIndex + 3)
      .map((answer) => (answer as MatchingAnswer)?.option as string);
    return _answers;
  };

  const handleNextQuestionPress = () => {
    setCurrentQuestion(currentQuestion + 1);
    if (exam.questions![currentQuestion].answerType === "MCQ") {
      setAnswerIndex(answerIndex + 1);
    } else if (exam.questions![currentQuestion].answerType === "Matching") {
      setAnswerIndex(answerIndex + 3);
    }
  };

  const handlePrevQuestionPress = () => {
    setCurrentQuestion(currentQuestion - 1);
    if (exam.questions![currentQuestion - 1].answerType === "MCQ") {
      setAnswerIndex(answerIndex - 1);
    } else if (exam.questions![currentQuestion - 1].answerType === "Matching") {
      setAnswerIndex(answerIndex - 3);
    }
  };

  return (
    <section className="w-[20rem] md:w-full my-4">
      <p className="mx-6 my-4 bg-[#F3F3F3] w-fit text-[1.3rem] px-2 py-1">
        {currentExam?.title}
      </p>
      <div>
        <div className="mx-[1rem] md:mx-[18rem] ">
          <ProgressBar
            now={((currentQuestion + 1) / (exam?.questions?.length || 1)) * 100}
            label={`${
              ((currentQuestion + 1) / (exam?.questions?.length || 1)) * 100
            }%`}
          />
        </div>
        {exam?.questions?.map((question: Question, index: number) => (
          <div key={index.toString()}>
            {index === currentQuestion && (
              <>
                {question.answerType === "Matching" ? (
                  <MatchingQuestionCard
                    question={
                      exam.questions![currentQuestion] as MatchingQuestion
                    }
                    initValue={currentMatchingQuestionAnswer()}
                    updateAnswers={(answers) =>
                      handleSelectMatchingAnswer(
                        answers,
                        exam.questions![currentQuestion] as MatchingQuestion
                      )
                    }
                  />
                ) : (
                  <QuestionCard
                    initValue={answers[answerIndex]?.answer}
                    question={exam.questions![currentQuestion] as MCQuestion}
                    setQuestionResult={(result) => {
                      handleMCSelectAnswer(
                        result.playerAnswer,
                        exam.questions![currentQuestion] as MCQuestion
                      );
                    }}
                    isRTL={exam.language === "Hebrew"}
                  />
                )}
              </>
            )}
          </div>
        ))}

        <div className="md:flex md:flex-row md:justify-center  md:px-[12rem] md:gap-[10rem] flex flex-row gap-4  my-4">
          <div className="space-x-2 md:space-x-4">
            <button
              className={`bg-[#3188C9] text-white px-4 py-2 w-[8rem] font-semibold rounded ${
                currentQuestion === 0 && "bg-[#D3D3D3]"
              }`}
              onClick={handlePrevQuestionPress}
              disabled={currentQuestion === 0}
            >
              {t("prev")}
            </button>
            {currentQuestion < exam.questions!.length - 1 && (
              <button
                className="bg-[#3188C9] text-white px-6 w-[8rem] py-2 font-semibold rounded "
                onClick={handleNextQuestionPress}
              >
                {t("next")}
              </button>
            )}
          </div>
          <div>
            {currentQuestion === exam.questions!.length - 1 && (
              <button
                className="bg-[#9BDE7E] text-white px-6 w-[10rem] py-2 font-semibold rounded "
                onClick={sendStudentAnswers}
              >
                {t("Send answers")}
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SampleExamQuestions;
